import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  estimateBookingCalculations,
  getListingAvailability,
} from 'leween-react-sdk/redux/actions/public-listings.actions';
import { createBooking } from 'leween-react-sdk/redux/actions/booking.actions';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkAuth } from 'leween-react-sdk/redux/actions/auth.actions';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import DateTimeField from '../../../components/datetime-input';
import schema from './schema';
import { debounce, setServerErrors } from '../../../helpers';
import Login from '../../auth/login';
import SignUp from '../../auth/sign-up';

const BookingRequest = ({
  history, listingId, entities,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const [calculations, setCalculations] = useState(undefined);
  const [error, setManualError] = useState('');
  const [isLoginModalOpen, toggleLoginModal] = useState(false);
  const [isSignUpModalOpen, toggleSignUpModal] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState('');
  const [selectedEntity, setSelectedEntity] = useState(undefined);

  const {
    control,
    getValues,
    watch,
    setError,
    formState: { errors },
    trigger,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      time_slots: null,
    },
  });

  useEffect(() => {
    if (!selectedEntityId) return;

    const entity = entities?.find((entity) => entity.id === selectedEntityId);
    setSelectedEntity(entity);
  }, [selectedEntityId]);

  const minDate = useMemo(() => {
    return DateTime.now().toJSDate();
  }, []);

  const onSubmit = useCallback(
    debounce((values) => {
      if (isMounted.current) {
        const data = {
          start_datetime: DateTime.fromISO(values?.time_slots?.start_datetime).toFormat(
            'yyyy-MM-dd HH',
          ),
          end_datetime: DateTime.fromISO(values?.time_slots?.end_datetime).toFormat(
            'yyyy-MM-dd HH',
          ),
          entity_id: selectedEntity?.id,
        };
        dispatch(estimateBookingCalculations(listingId, data))
          .then((response) => {
            setCalculations(response.data);
          })
          .catch((error) => {
            const responseErrors = error?.response?.data?.errors;
            setServerErrors(responseErrors, setError);
          });
      } else {
        isMounted.current = true;
      }
    }, 500),
    [selectedEntity],
  );

  const requestToBook = () => {
    dispatch(checkAuth()).then((response) => {
      if (response) {
        trigger().then((result) => {
          if (result) {
            const values = getValues();
            const data = {
              entity_id: selectedEntity?.id,
              listing_id: listingId,
              start_datetime: DateTime.fromISO(
                values.time_slots?.start_datetime,
              ).toFormat('yyyy-MM-dd HH:mm'),
              end_datetime: DateTime.fromISO(values.time_slots?.end_datetime).toFormat(
                'yyyy-MM-dd HH:mm',
              ),
            };
            dispatch(createBooking(data))
              .then((response) => {
                history.push(`/booking/request/${response.data.id}`);
              })
              .catch((error) => {
                setManualError(error?.response?.data?.message);
              });
          }
        });
      } else {
        toggleLoginModal(true);
      }
    });
  };

  const newValues = JSON.stringify(watch());
  useEffect(() => {
    const values = getValues();
    onSubmit(values);
  }, [newValues]);

  const getAvailableTimes = useCallback((date) => {
    const formattedDate = date.toFormat('yyyy-MM-dd');
    return dispatch(getListingAvailability(listingId, selectedEntityId, formattedDate));
  }, [selectedEntityId]);

  const openSignUpModal = useCallback(() => {
    toggleLoginModal(false);
    toggleSignUpModal(true);
  }, [isLoginModalOpen]);

  const openLoginModal = useCallback(() => {
    toggleSignUpModal(false);
    toggleLoginModal(true);
  }, [isSignUpModalOpen]);
  return (
    <>
      <div className="booking-sticky-wrapper bg-light p-3 rounded shadow-sm">
        <h6 className="text-dark fw-bold mb-3">{t('listing_details.booking_request.input_label')}</h6>
        <Input
          name="entity_id"
          onChange={(e) => setSelectedEntityId(e.target.value)}
          type="select"
          defaultValue=""
          value={selectedEntityId}
        >
          <option value="" disabled>{t('listing_details.booking_request.input_placeholder')}</option>
          {entities?.map((entity) => {
            return <option value={entity.id}>{entity?.title}</option>;
          })}
        </Input>
        {selectedEntityId
        && (
          <div className="mt-3">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <h6 className="text-space-gray mb-0">
                {t('listing_details.booking_request.rate')}
              </h6>
              <h6 className="text-space-gray mb-0">
                {t('listing_details.booking_request.min_duration')}
              </h6>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div className="d-flex align-items-center">
                <h5 className="text-dark mb-0 fw-bold">
                  {selectedEntity?.hourly_rate}
                </h5>
                <p className="mb-0 ms-2 fw-bold">
                  {`${localStorage.getItem(
                    'currency',
                  )}/${t('shared.hour')}`}

                </p>
              </div>
              <h5 className="text-dark mb-0 fw-bold">
                {selectedEntity?.min_hours}
                {' '}
                {t('shared.hour')}
              </h5>
            </div>
            <p>
              <span className="fw-bold">
                {selectedEntity?.hour_discount?.percentage}
                %
              </span>
              {' '}
              {t('listing_details.booking_request.discount')}
              {' '}
              <span className="fw-bold">
                {selectedEntity?.hour_discount?.more_than}
                {' '}
                {t('shared.hour')}
              </span>
            </p>
            <div className="date-container mb-2">
              <DateTimeField
                name="time_slots"
                control={control}
                minDate={minDate}
                errors={errors}
                onDatePickerChange={getAvailableTimes}
              />
            </div>
            {calculations
              && (
                <>
                  <div className="d-flex align-items-center justify-content-end mt-2 mb-3">
                    <h6 className="text-space-gray mb-0">
                      {t('dashboard.bookings.hours')}
                      :
                      {' '}
                      {calculations?.bookingDuration}
                    </h6>
                  </div>
                  <h6 className="text-dark fw-bold">{t('shared.price')}</h6>
                  <div
                    className="d-flex align-items-center justify-content-between mb-2"
                  >
                    <h6 className="text-dark mb-0">{t('shared.booking_fees')}</h6>
                    <h6 className="text-dark mb-0">
                      {localStorage.getItem('currency')}
                      {' '}
                      {calculations?.bookingFees}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between mb-2"
                  >
                    <h6 className="text-dark mb-0">{t('shared.processing_fees')}</h6>
                    <h6 className="text-dark mb-0">
                      {localStorage.getItem('currency')}
                      {' '}
                      {calculations?.processingFee}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between mb-2"
                  >
                    <h6 className="text-dark mb-0">{t('steps.activities.discount')}</h6>
                    <h6 className="text-dark mb-0">
                      {localStorage.getItem('currency')}
                      {' '}
                      {calculations?.discount}
                    </h6>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between mb-2"
                  >
                    <h6 className="text-dark mb-0">{t('shared.vat')}</h6>
                    <h6 className="text-dark mb-0">
                      {localStorage.getItem('currency')}
                      {' '}
                      {calculations?.VAT}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h6 className="text-dark fw-bold mb-0">
                      {t('shared.total')}
                    </h6>
                    <h6 className="text-dark fw-bold mb-0">
                      {localStorage.getItem('currency')}
                      {' '}
                      {calculations?.total}
                    </h6>
                  </div>
                </>
              )}
            <button
              type="button"
              className="btn yellow-btn w-100"
              disabled={!calculations}
              onClick={requestToBook}
            >
              {t('listing_details.booking_request.request')}
            </button>
            {error && <p className="text-danger small mt-2">{error}</p>}
          </div>
        )}
      </div>
      <Login
        isModalOpen={isLoginModalOpen}
        toggleModal={toggleLoginModal}
        openSignUpModal={openSignUpModal}
      />
      <SignUp
        isModalOpen={isSignUpModalOpen}
        toggleModal={toggleSignUpModal}
        openLoginModal={openLoginModal}
      />
    </>
  );
};

export default BookingRequest;
