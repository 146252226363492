import React, { useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { ChevronUp, ChevronDown } from 'react-feather';
import { Shimmer } from 'react-shimmer';
import { useTranslation } from 'react-i18next';
import placeholderImg from '../../../../assets/images/placeholder.png';
import alertCircle from '../../../../assets/images/alert-circle.svg';

const BookingData = ({
  booking, isLoading, listingLocation,
}) => {
  const [isCollapsed, collapseContent] = useState(true);
  const { t } = useTranslation();

  const renderStatusColor = (status) => {
    switch (status) {
      case 'waiting-host-approval':
      case 'pending-payment':
      case 'draft':
        return 'orange-badge';
      case 'booking-approved':
        return 'dark-badge';
      case 'booking-rejected':
        return 'red-badge';
      case 'booking-canceled-by-user':
      case 'booking-canceled-by-host':
      case 'booking-held':
        return 'gray-badge';
      case 'booking-completed':
        return 'white-badge';
      default:
        return '';
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return 'Pending';
      case 'booking-approved':
        return 'Confirmed';
      case 'booking-rejected':
        return 'Declined by host';
      case 'booking-canceled-by-user':
      case 'booking-canceled-by-host':
        return 'Canceled';
      case 'booking-completed':
        return 'Completed';
      case 'draft':
        return 'Draft';
      case 'pending-payment':
        return 'Pending payment';
      case 'booking-held':
        return 'Booking Held';
      default:
        return null;
    }
  };

  const renderCalculations = () => {
    if (isLoading) {
      return (
        <Row>
          {[1, 2, 3].map((index) => (
            <Col key={index} md={12} className="mb-2">
              <Shimmer className="w-100" height={30} width={100} />
            </Col>
          ))}
        </Row>
      );
    }
    const price = booking?.calculation?.price;
    const fixedPriceKeys = Object.keys(price || {}).filter((key) => key !== 'makanatAddons');
    const fixedPriceItems = fixedPriceKeys.map((key) => {
      return (
        <Row key={key} className="justify-content-between">
          <Col xs="auto">
            <div className="d-flex align-items-center">
              <h6 className="text-space-gray mb-0">{price[key]?.label}</h6>
              <img src={alertCircle} className="ms-2" alt="alert" />
            </div>
          </Col>
          <Col xs="auto">
            <h6 className="text-dark">{`${localStorage.getItem('currency')} ${price[key]?.amount}`}</h6>
          </Col>
        </Row>
      );
    });

    return fixedPriceItems;
  };

  const renderCashPaidAddons = () => {
    if (isLoading) {
      return (
        <Row>
          {[1, 2, 3].map((index) => (
            <Col key={index} md={12} className="mb-2">
              <Shimmer className="w-100" height={30} width={100} />
            </Col>
          ))}
        </Row>
      );
    }

    return booking?.calculation?.cashPaid?.map((item) => (
      <Row key={item.label} className="justify-content-between">
        <Col xs="auto">
          <div className="d-flex align-items-center">
            <h6 className="text-space-gray mb-0">{item.label}</h6>
          </div>
        </Col>
        <Col xs="auto">
          <h6 className="text-dark">{item.amount}</h6>
        </Col>
      </Row>
    ));
  };
  return (
    <>
      <div className="white-container booking-details">
        <div className="cover-img">
          <img src={booking.listing?.primary_image?.url || placeholderImg} alt="cover" />
          <div className={`status ${renderStatusColor(booking?.status)}`}>
            <p className="mb-0 text-center">{renderStatus(booking?.status)}</p>
          </div>
          <div className="caption">
            <h5 className="text-white fw-bold">{booking?.title || 'Untitled'}</h5>
            <h6 className="text-white opacity-7 mb-0">{listingLocation}</h6>
          </div>
        </div>
        <div className="p-4">
          <div className="d-flex align-items-center justify-content-between cursor-pointer" onClick={() => collapseContent(!isCollapsed)}>
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="text-dark fw-bold mb-0">{t('shared.total')}</h5>
              <h5 className="text-dark fw-bold mb-0 me-1">{`${localStorage.getItem('currency')} ${booking?.calculation?.total?.amount}`}</h5>
            </div>
            {isCollapsed ? <ChevronUp /> : <ChevronDown />}
          </div>
          <Collapse className="mt-3" isOpen={isCollapsed}>
            <Row>
              <Col md={12}>
                <h6 className="fw-bold">{t('shared.price')}</h6>
              </Col>
            </Row>
            {renderCalculations()}
            {booking?.calculation?.cashPaid?.length > 0
            && (
              <>
                <Row className="mt-3">
                  <Col md={12}>
                    <h6 className="fw-bold">Cash Paid Add-ons</h6>
                  </Col>
                </Row>
                {renderCashPaidAddons()}
              </>
            )}
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default BookingData;
