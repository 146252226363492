import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';

const PrivateRoute = ({
  component: Component, header, footer, roles, ...rest
}) => {
  const authenticated = localStorage.getItem('api_token') !== null;
  const role = localStorage.getItem('role');

  const userHasRequiredRole = roles?.includes(role);

  const renderRoute = (props) => {
    if (authenticated) {
      if (userHasRequiredRole) {
        return <Component {...props} />;
      }
      return <Redirect to="/unauthorized" />;
    }
    return (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location },
      }}
      />
    );
  };
  if (header) {
    return (
      <>
        <Header />
        <Route
          {...rest}
          render={(props) => renderRoute(props)}
        />
      </>
    );
  } if (footer) {
    return (
      <>
        <Route
          {...rest}
          render={(props) => renderRoute(props)}
        />
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <Route
        {...rest}
        render={(props) => renderRoute(props)}
      />
      <Footer />
    </>
  );
};

export default PrivateRoute;
