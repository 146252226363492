import * as yup from 'yup';

const schema = yup.object().shape({
  accuracy: yup.number().required('This field is required'),
  communication: yup.number().required('This field is required'),
  cleanliness: yup.number().required('This field is required'),
  location: yup.number().required('This field is required'),
  check_in: yup.number().required('This field is required'),
  value: yup.number().required('This field is required'),
  public_review: yup.string().required('This field is required'),
});

export default schema;
