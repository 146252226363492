import React, { useMemo, useCallback } from 'react';
import {
  FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import { getErrorsByPath } from '../../helpers';

const FlagCheckboxInput = ({
  value, onChange, flag, name, errors, bsSize,
}) => {
  const isChecked = useMemo(() => {
    if (!value || !flag) return false;

    return value.includes(flag);
  }, [value, flag]);

  const onInputValueChange = useCallback(
    (event) => {
      const { checked } = event.target;
      if (checked) {
        onChange([...value, flag]);
      } else {
        onChange(value.filter((val) => val !== flag));
      }
    },
    [value, flag, onChange],
  );

  return (
    <Input
      type="checkbox"
      checked={isChecked}
      onChange={onInputValueChange}
      invalid={getErrorsByPath(errors, name) && true}
      bsSize={bsSize}
    />
  );
};

const FlagCheckboxField = ({
  control,
  name,
  flag,
  labelSize,
  label,
  errors,
  hideError,
  bsSize,
  className,
}) => {
  return (
    <FormGroup check className={`${className || ''}`}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { invalid } }) => (
          <FlagCheckboxInput
            onChange={onChange}
            value={value}
            flag={flag}
            invalid={invalid}
            errors={errors}
            name={name}
            bsSize={bsSize}
          />
        )}
      />
      <Label size={labelSize} for={name} check>
        {label}
      </Label>
      {!hideError && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <FormFeedback>{message}</FormFeedback>}
        />
      )}
    </FormGroup>
  );
};

export default FlagCheckboxField;
