import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { deleteListingImage, updateListing, uploadListingImage } from 'leween-react-sdk/redux/actions/host/listings.actions';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Row, Col, FormGroup, Label, Button as ButtonLink, ButtonGroup, FormText, Progress,
} from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import AsyncSelect from 'react-select/async';
import { activitiesLookup } from 'leween-react-sdk/redux/actions/activities.actions';
import { Trash2 } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import BinaryInput from '../../../../../components/binary-input';
import schema from './schema';
import InputField from '../../../../../components/input';
import Button from '../../../../../components/button';
import photoPlaceholder from '../../../../../assets/images/photo.png';

const Setup = ({ onUpdateListing, stepper, listing }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activities, setActivities] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState([]);
  const [progressValue, setProgressValue] = useState(10);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgressValue((progressValue) => progressValue + 10);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const data = [];
      acceptedFiles.map((file) => {
        return data.push({ url: URL.createObjectURL(file), id: file?.name });
      });
      setLoadingFiles(data);
      const uploadPromises = acceptedFiles.map((file) => {
        const formData = new FormData();
        formData.append('image', file);
        return dispatch(uploadListingImage(listing.id, formData));
      });

      Promise.allSettled(uploadPromises).then((results) => {
        setLoadingFiles(loadingFiles.splice(-1));
        setProgressValue(10);
        const fulfilledResults = results
          .filter((result) => result.status === 'fulfilled')
          .map((result) => result.value.data);
        setFiles([...fulfilledResults, ...files]);

        const rejectedResults = results
          .filter((result) => result.status === 'rejected')
          .map((result) => ({
            file: result.reason.config.data.get('image'),
            errors: result.reason.response?.data.errors.image || ['Image size is too large'],
          }));
        const newRejectedFiles = [...rejectedFiles, ...rejectedResults];
        setRejectedFiles(newRejectedFiles);
      });
    },
  });

  const deleteImage = (e, fileId) => {
    e.stopPropagation();
    const newFiles = [...files];
    const index = newFiles.findIndex((file) => file.id === fileId);
    newFiles.splice(index, 1);
    setFiles(newFiles);
    dispatch(deleteListingImage(listing.id, fileId));
  };

  const renderImagePreviews = files.map((file) => (
    <Col md={3} key={file.id}>
      <div className="image-preview">
        <img src={file.url} alt={file.id} />
        <div className="image-overlay" onClick={(e) => deleteImage(e, file.id)}>
          <Trash2 size={25} />
          <h6 className="mb-0 mt-2 text-center">Delete image</h6>
        </div>
      </div>
    </Col>
  ));

  const renderImageWithLoading = loadingFiles.map((file) => (
    <Col md={3} key={file.id}>
      <div className="image-preview">
        <img src={file.url} alt={file.id} className="blur-img" />
        <Progress color="success" value={progressValue} animated className="progress" />
      </div>
    </Col>
  ));

  const renderRejectedImages = rejectedFiles.map((image) => {
    const imageUrl = URL.createObjectURL(image.file);
    const errors = image.errors.map((error) => {
      return <h6 className="mb-0 text-center" key={image.file.name}>{error}</h6>;
    });
    return (
      <Col md={3} key={image.file.name}>
        <div className="image-preview">
          <img src={imageUrl} alt={image.file.name} />
          <div className="image-overlay invalid">
            {errors}
          </div>
        </div>
      </Col>
    );
  });

  useEffect(() => {
    if (listing.images) {
      setFiles(listing.images);
    }
  }, [listing]);

  const {
    register, handleSubmit, setValue, reset, watch, formState: { errors }, control,
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    if (files.length < 5) {
      setErrorMessage(true);
      return;
    }

    const selectedActivities = data?.activities.map((activity) => { return activity.value; });
    let newData = { ...data };
    if (!watch('parking_information.status')) {
      newData = {
        ...data,
        parking_information:
        {
          ...data.parking_information,
          options: undefined, // Discard options text if parking status set to `No`
          description: undefined, // Discard description text if parking status set to `No`
        },
        activities: selectedActivities,
        images: files,
      };
    } else {
      newData = {
        ...data,
        activities: selectedActivities,
        images: files,
      };
    }
    dispatch(updateListing(listing.id, { step: 2, ...newData }))
      .then(() => {
        onUpdateListing(true);
      });
  };

  useEffect(() => {
    const parkingInfo = listing.listing_data?.parking_information && listing.listing_data?.parking_information;
    reset({
      activities: listing?.activities?.map((activity) => ({ value: activity.id, label: activity.name })),
      parking_information: parkingInfo,
      title: listing.listing_data?.title,
      description: listing.listing_data?.description,
    });
  }, [listing, reset]);

  register('parking_information.status');

  useEffect(() => {
    if (watch('parking_information.status') === null) {
      setValue('parking_information.status', false);
    }
  }, [watch('parking_information.status')]);

  const loadOptions = (inputValue, callback) => {
    dispatch(activitiesLookup({ query: inputValue })).then((result) => {
      const newResult = result.data.map((activity) => ({ value: activity.id, label: activity.name }));
      callback(newResult);
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section>
        <Row className="d-flex align-items-center">
          <Col md={12} className="mb-4">
            <h4 className="fw-bold text-dark">{t('steps.setup.title')}</h4>
            <p className="text-dark-gray">{t('steps.setup.subtitle')}</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <InputField
              label={t('steps.about.ad_title')}
              errors={errors}
              name="title"
              type="text"
              {...register('title')}
            />
          </Col>
          <Col md={4}>
            <Label className="text-dark-gray">{t('steps.setup.activities')}</Label>
            <Controller
              name="activities"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <AsyncSelect
                  {...field}
                  isClearable
                  defaultOptions
                  loadOptions={loadOptions}
                  onInputChange={(value) => setActivities(value)}
                  isMulti
                  placeholder={t('steps.setup.activities_placeholder')}
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <InputField
              errors={errors}
              label={t('steps.about.ad_description')}
              name="description"
              rows={3}
              as="textarea"
              {...register('description')}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <hr className="silver-hr" />
          </Col>
        </Row>
        <Row className="d-flex align-items-center mt-3">
          <Col md={12}>
            <h4 className="fw-bold text-dark">{t('steps.setup.parking_title')}</h4>
            <p className="text-dark-gray">{t('steps.setup.parking_subtitle')}</p>
          </Col>
        </Row>
        <FormGroup row>
          <Col md="auto" lg={6} className="d-flex align-items-center justify-content-between">
            <Label className="text-dark-gray mb-0">{t('steps.setup.parking_availability')}</Label>
            <ButtonGroup>
              <ButtonLink onClick={() => setValue('parking_information.status', true)} active={watch('parking_information.status')}>{t('shared.yes')}</ButtonLink>
              <ButtonLink onClick={() => setValue('parking_information.status', false)} active={!watch('parking_information.status')}>{t('shared.no')}</ButtonLink>
            </ButtonGroup>
          </Col>
        </FormGroup>
        {watch('parking_information.status') && (
          <>
            <Row>
              <Label className="text-dark" md={12}>{t('steps.setup.select_all')}</Label>
              <Col lg={8}>
                <Row>
                  {t('steps.setup.parking_options', { returnObjects: true }).map((option) => (
                    <Col className="mb-2" lg={6} key={option.value}>
                      <BinaryInput
                        id={option.value}
                        label={option.label}
                        type="checkbox"
                        value={option.value}
                        name="parking_information.options"
                        {...register('parking_information.options')}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col className="mb-3" md={12}>
                <ErrorMessage
                  errors={errors}
                  name="parking_information.options"
                  render={({ message }) => <FormText color="danger">{message}</FormText>}
                />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <InputField
                  label={t('steps.setup.parking_desc')}
                  errors={errors}
                  name="parking_information.description"
                  rows={3}
                  as="textarea"
                  {...register('parking_information.description')}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col md={8}>
            <hr className="silver-hr" />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={12} className="mb-4">
            <h4 className="fw-bold text-dark">
              {t('steps.photos.title')}
            </h4>
            <p className="text-dark-gray">{t('steps.photos.subtitle')}</p>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col>
            <h6 className="text-dark">
              {t('steps.photos.requirements_title')}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <ul className="pl-4 mb-3">
              {t('steps.photos.requirements_one', { returnObjects: true }).map(
                (li) => (
                  <li className="mb-1" key={li.title}>
                    <span className="fw-bold text-dark">{li.title}</span>
                    {' '}
                    {li.desc}
                  </li>
                ),
              )}
            </ul>
          </Col>
          <Col md={4}>
            <ul className="pl-4 mb-3">
              {t('steps.photos.requirements_two', { returnObjects: true }).map(
                (li) => (
                  <li className="mb-1" key={li.title}>
                    <span className="fw-bold text-dark">{li.title}</span>
                    {' '}
                    {li.desc}
                  </li>
                ),
              )}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <div {...getRootProps({ className: `dropzone-uploader ${errorMessage && files.length < 5 ? 'invalid' : ''}` })}>
              <input {...getInputProps()} />
              <img
                src={photoPlaceholder}
                alt="placeholder"
                className="img-placeholder"
              />
              <h6 className="text-dark">{t('steps.photos.count')}</h6>
              <div className="blue-container">
                <h6 className="text-dark-gray mb-0">
                  <span className="text-blue">Add file</span>
                  {' '}
                  or drop files here
                </h6>
              </div>
              <Row>
                {renderRejectedImages}
                {renderImagePreviews}
                {renderImageWithLoading}
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            {errorMessage && files.length < 5 && (
              <span className="text-danger">Listing should contain at least 5 photos</span>
            )}
          </Col>
        </Row>
      </section>
      <section className="bs-footer">
        <Row>
          <Col md={12} className="d-flex justify-content-end">
            <ButtonLink className="light-btn br-0 px-4 me-2" onClick={() => stepper.previous()}>{t('shared.previous')}</ButtonLink>
            <Button className="blue-btn br-0 px-4" title={t('shared.next')} />
          </Col>
        </Row>
      </section>
    </form>
  );
};

export default Setup;
