import React from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Form,
} from 'reactstrap';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { approveHostBooking } from 'leween-react-sdk/redux/actions/host/bookings.actions';
import InputField from '../../../../../components/input/index';
import hostelImg from '../../../../../assets/images/hostel.svg';
import schema from './schema';

const ApproveBooking = ({
  isOpen, toggleModal, bookingId, guestName, getBooking,
}) => {
  const dispatch = useDispatch();

  const {
    register, handleSubmit, reset, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    dispatch(approveHostBooking(bookingId, data)).then(() => {
      toggleModal();
      getBooking();
    }).catch((error) => {
      const message = error?.response?.data?.message;
      setError('manual', { message, type: 'manual' });
    });
  };

  return (
    <Modal onOpened={reset} toggle={() => toggleModal()} isOpen={isOpen} centered>
      <X className="modal-close-icon cursor-pointer" onClick={() => toggleModal()} size={18} />
      <ModalBody className="p-5">
        <Row>
          <Col md={12} className="d-flex justify-content-center mb-4">
            <img src={hostelImg} alt="approve booking" />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h3 className="fw-bold text-center">Great!</h3>
            <p className="text-center mb-0">{`Write a message welcoming ${guestName}`}</p>
          </Col>
        </Row>
        <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <InputField
                id="comment"
                name="comment"
                placeholder="Hello and welcome. Thank you for booking."
                as="textarea"
                rows={5}
                errors={errors}
                {...register('comment')}
                wrapperClassName="mb-2"
              />
              {errors && errors?.manual && <p className="text-red mb-0">{errors.manual.message}</p>}
              <p className="small mb-0">*Directions and WiFi information will be attached automatically.</p>
              <button type="submit" className="btn blue-btn w-100 mt-5">Accept & Send</button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ApproveBooking;
