const asyncSelectStyles = {
  container: (styles) => ({
    ...styles,
    flex: 1,
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    boxShadow: 'none',
    backgroundColor: '#f9f9fc',
    flex: 1,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#191919',
    backgroundColor: state.isSelected ? '#2a32ff' : '#f9f9fc',
    borderBottom: '1px solid #e2e2e2',
    padding: '6px 10px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#e2e2e2',
      color: '#191919',
      cursor: 'pointer',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#73879c',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0',
  }),
  IndicatorsContainer: (base) => ({
    ...base,
    padding: '0',
  }),
  singleValue: (base) => ({
    ...base,
    height: 17,
    lineHeight: 1,
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    backgroundColor: '#f9f9fc',
    borderRadius: 0,
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};

export default asyncSelectStyles;
