import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import { getErrorsByPath } from '../../helpers';

const PhoneField = React.forwardRef(({ optional, control, ...props }, ref) => {
  React.useImperativeHandle(ref, () => ({}));

  return (
    <Form.Group className="mb-3">
      {props.label
      && <Form.Label className="text-dark">{props.label}</Form.Label>}
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            inputClass={classnames('form-control', { 'is-invalid': getErrorsByPath(props.errors, props.name) })}
            value={value}
            onChange={onChange}
            country="sa"
            disabled={props.disabled}
          />
        )}
      />
      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => <Form.Control.Feedback className="d-block" type="invalid">{message}</Form.Control.Feedback>}
      />
    </Form.Group>
  );
});

export default PhoneField;
