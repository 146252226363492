import React, { useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
import { useDispatch } from 'react-redux';
import { forgetPassword } from 'leween-react-sdk/redux/actions/auth.actions';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import schema from './schema';
import VerifyOtp from '../verify-otp';
import { setServerErrors } from '../../../../helpers';
import PhoneField from '../../../../components/phone-input';
import Button from '../../../../components/button';

const AccountRetrieve = ({ isModalOpen, toggleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOtpModalOpen, toggleOtpModal] = useState(false);
  const {
    register, handleSubmit, reset, setError, control, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const parsedPhoneNumber = parsePhoneNumber(`+${data.phone_number}`);
    const newData = { phone_number: parsedPhoneNumber?.nationalNumber, phone_country: parsedPhoneNumber?.country };
    dispatch(forgetPassword(newData)).then(() => {
      localStorage.setItem('phone_number', parsedPhoneNumber?.nationalNumber);
      toggleOtpModal(true);
      toggleModal();
    }).catch((error) => {
      const responseErrors = error?.response?.data?.errors || [];
      setServerErrors(responseErrors, setError);
    });
  };
  return (
    <>
      <Modal className="light-modal" onShow={reset} show={isModalOpen} centered>
        <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
        <Modal.Body>
          <Container className="p-4">
            <Row>
              <Col md={12} className="d-flex flex-column align-items-center justify-content-center mb-4 mt-2">
                <h3 className="text-dark fw-bold mb-2">{t('retrieve_account.title')}</h3>
                <p className="text-dark mb-0">{t('retrieve_account.subtitle')}</p>
              </Col>
              <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
                <Col md={12}>
                  <PhoneField
                    name="phone_number"
                    control={control}
                    errors={errors}
                    {...register('phone_number')}
                  />
                  <Button className="yellow-btn w-100" title={t('retrieve_account.button')} />
                </Col>
              </Form>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <VerifyOtp isModalOpen={isOtpModalOpen} toggleModal={toggleOtpModal} />
    </>
  );
};

export default AccountRetrieve;
