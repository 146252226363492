import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkAuth, confirmOtp, resendOtp } from 'leween-react-sdk/redux/actions/auth.actions';
import { X } from 'react-feather';
import { getUser } from 'leween-react-sdk/redux/actions/user.actions';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import Button from '../../../components/button';
import OtpInput from '../../../components/otp-input';
import schema from './schema';
import EditPhoneNumber from '../edit-phone-number';

const OTP = ({
  isModalOpen, toggleModal, otpId, updateUserDetails,
}) => {
  const dispatch = useDispatch();
  const [isPhoneNumberModalOpen, togglePhoneNumberModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [updatedOtpId, setUpdatedOtpId] = useState(null);
  const [timer, setTimer] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (!timer) return;

    const intervalId = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    setTimer(60);
  }, [isModalOpen]);

  useEffect(() => {
    dispatch(checkAuth()).then((response) => {
      if (response) {
        dispatch(getUser()).then((response) => {
          const hashedPhoneNumber = response.data.phone_number.replace(/.(?=.{4})/g, '*');
          setPhoneNumber(hashedPhoneNumber);
        });
      }
    });
  }, [isModalOpen]);

  const {
    control, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const otp = updatedOtpId || otpId;
    dispatch(confirmOtp(otp, data)).then(() => {
      toggleModal();
      if (updateUserDetails) {
        updateUserDetails();
      }
    }).catch(() => {
      const message = 'Invaid OTP, please try again';
      setError('otp_code', { message, type: 'manual' });
    });
  };

  const handleResendOtp = () => {
    const otp = updatedOtpId || otpId;
    dispatch(resendOtp(otp)).then((response) => {
      setUpdatedOtpId(response.data.id);
      setTimer(60);
    });
  };

  const closeOtpModal = () => {
    toggleModal(false);
    togglePhoneNumberModal(!isPhoneNumberModalOpen);
  };

  const openOtpModal = useCallback((otpId) => {
    setUpdatedOtpId(otpId);
    toggleModal(true);
    togglePhoneNumberModal(!isPhoneNumberModalOpen);
  }, [isModalOpen, isPhoneNumberModalOpen]);
  return (
    <>
      <Modal className="light-modal" show={isModalOpen} onHide={() => toggleModal()} centered>
        <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
        <Modal.Body>
          <Container className="py-4 px-5">
            <Row>
              <Col md={12} className="d-flex flex-column align-items-center justify-content-center mt-2">
                <h3 className="text-dark text-center fw-bold mb-3">{t('otp.title')}</h3>
                <p className="text-dark text-center mb-0 lead">
                  {t('otp.subtitle')}
                  {' '}
                  {phoneNumber}
                </p>
              </Col>
              <Col md={12} className="d-flex align-items-center justify-content-center">
                <p className="text-yellow cursor-pointer mb-0 lead" onClick={closeOtpModal}>{t('change_phone.title')}</p>
              </Col>
              <Col md={12}>
                {timer !== 0
                  ? (
                    <p className="text-white text-center lead">
                      {`${t('otp.resend_timer')} ${String(Math.floor(timer / 60)).padStart(2, '0')}:${String(Math.floor(timer % 60)).padStart(2, '0')}`}
                    </p>
                  )
                  : (
                    <p className="text-white text-center lead cursor-pointer text-underline" onClick={handleResendOtp}>
                      {t('otp.resend')}
                    </p>
                  )}
              </Col>
            </Row>
            <Form className="row mb-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md={12} className="d-flex align-items-center justify-content-center">
                <OtpInput
                  name="otp_code"
                  control={control}
                  errors={errors}
                  {...register('otp_code')}
                />
              </Col>
              <Col md={12}>
                <Button className="yellow-btn w-100 mt-2" title={t('shared.continue')} />
              </Col>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      {isPhoneNumberModalOpen && <EditPhoneNumber isModalOpen={isPhoneNumberModalOpen} toggleModal={togglePhoneNumberModal} openOtpModal={openOtpModal} updateUserDetails={updateUserDetails} />}
    </>
  );
};

export default OTP;
