import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup.object().shape({
  description: yup.string()
    .nullable()
    .required(() => i18n.t('host.validations.required'))
    .min(140, () => i18n.t('host.validations.maxChar')),
  parking_information: yup.object().shape({
    description: yup
      .string()
      .nullable()
      .when('status', {
        is: true,
        then: yup.string()
          .nullable()
          .required(() => i18n.t('host.validations.required'))
          .min(35, () => i18n.t('host.validations.maxChar')),
      }),
    options: yup
      .array()
      .nullable()
      .when('status', {
        is: true,
        then: yup.array()
          .nullable()
          .required(() => i18n.t('host.validations.required'))
          .min(1, () => i18n.t('host.validations.selection')),
      }),
  }, ['status']),
});

export default schema;
