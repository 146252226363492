import { css } from 'styled-components';

const styles = {
  fieldWrapper: {
    base: css`
      width: 100%;
    `,
  },
  inputWrapper: {
    base: css`
      border-color: green;
      height: 50px;
      border-color: #e2e2e2;
      box-shadow: none;
      padding: .375rem .75rem;
      align-items: center;
      width: 100%;
    `,
    errored: css`
      border-color: #ed4646;
      box-shadow: none;
    `,
    focused: css`
      border-color: #2a32ff;
      box-shadow: none;
    `,
  },
  input: {
    base: css`
      color: #212529;
    `,
    errored: css`
      color: #ed4646;
    `,
    cardNumber: css`
      width: 60%;
      &::placeholder {
        color: #b2b2b2;
      }
    `,
    expiryDate: css`
      width: 25%;
      &::placeholder {
        color: #b2b2b2;
      }
    `,
    cvc: css`
      width: 15%;
      &::placeholder {
        color: #b2b2b2;
      }
    `,
  },
  errorText: {
    base: css`
      color: #ed4646;
      font-size: .875em;
    `,
  },
};

export default styles;
