import { React } from 'react';
import StorageService from 'leween-react-sdk/storage-service';
import { X } from 'react-feather';
import { useHistory } from 'react-router-dom';
import {
  Col, Container, Modal, Row,
} from 'react-bootstrap';

const CurrenciesModal = ({ isModalOpen, toggleModal, currencies }) => {
  const history = useHistory();

  const selectCurrency = (currency) => {
    StorageService.set('currency', currency).then(() => {
      history.push(window.location.pathname);
      toggleModal();
    });
  };
  return (
    <Modal className="light-modal" show={isModalOpen} onHide={() => toggleModal()} centered size="lg">
      <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
      <Modal.Body className="px-4">
        <Container className="py-3">
          <Row className="mb-4 mt-2">
            <Col md={12} className="d-flex align-items-center justify-content-center">
              <h4 className="text-dark fw-bold mb-2">Choose a Currency</h4>
            </Col>
          </Row>
          <Row>
            {currencies?.map((currency) => (
              <Col md={4} key={currency.id}>
                <div
                  className="hover-background-color cursor-pointer p-3"
                  onClick={() => selectCurrency(currency.code)}
                >
                  <p className="m-0 fw-bold">
                    {currency.name}
                    {' '}
                    -
                    {' '}
                    {currency.symbol}
                  </p>
                  <p className="m-0">{currency.code}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CurrenciesModal;
