import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import BootstrapTable from 'react-bootstrap-table-next';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getPaymentDetails, getPayments } from 'leween-react-sdk/redux/actions/host/reporting.actions';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const Credit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [payments, setPayments] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [transactionDetails, setTransactionDetails] = useState({});

  useEffect(() => {
    dispatch(getPayments()).then((response) => {
      setPayments(response.data);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  }, []);

  const loadMore = () => {
    dispatch(getPayments({ page: paginationMeta.current_page + 1 })).then((response) => {
      setPayments([...payments, ...response.data]);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  };

  function renderDate(date) {
    return (
      <p className="text-dark small mb-0">{DateTime.fromISO(date).toFormat('yyyy-MM-dd')}</p>
    );
  }

  const columns = [
    {
      dataField: 'date',
      text: t('dashboard.reporting.credit.date'),
      formatter: (date) => renderDate(date),
    },
    {
      dataField: 'guest_name',
      text: t('dashboard.reporting.credit.guest'),
    },
    {
      dataField: 'description',
      text: t('dashboard.reporting.credit.description'),
      hidden: isMobile,
    },
    {
      dataField: 'amount',
      text: `${t('dashboard.reporting.credit.amount')} (${localStorage.getItem('currency')})`,
      hidden: isMobile,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div className="expanded-row">
        {columns
          .filter((column) => column.hidden)
          .map((column) => {
            return (
              <p key={column?.dataField} className="text-dark mb-1">
                <span className="fw-bold">{column?.text}</span>
                :
                {' '}
                {row[column?.dataField]}
              </p>
            );
          })}
        <Row>
          <Col md={12}>
            <Row>
              <Col md={5} className="d-flex justify-content-between">
                <p className="text-mid-black fw-bold small mb-1">{t('dashboard.reporting.credit.items')}</p>
                <p className="text-mid-black fw-bold small mb-1">{t('dashboard.reporting.credit.price')}</p>
              </Col>
            </Row>
            <Row>
              <Col md={5} className="d-flex justify-content-between">
                <p className="text-mid-black mb-0">
                  {localStorage.getItem('currency')}
                  {' '}
                  {transactionDetails?.hourly_rate}
                  {' '}
                  x
                  {' '}
                  {transactionDetails?.total_booked_hours}
                  {' '}
                  hours
                </p>
                <p className="text-mid-black mb-0">{transactionDetails?.hourly_rate * transactionDetails?.total_booked_hours}</p>
              </Col>
            </Row>
            <Row>
              <Col md={5} className="d-flex justify-content-between">
                <p className="text-mid-black mb-0">{t('dashboard.reporting.credit.commission')}</p>
                <p className="text-mid-black mb-0">{transactionDetails?.commission}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <ChevronUp className="text-blue" size={19} />;
      }
      return <ChevronDown className="text-blue" size={19} />;
    },
    showExpandColumn: true,
    expandColumnPosition: 'right',
    onlyOneExpanding: true,
  };

  const rowEvents = {
    onClick: (e, row) => {
      dispatch(getPaymentDetails(row?.payment_reference)).then((response) => {
        setTransactionDetails(response.data);
      });
    },
  };
  return (
    <>
      <Row>
        <Col md={12}>
          <BootstrapTable
            keyField="date"
            data={payments}
            columns={columns}
            expandRow={expandRow}
            rowEvents={rowEvents}
            striped
            headerClasses="table-header fw-bold"
            rowClasses="table-body left-border"
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={2} xs={6} className="d-flex align-items-center">
          <h6 className="mb-0 text-space-gray">{`Showing ${payments?.length} of ${paginationMeta?.total}`}</h6>
        </Col>
        <Col md={10} xs={6} className="d-flex justify-content-center">
          <button type="button" className="btn btn-light py-2 px-3" disabled={paginationMeta?.current_page >= totalPages} onClick={loadMore}>
            {t('shared.show_more')}
          </button>
        </Col>
      </Row>
    </>
  );
};

export default Credit;
