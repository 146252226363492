import React from 'react';
import { Lock, X } from 'react-feather';
import { Link } from 'react-router-dom';
import {
  Label, Input, ListInlineItem, List, Modal, ModalBody,
} from 'reactstrap';
import Button from '../../../../components/button';
import mada from '../../../../assets/images/mada.png';
import visa from '../../../../assets/images/visa.png';

const Payment = ({ isOpen, toggleModal }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} className="payment-modal" centered>
      <X className="close-icon cursor-pointer" onClick={toggleModal} size={18} />
      <ModalBody className="p-5">
        <section>
          <h4 className="text-center fw-bold mb-0">Payment total: 4200 SAR</h4>
          <h6 className="text-center">
            <Lock size={14} />
            {' '}
            Your payment is stored securely.
          </h6>
        </section>
        <section className="mb-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Label className="mb-0 fw-bold" for="payment_method">
              Pay with
            </Label>
            <List className="mb-0 payment-methods">
              <ListInlineItem><img src={visa} alt="Visa" /></ListInlineItem>
              <ListInlineItem><img src={mada} alt="Mada" /></ListInlineItem>
            </List>
          </div>
          <Input
            id="payment_method"
            name="payment_method"
            placeholder="Credit or debit card"
            type="select"
          >
            <option>****5195 (Exp. 7/2024</option>
          </Input>
        </section>
        <section>
          <h6 className="text-dark fw-bold mb-2">Cancellation Policy</h6>
          <h6 className="text-dark fw-bold mb-3">Very Flexible</h6>
        </section>
        <section>
          <p className="text-dark lg mb-3">Guests may cancel their Booking until 24 hours before the event start time and will receive a full refund for (include all Fees) of their Booking Price.</p>
          <p className="text-dark lg mb-0">
            Please see our
            {' '}
            <Link className="text-blue" to="/">cancellation policy</Link>
            {' '}
            for more information.
          </p>
          <Button className="blue-btn w-100 mt-4" title="Pay Now" />
        </section>
      </ModalBody>
    </Modal>
  );
};

export default Payment;
