import React from 'react';
import { X } from 'react-feather';
import StarRatings from 'react-star-ratings';
import {
  Card, Row, Col, CardBody,
} from 'reactstrap';
import imageAlt from '../../../../assets/images/listing_image.png';

const InfoWindow = ({ listing, address, closeInfoWindow }) => {
  return (
    <Card className="info-window shadow-sm border-0">
      <Row>
        <Col xs={5} className="pe-0">
          <img src={listing.primary_image?.url || imageAlt} alt={listing.listing_data?.title} />
        </Col>
        <Col xs={7} className="ps-0">
          <CardBody>
            <div className="d-flex justify-content-between">
              <h6 className="text-dark mb-1">
                {listing.listing_data?.title ?? 'Untitled'}
              </h6>
              <X size={18} className="cursor-pointer" onClick={closeInfoWindow} />
            </div>
            <p className="small text-blue mb-1">{address}</p>
            <StarRatings
              rating={listing?.listing_data?.average_rating || 0}
              starRatedColor="#fdc500"
              numberOfStars={5}
              starDimension="20px"
              starSpacing="2px"
              isSelectable={false}
            />
          </CardBody>
        </Col>
      </Row>
    </Card>
  );
};

export default InfoWindow;
