import React from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { PlayCircle } from 'react-feather';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';

const mapContainerStyle = { width: '100%', height: '100%' };

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="pt-5">
        <Container>
          <Row>
            <Col md="12">
              <div className="header-container d-flex flex-column justify-content-center align-items-center text-center">
                <h6 className="text-white">{t('aboutUs.mission')}</h6>
                <h2 className="text-white fw-bold">{t('aboutUs.headerTitle')}</h2>
                <div className="mt-2 d-flex flex-row justify-content-center align-items-center">
                  <PlayCircle className="text-white" />
                  <p className="text-white mb-0 ms-2">{t('aboutUs.ceoMessage')}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col md="12">
              <h4 className="text-dark fw-bold text-center">{ t('aboutUs.title')}</h4>
            </Col>
            <Col md="8">
              <p className="text-dark-gray font-weight-regular text-center mb-0">
                {t('aboutUs.subtitle')}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="listings-section py-4">
        <Container>
          <Row>
            <Col md="6">
              <div className="bg-light p-4">
                <h4 className="fw-bold text-dark">{t('aboutUs.guests')}</h4>
                <p className="font-weight-regular text-dark-gray">
                  {t('aboutUs.forGuest')}
                </p>
                <Button className="blue-btn br-0" title={t('aboutUs.findSpace')} />
              </div>
              <div className="listing-container" />
            </Col>
            <Col md="6">
              <div className="listing-container" />
              <div className="bg-light p-4">
                <h4 className="fw-bold text-dark">{t('aboutUs.host')}</h4>
                <p className="font-weight-regular text-dark-gray">
                  {t('aboutUs.forHost')}
                </p>
                <Button className="blue-btn br-0" title={t('aboutUs.becomeHost')} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="logos-section py-5 my-5">
        <Container>
          <Row>
            <Col md={2}>
              <div className="logo-container d-flex justify-content-center align-items-center">
                <h4 className="text-center text-white text-uppercase m-0">logo</h4>
              </div>
            </Col>
            <Col md={2}>
              <div className="logo-container d-flex justify-content-center align-items-center">
                <h4 className="text-center text-white text-uppercase m-0">logo</h4>
              </div>
            </Col>
            <Col md={2}>
              <div className="logo-container d-flex justify-content-center align-items-center">
                <h4 className="text-center text-white text-uppercase m-0">logo</h4>
              </div>
            </Col>
            <Col md={2}>
              <div className="logo-container d-flex justify-content-center align-items-center">
                <h4 className="text-center text-white text-uppercase m-0">logo</h4>
              </div>
            </Col>
            <Col md={2}>
              <div className="logo-container d-flex justify-content-center align-items-center">
                <h4 className="text-center text-white text-uppercase m-0">logo</h4>
              </div>
            </Col>
            <Col md={2}>
              <div className="logo-container d-flex justify-content-center align-items-center">
                <h4 className="text-center text-white text-uppercase m-0">logo</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <h4 className="fw-bold text-dark">{t('aboutUs.contactUs')}</h4>
            </Col>
          </Row>
          <Row className="my-2 g-0">
            <Col md={6}>
              <div className="bg-light p-5">
                <h4 className="fw-bold text-dark mb-3">{t('aboutUs.general')}</h4>
                <p className="font-weight-regular text-dark-gray m-0">114 Sansome Street, Suite 1100</p>
                <p className="font-weight-regular text-dark-gray">San Francisco, CA 94104</p>
                <p className="font-weight-regular text-dark-gray">
                  <span>{t('aboutUs.emailUs')}</span>
                  <a className="text-underline text-blue ms-1" href="mailto:contact@LeWeen.com">contact@LeWeen.com</a>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={{ lat: 37.791439, lng: -122.400612 }}
                zoom={15}
              >
                <Marker
                  key={`${37.791439}-${-122.400612}`}
                  position={{ lat: 37.791439, lng: -122.400612 }}
                />
              </GoogleMap>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="mt-4">
        <Container>
          <Row className="mb-5 g-0">
            <Col md={6}>
              <div className="bg-light p-5 h-100">
                <h4 className="fw-bold text-dark mb-3">{t('aboutUs.support')}</h4>
                <p className="font-weight-regular text-dark-gray m-0">
                  <span>{t('aboutUs.contactSupport')}</span>
                  <a className="text-underline text-blue ms-1" href="mailto:help@LeWeen">help@LeWeen.com</a>
                </p>
                <p className="font-weight-regular text-dark-gray m-0">
                  <span>{t('aboutUs.supportPage')}</span>
                  <Link className="text-underline text-blue ms-1" to="/faq">FAQ page</Link>
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="bg-light p-5 h-100">
                <h4 className="fw-bold text-dark mb-3">{t('aboutUs.press')}</h4>
                <p className="font-weight-regular text-dark-gray">
                  <span>{t('aboutUs.pressTitle')}</span>
                  <a className="text-underline text-blue ms-1" href="mailto:press@LeWeen.com">press@LeWeen.com</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default About;
