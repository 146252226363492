import { React } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const BookingCard = ({ data }) => {
  const history = useHistory();

  const { t } = useTranslation();

  const renderBorderColor = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return 'left-orange-border';
      case 'booking-approved':
        return 'left-dark-border';
      default:
        return '';
    }
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case 'waiting-host-approval':
      case 'pending-payment':
        return 'waiting';
      case 'booking-approved':
        return 'confirmed';
      case 'booking-rejected':
        return 'declined';
      case 'booking-canceled-by-user':
      case 'booking-canceled-by-host':
      case 'booking-held':
        return 'canceled';
      case 'draft':
        return 'in-review';
      case 'booking-completed':
        return 'completed';
      default:
        return '';
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return t('dashboard.bookings.pending_host_approval');
      case 'booking-approved':
        return t('dashboard.bookings.confirmed');
      case 'booking-rejected':
        return t('dashboard.bookings.rejected');
      case 'booking-canceled-by-user':
      case 'booking-canceled-by-host':
        return t('dashboard.bookings.canceled');
      case 'draft':
        return t('dashboard.bookings.draft');
      case 'booking-completed':
        return t('dashboard.bookings.completed');
      case 'pending-payment':
        return 'Pending payment';
      case 'booking-held':
        return 'Booking Held';
      default:
        return null;
    }
  };

  const navigateToBookingDetails = (status) => {
    if (status === 'draft') {
      history.push(`/booking/request/${data.id}`);
      return;
    }

    history.push(`/guest/bookings/${data.id}`);
  };
  return (
    <div onClick={() => navigateToBookingDetails(data?.status)}>
      <div className={`booking-card ${renderBorderColor(data?.status)}`}>
        <Row>
          <Col md={4}>
            <img src={data?.listing?.primary_image?.url} alt="booking" className="listing-img" />
          </Col>
          <Col md={8}>
            <div className="content-container">
              <Row className="mb-md-5 mb-sm-3">
                <Col>
                  <h5 className="fw-bold mb-0">{data?.title || 'Untitled'}</h5>
                </Col>
                <Col lg="auto" md={12}>
                  <h6 className="text-space-gray mb-0">
                    {DateTime.fromISO(data?.created_at).toFormat('MMMM dd, yyyy')}
                    {' '}
                    at
                    {' '}
                    {DateTime.fromISO(data?.created_at).toFormat('HH:00a')}
                  </h6>
                </Col>
              </Row>
              <hr />
              <Row className="d-flex align-items-center">
                <Col md="auto" xs={6}>
                  <h6 className="text-space-gray mb-1">{t('dashboard.bookings.attendees')}</h6>
                  <h6 className="text-dark mb-0">{`${data?.number_of_guests || '-'} ${t('dashboard.bookings.number_of_guests')}`}</h6>
                </Col>
                <Col md="auto" xs={6}>
                  <h6 className="text-space-gray mb-1">{t('dashboard.bookings.hours')}</h6>
                  <h6 className="text-dark mb-0">{`${data?.duration / 60 || 0} ${t('dashboard.bookings.hrs')}`}</h6>
                </Col>
                <Col md="auto" xs={6}>
                  <h6 className="text-space-gray mb-1">{t('dashboard.bookings.date')}</h6>
                  <h6 className="text-dark mb-0">{DateTime.fromISO(data?.start_datetime).toFormat('MM-dd-yyyy')}</h6>
                </Col>
                <Col md="auto" xs={6} className="ms-md-auto flex-column d-flex align-items-end">
                  <h6 className="fw-bold mb-0">{`${localStorage.getItem('currency')} ${data?.total_amount}`}</h6>
                  <div className={`status d-flex align-items-center ${renderStatusColor(data?.status)}`}>
                    <p className="fw-bold mb-0">{renderStatus(data?.status)}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BookingCard;
