import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const OperatingHours = ({ data }) => {
  const { t } = useTranslation();
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (data) {
      const newAvailability = {};
      const keys = Object.keys(data);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        const parsedData = data[key];
        // parsedData.allDay = parsedData.allDay.toString();
        newAvailability[key] = parsedData;
      }
      setDays(newAvailability);
    }
  }, [data]);
  return (
    <Row>
      <Col md={12}>
        <div className="py-4">
          <h5 className="text-dark fw-bold mb-3">{t('listing_details.operating_hours')}</h5>
          <Row>
            <Col md={12}>
              {data
              && (
                <>
                  {t('shared.days', { returnObjects: true }).map((day, index) => {
                    const dayData = days[day.id];
                    return (
                      <div className={`px-3 py-2 d-flex justify-content-between align-items-center ${index % 2 === 0 ? 'bg-light' : ''}`} key={day.id}>
                        <h6 className="text-blue font-weight-normal mb-0">{day.label}</h6>
                        <div>
                          {dayData?.status
                            ? (
                              <>
                                {dayData.allDay === true
                                  ? <h6 className="text-blue font-weight-normal mb-0">{t('shared.all_day')}</h6>
                                  : (
                                    <>
                                      {dayData.times?.map((time) => (
                                        <h6 className="text-blue font-weight-normal mb-0" key={day.label}>
                                          {time.from}
                                          {' '}
                                          -
                                          {' '}
                                          {time.to}
                                        </h6>
                                      ))}
                                    </>
                                  )}
                              </>
                            )
                            : <h6 className="text-blue font-weight-normal mb-0">{t('shared.not_available')}</h6>}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default OperatingHours;
