import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import getBookingPayment from 'leween-react-sdk/redux/actions/payments.actions';
import { DateTime } from 'luxon';
import check from '../../../assets/images/check.svg';
import cross from '../../../assets/images/red-x.svg';

const ConfirmBooking = () => {
  const dispatch = useDispatch();
  const [transactionDetails, setTransactionDetails] = useState({});

  useEffect(() => {
    const transactionId = new URL(window.location.href).searchParams.get('id');
    dispatch(getBookingPayment(transactionId)).then((response) => {
      setTransactionDetails(response.data);
    });
  }, []);

  const renderSuccessfulTransaction = () => {
    return (
      <>
        <img src={check} alt="icon" height={65} />
        <h3 className="text-dark text-center fw-bold my-2">Awesome!</h3>
        <p className="text-dark h5 text-center mb-1">We notified the host about your booking.</p>
        <p className="text-dark h5 text-center mb-3">You will hear from us soon</p>
        <p className="text-dark text-center mb-0">
          <span className="text-blue fw-bold">Transaction amount:</span>
          {' '}
          {transactionDetails?.amount}
          {' '}
          {transactionDetails?.currency}
        </p>
        <p className="text-dark text-center mb-4">
          <span className="text-blue fw-bold">Transaction date and time:</span>
          {' '}
          {DateTime.fromISO(transactionDetails?.created_at).toFormat('yyyy-MM-dd . HH:mm a')}
        </p>
        <Link to={`/guest/bookings/${transactionDetails?.booking_id}`} className="btn blue-btn px-4">Click to see your booking</Link>
      </>
    );
  };

  const renderFailedTransaction = () => {
    return (
      <>
        <img src={cross} alt="icon" height={65} />
        <h3 className="text-dark text-center fw-bold my-2">Ops!</h3>
        <p className="text-dark h5 text-center mb-1">Your payment did not go through</p>
        <p className="text-dark h5 text-center mb-3">You can try to pay again</p>
        <p className="text-dark text-center mb-0">
          <span className="text-blue fw-bold">Transaction amount:</span>
          {' '}
          {transactionDetails?.amount}
          {' '}
          {transactionDetails?.currency}
        </p>
        <p className="text-dark text-center mb-4">
          <span className="text-blue fw-bold">Transaction date and time:</span>
          {' '}
          {DateTime.fromISO(transactionDetails?.created_at).toFormat('yyyy-MM-dd . HH:mm a')}
        </p>
        <Link to={`/guest/bookings/${transactionDetails?.booking_id}`} className="btn blue-btn px-4">Click to see your booking</Link>
      </>
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case 1:
        return renderSuccessfulTransaction();
      case 0:
      case -1:
        return renderFailedTransaction();
      default:
        return null;
    }
  };
  return (
    <section className="h-100 d-flex align-items-center">
      <Container>
        <Row>
          <Col md={12} className="d-flex flex-column justify-content-center align-items-center">
            {renderStatus(transactionDetails?.status)}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ConfirmBooking;
