import React, { useCallback } from 'react';
import { FormFeedback } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';

import 'react-datepicker/dist/react-datepicker.css';

const TimePickerInput = ({
  name, control, disabled, errors,
}) => {
  errors = errors || [];

  const extractTime = useCallback((date, onChange) => {
    onChange(DateTime.fromJSDate(date).toFormat('HH:mm'));
  }, []);

  const getValue = useCallback((value) => {
    if (!value) return undefined;

    return DateTime.fromFormat(value, 'HH:mm').toJSDate();
  }, []);

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    < >
      <Controller
        control={control}
        name={name}
        render={({ field: { name, onChange, value }, fieldState: { invalid } }) => (
          <DatePicker
            name={name}
            selected={getValue(value)}
            onChange={(date) => extractTime(date, onChange)}
            invalid={invalid}
            className={`form-control ${invalid && 'is-invalid'}`}
            disabled={disabled}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="h:mm aa"
            autoComplete="off"
            onChangeRaw={handleDateChangeRaw}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormFeedback className="d-block">{message}</FormFeedback>}
      />
    </>
  );
};

export default TimePickerInput;
