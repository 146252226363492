import React, { useEffect, useState } from 'react';
import StarRatings from 'react-star-ratings';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getListingReviews } from 'leween-react-sdk/redux/actions/public-listings.actions';
import avatar from '../../../assets/images/user.png';

const Reviews = ({ listingId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState();
  const [paginationMeta, setPaginationMeta] = useState({});
  const [totalPages, setTotalPages] = useState(1);

  const getReviews = () => {
    dispatch(getListingReviews(listingId, { page: 1, limit: 3 })).then((response) => {
      setReviews(response.data);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  };

  useEffect(() => {
    getReviews();
  }, [listingId]);

  const loadMore = () => {
    dispatch(getListingReviews(listingId, { page: paginationMeta.current_page + 1, limit: 3 })).then((response) => {
      setReviews([...reviews, ...response.data]);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  };

  if (reviews?.length === 0) return null;

  return (
    <Row>
      <Col md={12}>
        <div className="border-top py-4">
          <h5 className="text-dark font-weight-bold mb-0">{`${t('listing_details.reviews')} (${reviews?.length})`}</h5>
          <Row>
            <Col md={12}>
              {reviews?.map((review) => {
                return (
                  <div className="review-card" key={review?.review}>
                    <Row className="mt-4">
                      <Col md="auto" xs="3">
                        <div className="user-avatar">
                          <img src={review?.owner?.profile_image?.original || avatar} alt={review?.owner?.name} />
                        </div>
                      </Col>
                      <Col md="7" xs="9">
                        <h5 className="font-weight-bold text-dark m-0 mt-1">{review?.owner?.name}</h5>
                        <div className="d-flex align-items-center">
                          <StarRatings
                            rating={review.rating}
                            starRatedColor="#fdc500"
                            numberOfStars={5}
                            starDimension="18px"
                            starSpacing="1px"
                          />
                        </div>
                        <p className="font-weight-regular text-blue mt-1 mb-0">
                          {review?.review}
                        </p>
                        <p className="small text-dark mb-0 mt-1 font-weight-regular">{review.created_at}</p>
                      </Col>
                    </Row>
                  </div>
                );
              })}
              {paginationMeta?.current_page < totalPages
              && (
                <Row>
                  <Col md={5} className="d-flex justify-content-center mt-4">
                    <button type="button" className="btn light-btn ms-4" onClick={loadMore}>
                      {t('publicListings.details.moreReviews', { numberOfReviews: paginationMeta?.total - reviews?.length })}
                    </button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Reviews;
