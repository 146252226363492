import React, { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createListingBlockTime, deleteListingBlockTime, getListingBlockTimes } from 'leween-react-sdk/redux/actions/host/listings.actions';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import DateInput from '../../../../components/date-input';
import TimePickerInput from '../../../../components/time-input';

const BlockTime = ({ isOpen, toggle, listingId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hasSpecificHours, toggleSpecificHours] = useState(false);
  const [blockTimes, setBlockTimes] = useState([]);

  const {
    handleSubmit, reset, control, formState: { errors },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    if (!listingId) return;

    dispatch(getListingBlockTimes({
      type: 'listing',
      id: listingId,
    }))
      .then((response) => {
        setBlockTimes(response.data);
      });
  }, [listingId]);

  const getData = () => {
    dispatch(getListingBlockTimes({
      type: 'listing',
      id: listingId,
    }))
      .then((response) => {
        setBlockTimes(response.data);
      });
  };

  const deleteBlockTime = (id) => {
    dispatch(deleteListingBlockTime(id)).then(() => {
      getData();
    });
  };

  const onSubmit = (data) => {
    const newData = {
      from_datetime: `${data.from_date} ${data.from_time || '00:00'}`,
      to_datetime: `${data.to_date} ${data.to_time || '23:00'}`,
      type: 'listing',
      id: listingId,
    };
    dispatch(createListingBlockTime(newData)).then(() => {
      getData();
      reset();
      toggleSpecificHours(false);
    });
  };

  const minDate = useMemo(() => {
    return DateTime.now().plus({ days: 1 }).toJSDate();
  }, []);
  return (
    <Modal className="silver-modal" isOpen={isOpen} toggle={() => toggle()} centered>
      <X className="modal-close-icon cursor-pointer" onClick={() => toggle()} size={18} />
      <ModalBody className="p-md-5">
        <Row>
          <Col md={12}>
            <h4 className="text-center fw-bold mb-5">{t('dashboard.bookings.block_time')}</h4>
          </Col>
        </Row>
        {blockTimes?.length > 0 ? (
          <>
            {blockTimes?.map((time) => {
              return (
                <>
                  <div className="faded-silver-bg rounded p-3 mx-3">
                    <Row className="d-flex justify-content-center mb-1">
                      <Col xs={5}>
                        <p className="text-dark mb-0">
                          {time?.from_datetime
                            ?.split(' ')[0]
                            .toLocaleString(DateTime.DATE_MED)}
                        </p>
                      </Col>
                      <Col
                        xs={1}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <p className="text-dark mb-0">to</p>
                      </Col>
                      <Col xs={5}>
                        <p className="text-dark mb-0">
                          {time?.to_datetime
                            ?.split(' ')[0]
                            .toLocaleString(DateTime.DATE_MED)}
                        </p>
                      </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <Col xs={5}>
                        <p className="text-dark mb-0">
                          {DateTime.fromISO(
                            time?.from_datetime?.split(' ')[1],
                          ).toFormat('HH:mm a')}
                        </p>
                      </Col>
                      <Col
                        xs={1}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <p className="text-dark mb-0">to</p>
                      </Col>
                      <Col xs={5}>
                        <p className="text-dark mb-0">
                          {DateTime.fromISO(
                            time?.to_datetime?.split(' ')[1],
                          ).toFormat('HH:mm a')}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <div className="d-flex justify-content-end mt-1 mx-3">
                    <p
                      className="text-danger cursor-pointer fw-bold"
                      onClick={() => deleteBlockTime(time.id)}
                    >
                      Unblock time
                    </p>
                  </div>
                </>
              );
            })}
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-flex justify-content-center">
              <Col xs={5}>
                <DateInput
                  name="from_date"
                  control={control}
                  errors={errors}
                  minDate={minDate}
                />
              </Col>
              <Col
                xs={1}
                className="d-flex align-items-center justify-content-center"
              >
                <h6 className="text-center mb-0">to</h6>
              </Col>
              <Col xs={5}>
                <DateInput
                  name="to_date"
                  control={control}
                  errors={errors}
                  minDate={minDate}
                />
              </Col>
            </Row>
            {hasSpecificHours && (
              <Row className="mt-3 d-flex justify-content-center">
                <Col xs={5}>
                  <TimePickerInput
                    name="from_time"
                    control={control}
                    errors={errors}
                  />
                </Col>
                <Col
                  xs={1}
                  className="d-flex align-items-center justify-content-center"
                >
                  <h6 className="text-center mb-0">to</h6>
                </Col>
                <Col xs={5}>
                  <TimePickerInput
                    name="to_time"
                    control={control}
                    errors={errors}
                  />
                </Col>
              </Row>
            )}
            <Row className="mt-3 d-flex justify-content-center">
              <Col md={11}>
                <FormGroup check>
                  <Input
                    type="checkbox"
                    onChange={(e) => toggleSpecificHours(e.target.checked)}
                    checked={hasSpecificHours}
                  />
                  <Label check>{t('dashboard.bookings.specific_hours')}</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4 d-flex justify-content-center">
              <Col md={11}>
                <button type="submit" className="btn blue-btn w-100">
                  {t('shared.save')}
                </button>
              </Col>
            </Row>
          </form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default BlockTime;
