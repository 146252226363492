import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import {
  Row,
  Col,
  Label,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getUser, updateUser, uploadUserImage, confirmEmail,
} from 'leween-react-sdk/redux/actions/user.actions';
import parsePhoneNumber from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import InputField from '../../../../components/input';
import Button from '../../../../components/button';
import avatar from '../../../../assets/images/user.png';
import schema from './schema';
import OTP from '../../../auth/otp';
import { setServerErrors } from '../../../../helpers';
import alertCircle from '../../../../assets/images/yellow-info.svg';
import PhoneField from '../../../../components/phone-input';

export const getDirtyValues = (dirtyFields, allValues) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      getDirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};

const PersonalInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [imageSrc, setImage] = useState(undefined);
  const [user, setUser] = useState({});
  const [isOtpModalOpen, toggleOtpModal] = useState(false);
  const [otpId, setOtpId] = useState(null);

  const {
    handleSubmit, control, register, reset, setError, formState: { errors, dirtyFields },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const getUserDetails = () => {
    dispatch(getUser()).then((response) => {
      setUser(response.data);
      reset(response.data);
    });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const onSubmit = (data) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);
    let newData = { ...dirtyValues };
    let params;
    if (dirtyValues.phone_number) {
      const parsedPhoneNumber = parsePhoneNumber(`+${dirtyValues.phone_number}`);
      newData = {
        ...dirtyValues,
        phone_number: parsedPhoneNumber?.nationalNumber,
        phone_country: parsedPhoneNumber?.country,
      };
    }
    if (dirtyValues.email) {
      params = { callback_url: `${window.origin}/confirm-email` };
    }
    toast.promise(
      dispatch(updateUser(newData, params)).then(() => {
        getUserDetails();
      }),
      {
        loading: 'Updating...',
        success: <b>{dirtyValues.email ? 'Please check your email' : 'Updated successfully!'}</b>,
        error: <b>Error occurred while updating.</b>,
      },
    );
  };

  const openUploader = () => {
    inputRef.current.click();
  };

  const uploadImage = (e) => {
    const imageBlob = URL.createObjectURL(e.target.files[0]);
    setImage(imageBlob);

    const formData = new FormData();
    formData.append('profile_image', e.target.files[0]);
    toast.promise(
      dispatch(uploadUserImage(formData)).then(() => {
        getUserDetails();
      }),
      {
        loading: 'Uploading...',
        success: <b>Image uploaded successfully!</b>,
        error: <b>Error occurred while uploading.</b>,
      },
    );
  };

  const verifyPhoneNumber = () => {
    const parsedPhoneNumber = parsePhoneNumber(`+${user?.phone_number}`);
    const data = { phone_number: parsedPhoneNumber?.nationalNumber, phone_country: parsedPhoneNumber?.country };
    dispatch(updateUser(data)).then((response) => {
      setOtpId(response.meta.otp_id);
      if (response?.data?.meta_data?.need_verification?.includes('phone_number')) {
        toggleOtpModal(!isOtpModalOpen);
      }
      getUserDetails();
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };

  const updateUserDetails = useCallback(() => {
    getUserDetails();
  }, []);

  const verifyEmail = () => {
    dispatch(confirmEmail({ callback_url: `${window.origin}/confirm-email` })).then(() => {
      toast.success('Please check your email');
    }).catch(() => {
      toast.error('Error occurred, please try again');
    });
  };

  return (
    <>
      <Row className="mb-4">
        <Col md={11}>
          <h4 className="fw-bold">{t('profile.title')}</h4>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-4">
          <Col md={12}>
            <h6 className="fw-bold mb-4">{t('profile.photo')}</h6>
          </Col>
          <Col md={2}>
            {imageSrc
              ? <img src={imageSrc} alt="User" className="avatar-img" />
              : (
                <div className="avatar-content">
                  <img src={avatar} alt="Avatar" className="avatar-img" />
                </div>
              )}
          </Col>
          <Col md={10} className="d-flex flex-column justify-content-center">
            <div className="cursor-pointer" onClick={openUploader}>
              <input type="file" hidden ref={inputRef} onChange={(e) => uploadImage(e)} />
              <p className="text-blue mb-1">{t('complete_profile.profile_photo')}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Label className="fw-bold mb-1">
              {t('shared.first_name')}
              {' '}
              <strong className="text-blue">*</strong>
            </Label>
            <InputField
              errors={errors}
              name="first_name"
              type="text"
              className="md-height"
              {...register('first_name')}
            />
          </Col>
          <Col md="6">
            <Label className="fw-bold mb-1">
              {t('shared.last_name')}
              {' '}
              <strong className="text-blue">*</strong>
            </Label>
            <InputField
              errors={errors}
              name="last_name"
              type="text"
              className="md-height"
              {...register('last_name')}
            />
          </Col>
          <Col md="6">
            <div className="position-relative">
              <Label className="fw-bold mb-1">
                {t('shared.phone')}
                {' '}
                <strong className="text-blue">*</strong>
              </Label>
              <PhoneField
                name="phone_number"
                control={control}
                errors={errors}
                {...register('phone_number')}
                theme="light"
                wrapperClassName="mb-1"
              />
              {user?.meta_data?.need_verification?.includes('phone_number')
                && <img src={alertCircle} alt="alert" className="input-icon" />}
            </div>
            {user?.meta_data?.need_verification?.includes('phone_number')
              && <p className="text-blue cursor-pointer" onClick={verifyPhoneNumber}>{t('shared.verify_phone')}</p>}
          </Col>
          <Col md="6">
            <div className="position-relative">
              <Label className="fw-bold mb-1">
                {t('shared.email')}
                {' '}
                <strong className="text-blue">*</strong>
              </Label>
              <InputField
                errors={errors}
                name="email"
                type="email"
                className="md-height"
                {...register('email')}
                wrapperClassName="mb-1"
              />
              {user?.meta_data?.need_verification?.includes('email')
                && <img src={alertCircle} alt="alert" className="input-icon" />}
            </div>
            {user?.meta_data?.need_verification?.includes('email')
              && <p className="text-blue cursor-pointer" onClick={verifyEmail}>{t('shared.confirm_email')}</p>}
          </Col>
        </Row>
        {/* <Row className="mt-4 mb-5">
          <Col md={12}>
            <h6>
              {t('profileSettings.personalInfo.governmentID')}
              {' '}
              <img src={ID} alt={t('profileSettings.personalInfo.governmentID')} />
            </h6>
            <p dangerouslySetInnerHTML={{ __html: t('profileSettings.personalInfo.idNote') }} />
            <p className="text-space-gray">{t('profileSettings.personalInfo.uploadingFileExamples')}</p>
          </Col>
          <Col md={8}>
            <div className="dropzone-file-uploader">
              <div className="blue-container" onClick={openIdentificationUploader}>
                <h6 className="text-dark-gray mb-0">
                  <span className="text-blue">{t('profileSettings.personalInfo.addFile') }</span>
                </h6>
                <input type="file" hidden ref={identificationRef} onChange={(e) => uploadIdentificationImg(e)} />
              </div>
            </div>
          </Col>
          {user?.identification_image
          && (
            <Col md={8}>
              <div className="img-preview">
                <img src={user?.identification_image} alt="identification_image" />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col md="12">
            <h6 className="fw-bold mb-3">{t('profileSettings.personalInfo.additionalInfo')}</h6>
          </Col>
          <Col md="6">
            <Label className="fw-bold mb-1">{t('profileSettings.personalInfo.companyName')}</Label>
            <InputField
              errors={errors}
              name="company_name"
              type="text"
              placeholder={t('profileSettings.personalInfo.companyName')}
              className="md-height"
              {...register('additional_info.company_name')}
            />
          </Col>
          <Col md="6">
            <Label className="fw-bold mb-1">{t('profileSettings.personalInfo.role')}</Label>
            <InputField
              errors={errors}
              name="career"
              type="text"
              placeholder={t('profileSettings.personalInfo.rolePlaceholder')}
              className="md-height"
              {...register('additional_info.career')}
            />
          </Col>
          <Col md="12">
            <div className="d-flex justify-content-between mb-1">
              <Label className="fw-bold mb-0">{t('profileSettings.personalInfo.about')}</Label>
              <p className="text-space-gray mb-0 small">{t('profileSettings.personalInfo.hint') }</p>
            </div>
            <InputField
              errors={errors}
              name="about"
              type="textarea"
              placeholder={t('profileSettings.personalInfo.aboutPlaceholder')}
              rows={3}
              {...register('additional_info.about')}
            />
          </Col>
        </Row> */}
        <Row className="my-4">
          <Col md={12}>
            <Button className="blue-btn px-3" title={t('shared.save')} />
          </Col>
        </Row>
      </form>
      {isOtpModalOpen && <OTP isModalOpen={isOtpModalOpen} toggleModal={toggleOtpModal} otpId={otpId} updateUserDetails={updateUserDetails} />}
    </>
  );
};

export default PersonalInfo;
