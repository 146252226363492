import React from 'react';
import { Form, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createPayoutMethod } from 'leween-react-sdk/redux/actions/host/payouts.actions';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../components/input';
import schema from '../schema';
import BinaryInput from '../../../../components/binary-input';

const CreatePayout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, register, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    dispatch(createPayoutMethod(data)).then(() => {
      history.push('/host/dashboard/payouts');
    });
  };
  return (
    <section className="payouts">
      <Row className="mb-4">
        <Col md={12}>
          <h4 className="fw-bold text-dark">{t('dashboard.payouts.add')}</h4>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <InputField
              label={t('dashboard.payouts.bank_name')}
              name="bank_name"
              errors={errors}
              type="text"
              {...register('bank_name')}
            />
          </Col>
          <Col md={6}>
            <InputField
              label={t('dashboard.payouts.branch_address')}
              name="branch_address"
              errors={errors}
              type="text"
              {...register('branch_address')}
            />
          </Col>
          <Col md={6}>
            <InputField
              label={t('dashboard.payouts.full_name')}
              name="full_name"
              errors={errors}
              type="text"
              {...register('full_name')}
            />
          </Col>
          <Col md={6}>
            <InputField
              label={t('dashboard.payouts.swift_code')}
              name="swift_code"
              errors={errors}
              type="text"
              {...register('swift_code')}
            />
          </Col>
          <Col md={6}>
            <InputField
              label={t('dashboard.payouts.iban')}
              name="iban"
              errors={errors}
              type="text"
              {...register('iban')}
            />
          </Col>
          <Col md={6} className="d-flex align-items-center">
            <BinaryInput
              type="checkbox"
              name="is_primary"
              value
              label={t('dashboard.payouts.mark_primary')}
              {...register('is_primary')}
              className="pt-3"
            />
          </Col>
          <Col md={12} className="d-flex justify-content-end">
            <button type="submit" className="btn yellow-btn mt-3 px-5">{t('shared.save')}</button>
          </Col>
        </Row>
      </Form>
    </section>
  );
};

export default CreatePayout;
