import React from 'react';
import {
  Container, Modal, ModalBody, Row, Col,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../../../components/button';

const ConfirmationModal = ({ isModalOpen, toggleModal }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector((state) => state.user.data);

  const handleSubmit = () => {
    toggleModal(!isModalOpen);
    history.push('/host/dashboard/listings/all');
  };
  return (
    <>
      <Modal isOpen={isModalOpen} toggle={() => toggleModal()} centered backdrop="static">
        <ModalBody className="p-5">
          <Container>
            <Row>
              <Col md="12" className="d-flex flex-column align-items-center justify-content-center">
                <h1> 🤝 </h1>
                <h3 className="text-dark fw-bold text-center">{`${t('shared.congrats')} ${user?.first_name}!`}</h3>
                <p className="text-blue text-center">{t('shared.congrats_msg')}</p>
                <Button className="yellow-btn w-100" title={t('shared.continue')} onClick={handleSubmit} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
