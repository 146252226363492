import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { deletePayoutMethod, getPayoutMethods } from 'leween-react-sdk/redux/actions/host/payouts.actions';
import { useTranslation } from 'react-i18next';
import EmptyPlaceholder from '../../../../components/empty-placeholder';

const Payouts = () => {
  const dispatch = useDispatch();
  const [payoutMethods, setPayoutMethods] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  const getMethods = () => {
    dispatch(getPayoutMethods()).then((response) => {
      setPayoutMethods(response.data);
    });
  };

  useEffect(() => {
    getMethods();
  }, []);

  const deleteMethod = (id) => {
    dispatch(deletePayoutMethod(id)).then(() => {
      getMethods();
    });
  };
  return (
    <section className="payouts">
      <Row className="mb-4">
        <Col md={12} className="d-flex align-items-center justify-content-between">
          <div>
            <h4 className="fw-bold text-dark">{t('dashboard.payouts.title')}</h4>
            <p className="mb-0 text-dark-gray">
              {t('dashboard.payouts.subtitle')}
            </p>
          </div>
          <Link to="/host/dashboard/payouts/create" className="btn yellow-btn">{t('dashboard.payouts.add')}</Link>
        </Col>
      </Row>
      {payoutMethods?.length === 0 ? <EmptyPlaceholder text={t('dashboard.payouts.empty_placeholder')} />
        : (
          <>
            {payoutMethods?.map((method) => {
              return (
                <Row className="d-flex align-items-center mb-3" key={method.id}>
                  <Col md={6}>
                    <div className="d-flex align-items-center">
                      <p className="text-dark mb-0">
                        {method.bank_name}
                        {' '}
                        -
                        {' '}
                        {method.full_name}
                      </p>
                    </div>
                  </Col>
                  <Col md={6} className="d-flex align-items-center justify-content-end">
                    <button type="button" className="btn blue-btn me-2" onClick={() => history.push(`/host/dashboard/payouts/edit/${method.id}`)}>{t('shared.edit')}</button>
                    <button type="button" className="btn red-btn" onClick={() => deleteMethod(method.id)}>{t('shared.delete')}</button>
                  </Col>
                </Row>
              );
            })}
          </>
        )}
    </section>
  );
};

export default Payouts;
