import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { FormFeedback } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';

const TimePicker = ({
  name, value, disabled, onChange, invalid, errors,
}) => {
  const extractTime = useCallback((date, onChange) => {
    onChange(DateTime.fromJSDate(date).toFormat('HH:mm'));
  }, []);

  const getValue = useCallback((value) => {
    if (!value) return undefined;

    return DateTime.fromFormat(value, 'HH:mm').toJSDate();
  }, []);

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <DatePicker
        name={name}
        selected={getValue(value)}
        onChange={(date) => extractTime(date, onChange)}
        invalid={invalid}
        className={`form-control ${invalid && 'is-invalid'}`}
        disabled={disabled}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={60}
        timeCaption="Time"
        dateFormat="h:mm aa"
        autoComplete="off"
        onChangeRaw={handleDateChangeRaw}
      />
      {errors && errors[name] && <FormFeedback className="d-block">{errors[name]}</FormFeedback>}
    </>
  );
};

export default TimePicker;
