import React, { useState, useRef, useEffect } from 'react';
import {
  FormGroup, Label, Col, Row,
} from 'reactstrap';
import { createListing, updateListing } from 'leween-react-sdk/redux/actions/host/listings.actions';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete';
import Button from '../../../../../components/button';
import currentLocation from '../../../../../assets/images/current-location.png';

const mapContainerStyle = { width: '100%', height: '50vh' };

const Location = ({ onUpdateListing, listingId, listingCoordinates }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const Map = useRef();

  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const [geocoder, setGeocoder] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  // checking if component is mounted before performing state updates in long running operations
  // else we would get Can't perform a React state update on an unmounted component
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const getCurrentLocation = (geocoder) => {
    // Get user's current location
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        if (!mounted.current) return;
        const position = { lat, lng };
        setCoordinates(position);
        geocoder
          .geocode({ location: position })
          .then(((response) => {
            if (!mounted.current) return;
            setSelectedPlace({ label: response?.results[0]?.formatted_address, value: response?.results[0]?.formatted_address });
          }));
      },
    );
  };

  const onMapLoad = (map) => {
    Map.current = map;

    // Init google maps Geocoder
    const geocoder = new window.google.maps.Geocoder();
    setGeocoder(geocoder);

    getCurrentLocation(geocoder);
  };

  const LocateButton = () => {
    return (
      <div className="current-location" onClick={() => getCurrentLocation(geocoder)}>
        <img src={currentLocation} alt="current location" />
      </div>
    );
  };

  const reverseGeocoding = () => {
    geocoder
      .geocode({ location: coordinates })
      .then(((response) => setSelectedPlace({ label: response?.results[0]?.formatted_address, value: response?.results[0]?.formatted_address })));
  };

  const onCoordsChange = (e) => {
    setCoordinates({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    reverseGeocoding();
  };

  const onLocationFieldChange = (place) => {
    geocodeByAddress(place.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setSelectedPlace(place);
        setCoordinates({ lat: +lat, lng: +lng });
      });
  };

  useEffect(() => {
    if (!listingCoordinates) {
      return;
    }

    const [lat, lng] = listingCoordinates?.split(', ');
    geocodeByLatLng({ lat: +lat, lng: +lng })
      .then((results) => {
        setSelectedPlace({ label: results[4]?.formatted_address, value: results[4]?.formatted_address });
        setCoordinates({ lat: +lat, lng: +lng });
      });
  }, [listingCoordinates]);

  const onSubmit = () => {
    if (listingId) {
      const data = { step: 1, coordinates: `${coordinates.lat}, ${coordinates.lng}` };
      dispatch(updateListing(listingId, data)).then(() => {
        history.push(`/host/listings/${listingId}`);
        onUpdateListing(true);
      });
    } else {
      dispatch(createListing()).then((response) => {
        const data = { step: 1, coordinates: `${coordinates.lat}, ${coordinates.lng}` };
        dispatch(updateListing(response.data.id, data)).then(() => {
          history.push(`/host/listings/${response.data.id}`);
          onUpdateListing(false, response.data.id);
        });
      });
    }
  };
  return (
    <>
      <section>
        <Row className="d-flex align-items-center">
          <Col md={12} className="mb-4">
            <h4 className="fw-bold text-dark">{t('steps.location.headline')}</h4>
            <p className="text-dark-gray">{t('steps.location.subtitle')}</p>
          </Col>
        </Row>
        <FormGroup row>
          <Label className="text-dark-gray" md={12}>{t('steps.location.input_label')}</Label>
          <Col md={6}>
            <GooglePlacesAutocomplete
              selectProps={{
                value: selectedPlace,
                onChange: onLocationFieldChange,
                components: {
                  DropdownIndicator: () => null, IndicatorSeparator: () => null,
                },
                isClearable: false,
              }}
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['sa'],
                },
              }}
            />
          </Col>
        </FormGroup>
        <Row>
          <Col md={12}>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={coordinates}
              zoom={17}
              onClick={onCoordsChange}
              onLoad={onMapLoad}
              draggable
              onDragEnd={() => onCoordsChange({ latLng: Map.current?.getCenter() })}
            >
              <Marker
                key={`${coordinates.lat}-${coordinates.lng}`}
                position={{ ...coordinates }}
                draggable
                onDragEnd={onCoordsChange}
              />
              <LocateButton />
            </GoogleMap>
          </Col>
        </Row>
      </section>
      <section className="bs-footer">
        <Row>
          <Col md={12} className="d-flex justify-content-end">
            <Button className="blue-btn br-0 px-4" title={t('shared.next')} onClick={onSubmit} disabled={!selectedPlace} />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Location;
