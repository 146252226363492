import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getUser, updateUser } from 'leween-react-sdk/redux/actions/user.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
import { checkAuth } from 'leween-react-sdk/redux/actions/auth.actions';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import Button from '../../../components/button';
import schema from './schema';
import { setServerErrors } from '../../../helpers';
import PhoneField from '../../../components/phone-input';

const EditPhoneNumber = ({
  isModalOpen, toggleModal, openOtpModal, updateUserDetails,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit, control, setError, formState: { errors }, reset, register,
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    dispatch(checkAuth()).then((response) => {
      if (response) {
        dispatch(getUser()).then((response) => {
          reset(response.data);
        });
      }
    });
  }, [isModalOpen]);

  const onSubmit = (data) => {
    const parsedPhoneNumber = parsePhoneNumber(`+${data.phone_number}`);
    const newData = { ...data, phone_number: parsedPhoneNumber?.nationalNumber, phone_country: parsedPhoneNumber?.country };
    dispatch(updateUser(newData)).then((response) => {
      if (response?.data?.meta_data?.need_verification?.includes('phone_number')) {
        openOtpModal(response.meta.otp_id);
      }
      if (updateUserDetails) {
        updateUserDetails();
      }
      toggleModal();
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };
  return (
    <Modal className="light-modal" show={isModalOpen} onHide={() => toggleModal()} centered>
      <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
      <Modal.Body>
        <Container className="py-4">
          <Row className="mb-4 mt-2">
            <Col md="12" className="d-flex flex-column align-items-center justify-content-center">
              <h4 className="text-dark fw-bold mb-0">{t('change_phone.title')}</h4>
              <p className="text-dark mb-0">{t('change_phone.subtitle')}</p>
            </Col>
          </Row>
          <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
            <Col md={12}>
              <PhoneField
                name="phone_number"
                control={control}
                errors={errors}
                {...register('phone_number')}
                label={t('shared.phone')}
              />
              <Button className="yellow-btn w-100" title={t('shared.continue')} />
            </Col>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default EditPhoneNumber;
