import React from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import { X } from 'react-feather';
import handsEmoji from '../../../../assets/images/hands-emoji.png';

const SubmitReview = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} centered>
      <X className="modal-close-icon cursor-pointer" onClick={() => toggle()} size={18} />
      <ModalBody className="p-5">
        <Row className="mx-4">
          <Col md={12}>
            <div className="d-flex justify-content-center">
              <img src={handsEmoji} height={60} alt="icon" />
            </div>
            <h6 className="text-center my-4">Thank you for helping us boost our customer experience.</h6>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default SubmitReview;
