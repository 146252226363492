import { React } from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import avatar from '../../../../assets/images/user.png';
import { getActiveLocale } from '../../../../helpers';

const BookingCard = ({ data, showExpirationTime }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const navigateToBookingDetails = (e) => {
    e.stopPropagation();

    history.push(`/host/inbox/${data.id}`);
  };

  const mapStatusToText = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return t('dashboard.bookings.pending');
      case 'booking-approved':
        return t('dashboard.bookings.confirmed');
      case 'booking-rejected':
        return t('dashboard.bookings.rejected');
      case 'booking-canceled-by-user':
        return t('dashboard.bookings.canceled');
      case 'booking-completed':
        return t('dashboard.bookings.completed');
      default:
        return '';
    }
  };

  const mapStatusToClass = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return 'waiting';
      case 'booking-approved':
        return 'confirmed';
      case 'booking-rejected':
        return 'declined';
      case 'booking-canceled-by-user':
        return 'canceled';
      case 'booking-completed':
        return 'completed';
      default:
        return '';
    }
  };
  return (
    <div className="inbox-card cursor-pointer" onClick={(e) => navigateToBookingDetails(e)}>
      <Row>
        <Col md={2}>
          <div className="img-container d-flex flex-column justify-content-center align-items-center">
            {data?.status === 'waiting-host-approval' && (
              <div className="badge blue-badge sm mb-2">
                <p className="text-white mb-0">{t('dashboard.bookings.new_request')}</p>
              </div>
            )}
            <div className="d-flex flex-column align-items-center cursor-pointer">
              <h6 className="text-dark text-center fw-bold">{data.owner?.name}</h6>
              <img src={data.owner?.profile_image?.original || avatar} alt="user" />
            </div>
          </div>
        </Col>
        <Col md={10}>
          <div className="card-content">
            <Row className="">
              <Col className="d-flex align-items-center">
                <h5 className="text-dark mb-0">
                  <span className="fw-bold">{data?.title || 'Untitled'}</span>
                </h5>
              </Col>
              <Col md="auto" className="d-flex align-items-center">
                <h6 className="mb-0 text-space-gray">
                  {DateTime.fromISO(data?.created_at).setLocale(getActiveLocale()).toFormat('MMMM dd, yyyy')}
                  {' '}
                  {DateTime.fromISO(data?.created_at).setLocale(getActiveLocale()).toFormat('HH:00a')}
                </h6>
              </Col>
            </Row>
            <hr className="mt-md-5 mt-3" />
            <Row>
              <Col md={2} xs={4}>
                <h6 className="text-space-gray mb-1 small">{t('dashboard.bookings.attendees')}</h6>
                <h6 className="mb-0">{`${data.number_of_guests} ${t('dashboard.bookings.attendees')}`}</h6>
              </Col>
              <Col md={2} xs={4}>
                <h6 className="text-space-gray mb-1 small">{t('dashboard.bookings.hours')}</h6>
                <h6 className="mb-0">{data.duration / 60}</h6>
              </Col>
              <Col md={2} xs={4}>
                <h6 className="text-space-gray mb-1 small">{t('dashboard.bookings.date')}</h6>
                <h6 className="mb-0">{DateTime.fromISO(data.start_datetime).setLocale(getActiveLocale()).toFormat('MMMM dd, yyyy')}</h6>
              </Col>
              <Col md={6} className="d-flex flex-column align-items-start align-items-md-end justify-content-end mt-md-0 mt-2">
                <h6 className="fw-bold mb-1">{`${localStorage.getItem('currency')} ${data.total_amount}`}</h6>
                {showExpirationTime
                  ? <h6 className="text-space-gray mb-0">{`Expires in ${data.expired_after}`}</h6>
                  : <p className={`status ${mapStatusToClass(data.status)} small mb-0`}>{mapStatusToText(data.status)}</p>}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BookingCard;
