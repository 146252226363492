import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { submitBooking } from 'leween-react-sdk/redux/actions/booking.actions';
import { Shimmer } from 'react-shimmer';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import placeholderImg from '../../../../assets/images/placeholder.png';

const ListingDetails = ({
  activeStep, increaseStep, bookingDetails, listingDetails, isLoading,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { trigger } = useFormContext();

  const submitBookingHandler = () => {
    trigger(['card_id', 'cvv']).then((result) => {
      if (result) {
        dispatch(submitBooking(bookingDetails?.id)).then(() => {
          history.push('/guest/bookings/upcoming');
        //   const html = `
        //   <form id="purchase" style="display: none;" method="POST" action="https://paytest.directpay.sa/SmartRoutePaymentWeb/SRPayMsgHandler">
        //     <input type="hidden" name="MerchantID" value="${response.merchant_id}">
        //     <input type="hidden" name="Amount" value="${response.amount}">
        //     <input type="hidden" name="CurrencyISOCode" value="${response.currency_code}">
        //     <input type="hidden" name="Language" value="${response.language}">
        //     <input type="hidden" name="MessageID" value="${response.message_id}">
        //     <input type="hidden" name="TransactionID" value="${response.transaction_id}">
        //     <input type="hidden" name="PaymentMethod" value="${response.payment_method}">
        //     <input type="hidden" name="Version" value="${response.version}">
        //     <input type="hidden" name="ItemID" value="${response.item_id}">
        //     <input type="hidden" name="SecurityCode" value=${getValues()?.cvv}>
        //     <input type="hidden" name="ResponseBackURL" value="${response.response_back_url}">
        //     <input type="hidden" name="Token" value="${response.card_token}">
        //     <input type="hidden" name="SecureHash" value="${response.secure_hash}">
        //   </form>
        // `;
        //   document.querySelector('body').innerHTML += html;
        //   document.getElementById('purchase').submit();
        });
      }
    });
  };

  const addons = useMemo(() => {
    if (!bookingDetails?.calculation?.cashPaid) return [];

    return bookingDetails?.calculation?.cashPaid;
  }, [bookingDetails]);

  const renderCalculationsLoader = () => {
    return [1, 2, 3, 4].map((index) => <Shimmer key={index} className="w-100 mb-2" height={15} width={100} />);
  };
  return (
    <>
      <div className="cover-img">
        <img src={listingDetails?.primary_image?.url || placeholderImg} alt="cover" />
        <div className="caption">
          <h5 className="text-white fw-bold">{listingDetails?.listing_data?.title || 'Untitled'}</h5>
          <h6 className="text-white opacity-7 mb-0">{listingDetails?.location}</h6>
        </div>
      </div>
      <div className="white-container first-step">
        <div className="d-flex align-items-center justify-content-between my-2">
          <p className="text-dark fw-bold h5 mb-2">{t('shared.price')}</p>
          <div className="d-flex align-items-center">
            <h6 className="text-space-gray mb-0">
              {t('dashboard.bookings.hours')}
              :
              {' '}
              {bookingDetails?.calculation?.bookingDuration}
            </h6>
          </div>
        </div>
        {isLoading
          ? renderCalculationsLoader()
          : (
            <>
              <>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <h6 className="text-dark mb-0">{t('shared.booking_fees')}</h6>
                  <h6 className="text-dark mb-0">
                    {localStorage.getItem('currency')}
                    {' '}
                    {bookingDetails?.calculation?.bookingFees}
                  </h6>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <h6 className="text-dark mb-0">{t('shared.processing_fees')}</h6>
                  <h6 className="text-dark mb-0">
                    {localStorage.getItem('currency')}
                    {' '}
                    {bookingDetails?.calculation?.processingFee}
                  </h6>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <h6 className="text-dark mb-0">{t('steps.activities.discount')}</h6>
                  <h6 className="text-dark mb-0">
                    {localStorage.getItem('currency')}
                    {' '}
                    {bookingDetails?.calculation?.discount}
                  </h6>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <h6 className="text-dark mb-0">{t('shared.vat')}</h6>
                  <h6 className="text-dark mb-0">
                    {localStorage.getItem('currency')}
                    {' '}
                    {bookingDetails?.calculation?.VAT}
                  </h6>
                </div>
              </>
            </>
          )}
        <div className="d-flex align-items-center justify-content-between my-3">
          <h5 className="text-dark fw-bold mb-0">{t('shared.total')}</h5>
          <h5 className="text-dark fw-bold mb-0">
            {localStorage.getItem('currency')}
            {' '}
            {bookingDetails?.calculation?.total}
          </h5>
        </div>
        {addons?.length > 0
          && (
            <>
              <p className="text-dark fw-bold h5 mb-2">Cash Paid Add-ons</p>
              {addons?.map((addon) => {
                return (
                  <div className="d-flex align-items-center justify-content-between mb-2" key={addon.label}>
                    <div className="d-flex align-items-center">
                      <h6 className="text-dark mb-0 opacity-7">
                        {addon?.label}
                      </h6>
                    </div>
                    <h6 className="text-dark mb-0">
                      {localStorage.getItem('currency')}
                      {' '}
                      {addon?.amount}
                    </h6>
                  </div>
                );
              })}
            </>
          )}
        {activeStep === 3
          ? <button type="button" className="btn blue-btn fw-bold w-100 mt-3" onClick={submitBookingHandler}>{t('shared.continue')}</button>
          : <button type="button" className="btn blue-btn fw-bold w-100 mt-3" onClick={() => increaseStep(activeStep + 1)}>{t('shared.continue')}</button>}
      </div>
    </>
  );
};

export default ListingDetails;
