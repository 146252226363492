import { React } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Modal, Row,
} from 'react-bootstrap';
import Button from '../../../../components/button';
import DeleteIcon from '../../../../assets/images/delete-icon.svg';

const ConfirmationModal = ({ isModalOpen, closeModal, deleteListing }) => {
  const { t } = useTranslation();

  return (
    <Modal className="light-modal" show={isModalOpen} onHide={() => closeModal()} centered>
      <X className="close-icon text-dark cursor-pointer" onClick={() => closeModal()} />
      <Modal.Body className="py-5 px-3">
        <Container>
          <Row>
            <Col md={12} className="d-flex flex-column align-items-center justify-content-center">
              <img src={DeleteIcon} width={48} alt="delete" />
              <h5 className="fw-bold my-4">{t('dashboard.ads.delete_confirmation_title')}</h5>
              <p className="text-center">
                {t('dashboard.ads.delete_confirmation_subtitle')}
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Button className="btn light-btn w-100" onClick={closeModal} title={t('dashboard.ads.edit_hours')} />
            </Col>
            <Col md={6}>
              <Button className="btn red-btn w-100" onClick={deleteListing} title={t('dashboard.ads.delete_ad')} />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
