import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ListingCard from '../../../components/cards/ListingCard';

const SimilarListings = ({ listings, updateListings }) => {
  const { t } = useTranslation();
  return (
    <section>
      <Container>
        <Row>
          <Col md={12}>
            <div className="border-top py-5">
              <h5 className="fw-bold mb-4">
                {t('listing_details.similar_ads')}
              </h5>
              <Row>
                {listings?.map((listing) => {
                  return (
                    <Col md={3} key={listing.id} className="mb-3">
                      <ListingCard item={listing} updateList={updateListings} />
                    </Col>
                  );
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SimilarListings;
