import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const CancellationPolicy = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col md={12}>
        <h3 className="text-dark fw-bold mt-5">{t('cancellationPolicy.title')}</h3>
        <p className="text-space-gray fst-italic mt-3 mb-5">{' '}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('cancellationPolicy.generalConditions.title')}</h4>
        <p className="text-dark">
          <span>{t('cancellationPolicy.generalConditions.sectionOne')}</span>
        </p>
        <p className="mb-5">{t('cancellationPolicy.generalConditions.sectionTwo')}</p>
        <p className="mb-5">{t('cancellationPolicy.generalConditions.sectionThree')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('cancellationPolicy.cancellationOptions.title')}</h4>
        <p className="text-dark">{t('cancellationPolicy.cancellationOptions.introduction')}</p>
        <p className="text-dark mb-5">{t('cancellationPolicy.cancellationOptions.choose')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{`i.${t('cancellationPolicy.cancellationOptions.optionOneTitle')}`}</h4>
        <p className="text-dark">{t('cancellationPolicy.cancellationOptions.optionOne')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{`ii.${t('cancellationPolicy.cancellationOptions.optionTwoTitle')}`}</h4>
        <p className="text-dark">{t('cancellationPolicy.cancellationOptions.optionTwo')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{`iii.${t('cancellationPolicy.cancellationOptions.optionThreeTitle')}`}</h4>
        <p className="text-dark">{t('cancellationPolicy.cancellationOptions.optionThree')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{`iv.${t('cancellationPolicy.cancellationOptions.optionFourTitle')}`}</h4>
        <p className="text-dark">{t('cancellationPolicy.cancellationOptions.optionFour')}</p>
      </Col>

      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('cancellationPolicy.confirmCancellation.title')}</h4>
        <p className="text-dark">{t('cancellationPolicy.confirmCancellation.content')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('cancellationPolicy.multiDayBookings.title')}</h4>
        <p className="text-dark">{t('cancellationPolicy.multiDayBookings.content')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('cancellationPolicy.cancellationDeadlines.title')}</h4>
        <p className="text-dark">{t('cancellationPolicy.cancellationDeadlines.content')}</p>
      </Col>

      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('cancellationPolicy.hostCancellation.title')}</h4>
        <p className="text-dark">{t('cancellationPolicy.hostCancellation.sectionOne.content')}</p>
        <ul className="mb-5">
          <li className="text-dark">{t('cancellationPolicy.hostCancellation.sectionOne.pointOne')}</li>
          <li className="text-dark">{t('cancellationPolicy.hostCancellation.sectionOne.pointTwo')}</li>
        </ul>
        <p className="text-dark">{t('cancellationPolicy.hostCancellation.sectionTwo.content')}</p>
      </Col>
    </Row>
  );
};

export default CancellationPolicy;
