import * as yup from 'yup';

const schema = yup.object().shape({
  reason: yup.string('').typeError('Please pick a reason').required('Please pick a reason'),
  reasonComment: yup.string()
    .test('string', 'This field is required', (reasonComment, context) => {
      const { reason } = context.parent;
      let returnValue = true;
      if (reason !== 'Other' || !!reasonComment) {
        returnValue = true;
      } else returnValue = false;
      return returnValue;
    }),
});

export default schema;
