import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { getStatistics } from 'leween-react-sdk/redux/actions/host/reporting.actions';
import Credit from './credit';
import Statistics from './statistics';

const Reporting = () => {
  const dispatch = useDispatch();
  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    dispatch(getStatistics()).then((response) => {
      setStatistics(response.data);
    });
  }, []);
  return (
    <section className="reporting">
      <Statistics data={statistics} />
      <Row>
        <Col md={12}>
          <Credit />
        </Col>
      </Row>
    </section>
  );
};

export default Reporting;
