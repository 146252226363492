import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Container,
  Row,
  Col,
  List,
  ListInlineItem,
} from 'reactstrap';
import { ChevronRight } from 'react-feather';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getBooking, updateBooking } from 'leween-react-sdk/redux/actions/booking.actions';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getListingDetails } from 'leween-react-sdk/redux/actions/public-listings.actions';
import { DateTime } from 'luxon';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import ActivityDetails from './activity';
import ListingDetails from './listing';
import AddOns from './add-ons';
import Payment from './payment';
import schema from './schema';
import { debounce, setServerErrors } from '../../../helpers';

const BookingRequest = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(1);
  const [bookingDetails, setBookingDetails] = useState({});
  const [listingDetails, setListingDetails] = useState({});
  const [isLoading, toggleLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    context: { max_guests: listingDetails?.activity?.max_guests },
    defaultValues: {
      activity_id: undefined,
      number_of_guests: '',
      description: '',
    },
  });
  const {
    reset, getValues, watch, trigger, setError,
  } = methods;

  const increaseStep = useCallback((step) => {
    trigger(['activity_id', 'description', 'number_of_guests', 'time_slots']).then((result) => {
      if (result) {
        setActiveStep(step);
      }
    });
  }, [activeStep, setActiveStep]);

  const getBookingDetails = () => {
    toggleLoading(true);
    dispatch(getBooking(params.bookingId)).then((response) => {
      setBookingDetails(response.data);
      const addonsIds = response.data.addons?.map((addon) => addon.uuid);
      const data = { ...response.data, addons: addonsIds };
      reset(data);

      dispatch(getListingDetails(response.data.listing_id, { category_id: response.data.category_id })).then(async (response) => {
        const [lat, lng] = response.data?.coordinates.split(', ');
        const location = await geocodeByLatLng({ lat: +lat, lng: +lng });
        setListingDetails({ ...response.data, location: location[4]?.formatted_address });
        toggleLoading(false);
      });
    });
  };

  const onSubmit = useCallback(debounce((values) => {
    const newData = {
      ...values,
      activity_id: values.activity_id || undefined,
      number_of_guests: values.number_of_guests || undefined,
      type: 'private',
      start_datetime: DateTime.fromISO(values?.start_datetime).toFormat('yyyy-MM-dd HH:mm') || undefined,
      end_datetime: DateTime.fromISO(values?.end_datetime).toFormat('yyyy-MM-dd HH:mm') || undefined,
      promocode: values?.promocode || undefined,
    };
    if (typeof newData.addons === 'string') {
      newData.addons = [newData.addons];
    }
    dispatch(updateBooking(params.bookingId, newData)).then((response) => {
      setBookingDetails(response.data);
    }).catch((error) => {
      const responseErrors = error?.response?.data?.errors || [];
      setServerErrors(responseErrors, setError);
    });
  }, 500), []);

  useEffect(() => {
    getBookingDetails();
  }, []);

  const newValues = JSON.stringify(watch());
  useEffect(() => {
    const values = getValues();
    onSubmit(values);
  }, [newValues]);

  const isButtonDisabled = useMemo(() => {
    const values = getValues();
    if (!values.activity_id || !values.description || !values.number_of_guests) {
      return true;
    }
    return false;
  }, [activeStep, newValues]);
  return (
    <>
      <section className="py-4">
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="mb-5 text-dark text-center display-4">
                {t('listing_details.booking_request.request')}
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <List className="p-0">
                <ListInlineItem className={`${activeStep !== 1 ? 'opacity-5' : ''}`}>
                  <button type="button" className="btn btn-link text-dark fw-bold p-0" onClick={() => setActiveStep(1)}>
                    {`1. ${t('booking_request.activity')}`}
                  </button>
                </ListInlineItem>
                <ListInlineItem className="text-dark fw-bold cursor-pointer opacity-5"><ChevronRight size={20} className="rotated-chevron" /></ListInlineItem>
                <ListInlineItem className={`${activeStep !== 2 ? 'opacity-5' : ''}`}>
                  <button type="button" className="btn btn-link text-dark fw-bold p-0" disabled={isButtonDisabled} onClick={() => setActiveStep(2)}>
                    {`2. ${t('booking_request.add_ons')}`}
                  </button>
                </ListInlineItem>
                <ListInlineItem className="text-dark fw-bold cursor-pointer opacity-5"><ChevronRight size={20} className="rotated-chevron" /></ListInlineItem>
                <ListInlineItem className={`${activeStep !== 3 ? 'opacity-5' : ''}`}>
                  <button type="button" className="btn btn-link text-dark fw-bold p-0" disabled={isButtonDisabled} onClick={() => setActiveStep(3)}>
                    {`3. ${t('booking_request.confirm')}`}
                  </button>
                </ListInlineItem>
              </List>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <FormProvider {...methods}>
          <Container>
            <Row>
              <Col md={6}>
                <div className={`${activeStep === 1 ? 'd-block' : 'd-none'}`}>
                  <ActivityDetails bookingDetails={bookingDetails} />
                </div>
                <div className={`${activeStep === 2 ? 'd-block' : 'd-none'}`}>
                  <AddOns addons={listingDetails?.addons} />
                </div>
                <div className={`${activeStep === 3 ? 'd-block' : 'd-none'}`}>
                  <Payment bookingId={params.bookingId} />
                </div>
              </Col>
              <Col md={6}>
                <ListingDetails activeStep={activeStep} increaseStep={increaseStep} bookingDetails={bookingDetails} listingDetails={listingDetails} isLoading={isLoading} />
              </Col>
            </Row>
          </Container>
        </FormProvider>
      </section>
    </>
  );
};

export default BookingRequest;
