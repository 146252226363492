import React, { useEffect } from 'react';
import {
  Col,
  Row,
  Container,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getWishlist } from 'leween-react-sdk/redux/actions/guest/wishlist.actions';
import EmptyPlaceholder from '../../../components/empty-placeholder';
import ListingCard from '../../../components/cards/ListingCard';

const LikedSpaces = () => {
  const dispatch = useDispatch();
  const listings = useSelector((state) => state.guest.wishlist);

  useEffect(() => {
    dispatch(getWishlist());
  }, []);

  const renderListings = () => {
    if (listings?.data?.length === 0) {
      return <EmptyPlaceholder text="لا يوجد أماكن مفضلة" />;
    }

    return listings?.data?.map((listing) => (
      <Col md={4} key={listing.id}>
        <ListingCard item={listing} images={[]} />
      </Col>
    ));
  };
  return (
    <>
      <section className="guest-booking pt-5">
        <Container>
          <Row className="mb-4">
            <Col md={12}>
              <h5>
                {`الأماكن المفضلة (${listings?.data?.length})`}
              </h5>
            </Col>
          </Row>
          <Row>
            {renderListings()}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default LikedSpaces;
