import * as yup from 'yup';

function dayValidator() {
  return yup.object({
    times: yup.array().when('allDay', {
      is: false,
      then: yup.array().of(yup.object().shape({
        from: yup.string().required('From field is required'),
        to: yup.string()
          .required('To field is required')
          .test('isGreaterThanFromHour', 'To time should be greater than from time', (to, context) => {
            const { from } = context.parent;
            if (!from || !to) return true;
            return to === '00:00' || to > from;
          }),
      })),
    }),
  });
}

const schema = yup.object().shape({
  sunday: dayValidator(),
  monday: dayValidator(),
  tuesday: dayValidator(),
  wednesday: dayValidator(),
  thursday: dayValidator(),
  friday: dayValidator(),
  saturday: dayValidator(),
});

export default schema;
