import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getHostBookingDetails } from 'leween-react-sdk/redux/actions/host/bookings.actions';
import { useParams } from 'react-router-dom';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import Booking from './booking';
import redInfo from '../../../../assets/images/red-info.png';
import greenInfo from '../../../../assets/images/green-info.svg';
import Button from '../../../../components/button';
import DeclineBooking from './decline';
import ApproveBooking from './approve';
import BookingInfo from './info';

const HostBookingDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bookingId } = useParams();
  const [isDeclineModalOpen, toggleDeclineModal] = useState(false);
  const [isApproveModalOpen, toggleApproveModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState({});
  const [listingLocation, setListingLocation] = useState('');

  const getBooking = () => {
    setIsLoading(true);
    dispatch(getHostBookingDetails(bookingId)).then((response) => {
      const [lat, lng] = response.data.listing?.coordinates.split(', ');
      geocodeByLatLng({ lat: +lat, lng: +lng }).then((results) => setListingLocation(results[4].formatted_address));
      setBooking(response.data);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getBooking();
  }, []);

  const renderBookingStatus = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return (
          <Row className="mb-3">
            <Col md={12}>
              <div className="status-alert platinum-bg">
                <Row>
                  <Col>
                    <h6 className="my-2 fw-bold ">
                      Waiting approval…
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="light-btn w-100"
                      title="Not Available"
                      onClick={() => toggleDeclineModal(!isDeclineModalOpen)}
                    />
                  </Col>
                  <Col>
                    <Button
                      className="blue-btn w-100"
                      title="Accept"
                      onClick={() => toggleApproveModal(!isApproveModalOpen)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="d-flex justify-content-end">
                <small className="text-danger">{booking.expired_after}</small>
              </div>
            </Col>
          </Row>
        );
      case 'booking-approved':
        return (
          <Row className="mb-3">
            <Col>
              <div className="status-alert platinum-bg mb-3">
                <div className="d-flex align-items-center">
                  <img src={greenInfo} alt="info" />
                  <h6 className="fw-bold mb-0 ms-2">Booking Confirmed</h6>
                </div>
                <p className="small text-space-gray mb-0 ms-4">
                  Please follow instructions to your space!
                </p>
              </div>
            </Col>
          </Row>
        );
      case 'booking-rejected ':
        return (
          <div className="d-flex status-alert red-bg px-4">
            <Col className="d-flex align-items-center">
              <img src={redInfo} alt="info" />
              <h6 className="fw-bold mb-0 ms-2">Booking Cancelled</h6>
            </Col>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <section className="mt-3">
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="text-dark mb-4">{t('dashboard.bookings.details')}</h3>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <Container>
          {renderBookingStatus(booking?.status)}
          <Row>
            <Col md={6} className="order-md-1 order-2">
              <Booking booking={booking} listingLocation={listingLocation} isLoading={isLoading} />
            </Col>
            <Col md={6} className="order-md-2 order-1">
              <BookingInfo booking={booking} isLoading={isLoading} getBooking={getBooking} />
            </Col>
          </Row>
        </Container>
      </section>
      <DeclineBooking
        isOpen={isDeclineModalOpen}
        toggleModal={toggleDeclineModal}
        bookingId={booking.id}
        getBooking={getBooking}
      />
      <ApproveBooking
        isOpen={isApproveModalOpen}
        toggleModal={toggleApproveModal}
        guestName={booking.owner?.name}
        bookingId={booking.id}
        getBooking={getBooking}
      />
    </>
  );
};

export default HostBookingDetails;
