import React, { useEffect, useState, forwardRef } from 'react';
import Stepper from 'bs-stepper';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import 'bs-stepper/dist/css/bs-stepper.min.css';

const ListingStepper = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    steps, separator, options, instance, completedStep,
  } = props;

  // ** State
  const [activeIndex, setActiveIndex] = useState(0);

  // ** Vars
  let stepper = null;

  // ** Step change listener on mount
  useEffect(() => {
    stepper = new Stepper(ref.current, options);

    ref.current.addEventListener('shown.bs-stepper', (event) => {
      setActiveIndex(event.detail.indexStep);
    });

    if (instance) {
      instance(stepper);
    }
  }, []);

  // ** Renders stepper header
  const renderHeader = () => {
    return steps.map((step, index) => {
      const isDisabledStep = index > (completedStep || 0);
      return (
        <React.Fragment key={step.id}>
          {index !== 0 && index !== steps.length ? <div className="line">{separator}</div> : null}
          <div
            className={classnames('step', {
              completed: (index + 1) <= completedStep,
              active: index === activeIndex,
            })}
            data-target={`#${step.id}`}
          >
            <button type="button" className="step-trigger" disabled={step.disabled || isDisabledStep}>
              <span className="bs-stepper-circle" />
              <span className="bs-stepper-label">
                <div className="title">{step.title}</div>
                {(index + 1) <= completedStep && <div className="edit text-underline text-dark fw-bold">{t('shared.edit')}</div>}
              </span>
            </button>
          </div>
        </React.Fragment>
      );
    });
  };

  // ** Renders stepper content
  const renderContent = () => {
    return steps.map((step, index) => {
      return (
        <div
          className={classnames('content fade', {
            'active dstepper-block': activeIndex === index,
          })}
          id={step.id}
          key={step.id}
        >
          {step.content}
        </div>
      );
    });
  };

  return (
    <div ref={ref} className="bs-stepper vertical">
      <div className="bs-stepper-header">{renderHeader()}</div>
      <div className="bs-stepper-content">{renderContent()}</div>
    </div>
  );
});

export default ListingStepper;
