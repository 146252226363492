import * as yup from 'yup';

const schema = yup.object().shape({
  email: yup.string().email('Please enter a valid email format').required('This field is required'),
  subject: yup.string().required('This field is required'),
  description: yup.string().required('This field is required'),
  request_type: yup.string().required('This field is required'),
});

export default schema;
