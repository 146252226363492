import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import StarRatings from 'react-star-ratings';

const RatingInput = React.forwardRef(({ ...props }, ref) => {
  React.useImperativeHandle(ref, () => ({}));

  return (
    <FormGroup>
      <Label for={props.id}>{props.label}</Label>
      <Controller
        control={props.control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <StarRatings
            rating={value}
            changeRating={onChange}
            starRatedColor="#00f"
            starHoverColor="#2a32ff"
            numberOfStars={5}
            starDimension="24px"
            starSpacing="1px"
            name={props.name}
          />
        )}
      />
      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => <FormFeedback className="d-block">{message}</FormFeedback>}
      />
    </FormGroup>
  );
});

export default RatingInput;
