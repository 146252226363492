import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ListingImages from './listing-images';

const ListingGallery = ({ data }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <section className="images-grid pt-3">
        <Container>
          <Row>
            <Col md={6}>
              <img
                src={data?.primary_image?.url}
                alt="listing"
                className="main-image cursor-pointer"
                onClick={() => { toggleModal(!isModalOpen); setActiveIndex(data.images.findIndex((img) => data.primary_image.id === img.id)); }}
              />
            </Col>
            <Col md={6}>
              <Row>
                {data.images?.filter((image) => data.primary_image.id !== image.id).slice(0, 4).map((image, index) => (
                  <Col md={6} className={`${(index % 2 === 0) ? 'p-md-0' : ''}`} key={image.id}>
                    <img
                      src={image?.url}
                      alt="listing"
                      className="cursor-pointer"
                      onClick={() => { toggleModal(!isModalOpen); setActiveIndex(data.images.findIndex((img) => img.id === image.id)); }}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <ListingImages isModalOpen={isModalOpen} toggleModal={toggleModal} activeIndex={activeIndex} setActiveIndex={setActiveIndex} images={data?.images} />
    </>
  );
};

export default ListingGallery;
