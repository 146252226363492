import React, {
  useMemo, useCallback, useState, useEffect,
} from 'react';
import { Col, FormFeedback, Row } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import { ErrorMessage } from '@hookform/error-message';
import { Calendar } from 'react-feather';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

const DateTimeInput = ({
  value, onChange, minDate, onDatePickerChange,
}) => {
  const { t } = useTranslation();
  const [selectedDate, selectDate] = useState(null);
  const [selectedStartTime, selectStartTime] = useState(null);
  const [selectedEndTime, selectEndTime] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);

  useEffect(() => {
    if (!value) return;

    selectDate(DateTime.fromISO(value.start_datetime));
    selectStartTime(DateTime.fromISO(value.start_datetime));
    selectEndTime(DateTime.fromISO(value.end_datetime));
  }, [value]);

  useEffect(() => {
    if (!selectedDate || !selectedStartTime || !selectedEndTime) return;

    const newValue = {
      start_datetime: selectedStartTime.set({ year: selectedDate.year, month: selectedDate.month, day: selectedDate.day }).toISO(),
      end_datetime: selectedEndTime.set({ year: selectedDate.year, month: selectedDate.month, day: selectedDate.day }).toISO(),
    };
    onChange(newValue);
  }, [selectedDate?.toISO(), selectedStartTime?.toISO(), selectedEndTime?.toISO()]);

  const onDateChange = useCallback(async (date) => {
    selectDate(DateTime.fromJSDate(date));
    const availableTimes = await onDatePickerChange(DateTime.fromJSDate(date));
    setAvailableTimes(availableTimes?.data);
    selectStartTime(null);
    selectEndTime(null);
  }, [selectDate, onDatePickerChange]);

  const onStartTimeChange = useCallback((time) => {
    selectStartTime(DateTime.fromJSDate(time));
  }, [selectStartTime]);

  const onEndTimeChange = useCallback((time) => {
    selectEndTime(DateTime.fromJSDate(time));
  }, [selectEndTime]);

  const [dateValue, formattedDateValue] = useMemo(() => {
    if (!selectedDate) return [null, t('shared.select_date')];

    const date = selectedDate.toJSDate(DateTime.DATE_MED);
    const formattedDate = selectedDate.toFormat('yyyy-MM-dd');
    return [date, formattedDate];
  }, [selectedDate?.toISO()]);

  const [startTimeValue, startTimeFormatted] = useMemo(() => {
    if (!selectedStartTime) return [null, t('shared.from')];

    const time = selectedStartTime.toJSDate(DateTime.DATE_MED);
    const formattedTime = selectedStartTime.toFormat('HH:mm');
    return [time, formattedTime];
  }, [selectedStartTime?.toISO()]);

  const [endTimeValue, endTimeFormatted] = useMemo(() => {
    if (!selectedEndTime) return [null, t('shared.to')];

    const time = selectedEndTime.toJSDate(DateTime.DATE_MED);
    const formattedTime = selectedEndTime.toFormat('HH:mm');
    return [time, formattedTime];
  }, [selectedEndTime?.toISO()]);

  const filterTimes = useCallback((date) => {
    const formattedTime = DateTime.fromJSDate(date).toFormat('HH:mm:ss');
    const time = availableTimes.find((time) => time.datetime === formattedTime);
    return time?.available || false;
  }, [availableTimes]);
  return (
    <Row className="h-100 text-center" onBlur={(e) => e.stopPropagation()}>
      <Col xs={2} className="h-100 d-flex align-items-center">
        <Calendar />
      </Col>
      <Col xs={4} className="h-100 d-flex align-items-center">
        <DatePicker
          selected={dateValue}
          onChange={onDateChange}
          minDate={minDate}
          customInput={<p className="mb-0 fw-bold">{formattedDateValue}</p>}
        />
      </Col>
      <Col xs={3} className="h-100 d-flex align-items-center">
        <DatePicker
          selected={startTimeValue}
          onChange={onStartTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={60}
          timeCaption="Time"
          dateFormat="h:mm aa"
          customInput={<p className="mb-0 fw-bold">{startTimeFormatted}</p>}
          disabled={!selectedDate}
          filterTime={filterTimes}
        />
      </Col>
      <Col xs={3} className="h-100 d-flex align-items-center">
        <DatePicker
          selected={endTimeValue}
          onChange={onEndTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={60}
          timeCaption="Time"
          dateFormat="h:mm aa"
          customInput={<p className="mb-0 fw-bold">{endTimeFormatted}</p>}
          disabled={!selectedStartTime}
          filterTime={filterTimes}
        />
      </Col>
    </Row>
  );
};

const DateTimeField = ({
  name, control, minDate, errors, onDatePickerChange,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DateTimeInput value={value} onChange={onChange} minDate={minDate} onDatePickerChange={onDatePickerChange} />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormFeedback className="d-block">{message}</FormFeedback>}
      />
    </>
  );
};

export default DateTimeField;
