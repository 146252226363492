import * as yup from 'yup';

const schema = yup
  .object({
    bank_name: yup.string().required('This field is required'),
    branch_address: yup.string().required('This field is required'),
    full_name: yup.string().required('This field is required'),
    swift_code: yup.string().required('This field is required').min(8, 'Swift code must be at least 8 characters').max(11, 'Swift code must be at most 11 characters'),
    iban: yup.string().required('This field is required').min(15, 'IBAN must be at least 15 characters').max(32, 'IBAN must be be at most 32 characters'),
    is_primary: yup.boolean(),
  });

export default schema;
