import React from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch } from 'react-redux';
import { activitiesLookup } from 'leween-react-sdk/redux/actions/activities.actions';
import { InputGroup, InputGroupText } from 'reactstrap';
import { SearchNormal1 } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import selectStyles from '../../globals/styles';

const ActivitiesField = ({ updateSearchParams, value, selectedValue }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadOptions = (inputValue, callback) => {
    dispatch(activitiesLookup({ query: inputValue })).then((result) => {
      const newResult = result.data.map((activity) => ({ value: activity.id, label: activity.name }));
      callback(newResult);
    });
  };
  return (
    <InputGroup className="input-group-merge">
      <InputGroupText>
        <SearchNormal1 size={22} />
      </InputGroupText>
      <AsyncSelect
        cacheOptions
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        defaultOptions
        loadOptions={loadOptions}
        onChange={(option) => updateSearchParams({ 'activities[]': option.value })}
        placeholder={t('home.hero_form.activity')}
        styles={selectStyles}
        value={value || selectedValue}
      />
    </InputGroup>
  );
};

export default ActivitiesField;
