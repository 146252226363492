import { React, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getHostBookings } from 'leween-react-sdk/redux/actions/host/bookings.actions';
import { useTranslation } from 'react-i18next';
import { useParams, NavLink } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import EmptyPlaceholder from '../../../../components/empty-placeholder/index';
import BookingCard from './booking-card';

const BookingsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { status } = useParams();
  const [isLoading, toggleLoading] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    toggleLoading(true);
    dispatch(getHostBookings({ page: 1, filter: [status] })).then((response) => {
      setBookings(response.data);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
      toggleLoading(false);
    });
  }, [status]);

  const loadMore = () => {
    toggleLoading(true);
    dispatch(getHostBookings({ page: paginationMeta.current_page + 1, filter: [status] })).then((response) => {
      setBookings([...bookings, ...response.data]);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
      toggleLoading(false);
    });
  };

  const renderBookingRequests = () => {
    if (isLoading) {
      return [1, 2, 3, 4, 5].map((index) => <Col key={index} md={12}><Shimmer height={200} width={100} className="w-100 mb-3" /></Col>);
    }
    if (bookings.length === 0) {
      return <EmptyPlaceholder text={t('dashboard.bookings.empty_placeholder')} />;
    }
    return bookings.map((booking) => (
      <Col md={12} key={Math.random()}>
        <BookingCard data={booking} showExpirationTime={status === 'upcoming'} />
      </Col>
    ));
  };

  return (
    <section className="inbox-list pb-5">
      <div className="tabs-container">
        <Row>
          <Col md={12}>
            <Nav tabs className="dashboard-tabs">
              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/host/dashboard/inbox/upcoming">
                  {t('dashboard.bookings.upcoming')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/host/dashboard/inbox/confirmed">
                  {t('dashboard.bookings.confirmed')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-link" activeClassName="active" to="/host/dashboard/inbox/past">
                  {t('dashboard.bookings.past')}
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </div>
      <TabContent activeTab={status} className="pt-3">
        <TabPane tabId="upcoming">
          <Row>
            {renderBookingRequests('upcoming')}
          </Row>
        </TabPane>
        <TabPane tabId="confirmed">
          <Row>
            {renderBookingRequests('confirmed')}
          </Row>
        </TabPane>
        <TabPane tabId="past">
          <Row>
            {renderBookingRequests('past')}
          </Row>
        </TabPane>
      </TabContent>
      {bookings?.length > 0
      && (
        <Row className="py-4">
          <Col md={4} className="d-flex align-items-center">
            <h6 className="mb-0 text-space-gray">{`Showing ${bookings?.length} of ${paginationMeta?.total}`}</h6>
          </Col>
          <Col md={8} className="d-flex justify-content-center">
            <button type="button" className="btn light-btn px-3" onClick={loadMore} disabled={paginationMeta?.current_page >= totalPages}>{t('shared.showMore')}</button>
          </Col>
        </Row>
      )}
    </section>
  );
};

export default BookingsList;
