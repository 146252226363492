import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getListingDetails, getPublicListings } from 'leween-react-sdk/redux/actions/public-listings.actions';
import { useParams } from 'react-router-dom';
import SimilarListings from './similar-listings';
import BookingRequest from './booking-request';
import OperatingHours from './operating-hours';
import Parking from './parking';
import Location from './location';
import About from './about';
import ListingInfo from './listing-info';
import ListingGallery from './listing-gallery';
import ListingInfoShimmerLoader from './info-loader';
import Reviews from './reviews';

const ListingDetails = ({ history }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [listings, setListings] = useState([]);

  const getListings = () => {
    dispatch(getPublicListings({ page: 1, limit: 3 })).then((response) => {
      setListings(response.data);
      setIsLoading(false);
    });
  };

  const getListing = () => {
    const { id } = params;
    dispatch(getListingDetails(id)).then((response) => {
      setDetails(response.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getListing();
    getListings();
  }, []);

  const updateListings = useCallback(() => {
    getListings();
  }, [listings]);

  const updateListingDetails = useCallback(() => {
    getListing();
  }, [details]);

  return (
    <>
      {isLoading
        ? (
          <ListingInfoShimmerLoader />
        )
        : (
          <>
            <ListingInfo data={details} listingId={params.id} updateListingDetails={updateListingDetails} />
            <ListingGallery data={details} />
            <section className="position-relative pt-5">
              <Container>
                <Row>
                  <Col md={8}>
                    <About data={details?.listing_data?.description} />
                    <Location coordinates={details?.coordinates} />
                    <Parking data={details?.listing_data?.parking_information} />
                    <OperatingHours data={details?.availability} />
                    <Reviews listingId={params.id} />
                  </Col>
                  {details?.entities?.length > 0
                  && (
                    <Col md={4}>
                      <BookingRequest history={history} listingId={params.id} entities={details?.entities} />
                    </Col>
                  )}
                </Row>
              </Container>
            </section>
            {listings?.length > 0 && <SimilarListings listings={listings} updateListings={updateListings} />}
          </>
        )}
    </>
  );
};

export default ListingDetails;
