import { DateTime } from 'luxon';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import avatar from '../../../../assets/images/user.png';

const BookingCard = ({ data }) => {
  const { t } = useTranslation();

  const renderBorderColor = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return 'left-blue-border';
      case 'booking-approved':
        return 'left-dark-border';
      default:
        return '';
    }
  };

  const renderStatusColor = (status) => {
    switch (status) {
      case 'waiting-host-approval':
      case 'pending_payment':
        return 'pending';
      case 'booking-approved':
        return 'confirmed';
      case 'booking-rejected':
        return 'declined';
      case 'booking-canceled-by-user':
      case 'booking-canceled-by-host':
      case 'booking-held':
        return 'gray-badge';
      case 'draft':
        return 'draft';
      default:
        return '';
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return t('dashboard.bookings.pending');
      case 'booking-approved':
        return t('dashboard.bookings.confirmed');
      case 'booking-rejected':
        return t('dashboard.bookings.rejected');
      case 'booking-canceled-by-user':
        return t('dashboard.bookings.canceled');
      case 'booking-completed':
        return t('dashboard.bookings.completed');
      default:
        return '';
    }
  };
  return (
    <div className={`booking-wrapper ${renderBorderColor(data?.status)}`}>
      <div className="p-4">
        <Row className="mb-md-4">
          <Col md={6}>
            <h6 className="fw-bold mb-0">
              {data?.listing?.listing_data?.title || 'Untitled'}
            </h6>
          </Col>
          <Col md={6} className="d-flex justify-content-md-end">
            <h6 className="text-space-gray mb-0">
              {DateTime.fromISO(data?.created_at).toFormat('MMMM dd, yyyy')}
              {' '}
              {DateTime.fromISO(data?.created_at).toFormat('HH:00a')}
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr />
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-between">
          <Col md={5} xs={6}>
            <div className="d-flex align-items-center">
              <img className="cursor-pointer" src={data.owner?.profile_image?.original || avatar} alt="User" />
              <p className="mb-0 ms-2 fw-bold">{data.owner?.name}</p>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <h6 className="text-space-gray mb-1">{`${data.number_of_guests} ${t('dashboard.bookings.attendees')}`}</h6>
            <h6 className="text-dark mb-0">{`${data?.duration / 60} ${t('dashboard.bookings.hours')}`}</h6>
          </Col>
          <Col md={4} className="d-flex flex-column align-items-md-end justify-content-md-end mt-md-0 mt-3">
            <h6 className="fw-bold mb-0">{`${localStorage.getItem('currency')} ${data?.total_amount}`}</h6>
            <div className="status d-flex align-items-center">
              <p className={`fw-bold small mb-0 text-left ${renderStatusColor(data.status)}`}>{renderStatus(data?.status)}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BookingCard;
