import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup.object().shape({
  first_name: yup.string().required(() => i18n.t('auth.validations.required')),
  last_name: yup.string().required(() => i18n.t('auth.validations.required')),
  phone_number: yup.string().required(() => i18n.t('auth.validations.required')),

});

export default schema;
