import React from 'react';
import { NavLink, Route } from 'react-router-dom';
import {
  Row,
  Container,
  Col,
  List,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PersonalInfo from './profile-info';
import userIcon from '../../../assets/images/user.svg';
import lock from '../../../assets/images/lock.svg';
import Security from './security';
import Payments from './payments/list';

const ProfileSettings = () => {
  const { t } = useTranslation();

  return (
    <section className="profile-settings pt-5">
      <Container>
        <Row className="">
          <Col md={3}>
            <aside className="sidenavs">
              <Row>
                <Col md={12}>
                  <List type="unstyled" className="navs-list">
                    <li>
                      <NavLink to="/profile/settings" activeClassName="active">
                        <img src={userIcon} alt="user" />
                        <h6 className="mb-0">{t('profile.personal_info')}</h6>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/profile/security" activeClassName="active">
                        <img src={lock} alt="lock" />
                        <h6 className="mb-0">{t('profile.change_password')}</h6>
                      </NavLink>
                    </li>
                    {/* <li>
                      <NavLink to="/profile/payments" activeClassName="active">
                        <img src={payments} alt="payments" />
                        <h6 className="mb-0">{t('profileSettings.sideNavs.payments')}</h6>
                      </NavLink>
                    </li> */}
                  </List>
                </Col>
              </Row>
            </aside>
          </Col>
          <Col md={1} />
          <Col md={8} className="d-flex flex-column">
            <Route path="/profile/settings" component={PersonalInfo} />
            <Route path="/profile/security" component={Security} />
            <Route path="/profile/payments" exact component={Payments} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ProfileSettings;
