import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { deleteCard, getCards, updateCard } from 'leween-react-sdk/redux/actions/cards.actions';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreatePaymentMethod from '../create';
import EmptyPlaceholder from '../../../../../components/empty-placeholder';

const Payments = () => {
  const [isCreateModalOpen, toggleCreateModal] = useState(false);
  const [cards, setCards] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getPaymentMethods = () => {
    dispatch(getCards()).then((response) => setCards(response.data));
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const deletePaymentMethod = (id) => {
    dispatch(deleteCard(id)).then(() => getPaymentMethods());
  };

  const markAsPrimary = (data) => {
    dispatch(updateCard(data.id, { ...data, is_primary: true })).then(() => getPaymentMethods());
  };
  return (
    <>
      <section className="payouts">
        <Row className="mb-4">
          <Col md={11}>
            <h4 className="fw-bold">{t('profileSettings.payments.title')}</h4>
            <p className="mb-0">{t('profileSettings.payments.subtitle')}</p>
          </Col>
        </Row>
        {cards?.length === 0 ? <EmptyPlaceholder text="payment methods" />
          : (
            <>
              {cards?.map((card) => {
                return (
                  <Row className="mb-3" key={card.id}>
                    <Col md={6}>
                      <Row>
                        <Col md={8}>
                          <div className="payment-method d-flex align-items-center">
                            <p className="text-dark mb-0">
                              {card.holder_name}
                              {' '}
                              -
                              {' '}
                              {card.masked_number}
                              {' '}
                              (
                              {card.expiry_date}
                              )
                            </p>
                          </div>
                        </Col>
                        <Col md={4} className="d-flex align-items-md-center justify-content-md-center">
                          {card.is_primary ? <p className="text-dark text-center mb-0">(Primary)</p>
                            : <button type="button" className="btn btn-light" onClick={() => markAsPrimary(card)}>Make Primary</button>}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="d-flex align-items-center justify-content-end">
                      <p className="text-dark mb-0 ms-4 cursor-pointer" onClick={() => deletePaymentMethod(card.id)}>Delete</p>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        <Row className="my-5">
          <Col md={12}>
            <button type="button" className="btn blue-btn" onClick={() => toggleCreateModal(!isCreateModalOpen)}>Add new card</button>
          </Col>
        </Row>
      </section>
      <CreatePaymentMethod isOpen={isCreateModalOpen} toggleModal={toggleCreateModal} callbackUrl={`${window.origin}/profile/payments`} />
    </>
  );
};

export default Payments;
