import React, { useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { ChevronUp, ChevronDown } from 'react-feather';
import { DateTime } from 'luxon';
import { Shimmer } from 'react-shimmer';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button';
import PaymentModal from './payment-modal';
import placeholderImg from '../../../../assets/images/placeholder.png';

const BookingData = ({
  booking, isLoading, listingLocation,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, collapseContent] = useState(true);
  const [isPaymentModalOpen, togglePaymentModal] = useState(false);

  const renderStatusColor = (status) => {
    switch (status) {
      case 'waiting-host-approval':
      case 'pending-payment':
      case 'draft':
        return 'orange-badge';
      case 'booking-approved':
        return 'dark-badge';
      case 'booking-rejected':
        return 'red-badge';
      case 'booking-canceled-by-user':
      case 'booking-canceled-by-host':
      case 'booking-held':
        return 'gray-badge';
      case 'booking-completed':
        return 'white-badge';
      default:
        return '';
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'waiting-host-approval':
        return t('dashboard.bookings.pending_host_approval');
      case 'booking-approved':
        return t('dashboard.bookings.confirmed');
      case 'booking-rejected':
        return t('dashboard.bookings.rejected');
      case 'booking-canceled-by-user':
      case 'booking-canceled-by-host':
        return t('dashboard.bookings.canceled');
      case 'booking-held':
        return 'Booking Held';
      case 'booking-completed':
        return t('dashboard.bookings.completed');
      case 'pending-payment':
        return 'Pending payment';
      case 'draft':
        return t('dashboard.bookings.draft');
      default:
        return null;
    }
  };

  const togglePaymentModalHandler = () => togglePaymentModal(!isPaymentModalOpen);

  const renderCashPaidAddons = () => {
    if (isLoading) {
      return (
        <Row>
          {[1, 2, 3].map((index) => (
            <Col key={index} md={12} className="mb-2">
              <Shimmer className="w-100" height={30} width={100} />
            </Col>
          ))}
        </Row>
      );
    }

    return booking?.calculation?.cashPaid?.map((item) => (
      <Row key={item.label} className="justify-content-between">
        <Col xs="auto">
          <div className="d-flex align-items-center">
            <h6 className="text-space-gray mb-0">{item.label}</h6>
          </div>
        </Col>
        <Col xs="auto">
          <h6 className="text-dark">{item?.amount}</h6>
        </Col>
      </Row>
    ));
  };

  const renderPaymentStatus = () => {
    const paymentDetails = booking?.payment_details;
    return (
      <>
        <div className="mt-3">
          <h6 className="fw-bold text-green">Payment Confirmed</h6>
          <h6>
            {DateTime.fromISO(paymentDetails?.created_at).toFormat('MMMM dd, yyyy . hh:mm a')}
            {' '}
            {paymentDetails?.provider_information?.card?.masked_number}
          </h6>
        </div>
        {booking?.status === 'pending-payment'
        && (
          <div className="mt-3">
            <p className="text-dark">Payment could not processed. Please pay now to confirm your booking.</p>
            <Button className="blue-btn w-100" title="Pay Now" onClick={togglePaymentModalHandler} />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="white-container booking-details">
        {isLoading
          ? (
            <Row>
              <Col md={12} className="mb-2">
                <Shimmer className="w-100" height={300} width={100} />
              </Col>
            </Row>
          )
          : (
            <div className="cover-img">
              <img src={booking.listing?.primary_image?.url || placeholderImg} alt="cover" />
              <div className={`status ${renderStatusColor(booking?.status)}`}>
                <p className="mb-0 text-center">{renderStatus(booking?.status)}</p>
              </div>
              <div className="caption">
                <h5 className="text-white fw-bold">{booking?.title || 'Untitled'}</h5>
                <h6 className="text-white opacity-7 mb-0">{listingLocation}</h6>
              </div>
            </div>
          )}
        <div className="p-4">
          <div className="d-flex align-items-center justify-content-between cursor-pointer" onClick={() => collapseContent(!isCollapsed)}>
            <div className="d-flex justify-content-between align-items-center w-100">
              <h5 className="text-dark fw-bold mb-0">{t('shared.total')}</h5>
              <h5 className="text-dark fw-bold mb-0 me-1">{`${localStorage.getItem('currency')} ${booking?.calculation?.total}`}</h5>
            </div>
            {isCollapsed ? <ChevronUp /> : <ChevronDown />}
          </div>
          <Collapse className="mt-3" isOpen={isCollapsed}>
            <Row>
              <Col md={12}>
                <h6 className="fw-bold">{t('shared.price')}</h6>
              </Col>
            </Row>
            <div
              className="d-flex align-items-center justify-content-between mb-2"
            >
              <h6 className="text-dark mb-0">{t('shared.booking_fees')}</h6>
              <h6 className="text-dark mb-0">
                {localStorage.getItem('currency')}
                {' '}
                {booking?.calculation?.bookingFees}
              </h6>
            </div>
            <div
              className="d-flex align-items-center justify-content-between mb-2"
            >
              <h6 className="text-dark mb-0">{t('shared.processing_fees')}</h6>
              <h6 className="text-dark mb-0">
                {localStorage.getItem('currency')}
                {' '}
                {booking?.calculation?.processingFee}
              </h6>
            </div>
            <div
              className="d-flex align-items-center justify-content-between mb-2"
            >
              <h6 className="text-dark mb-0">{t('steps.activities.discount')}</h6>
              <h6 className="text-dark mb-0">
                {localStorage.getItem('currency')}
                {' '}
                {booking?.calculation?.discount}
              </h6>
            </div>
            <div
              className="d-flex align-items-center justify-content-between mb-2"
            >
              <h6 className="text-dark mb-0">{t('shared.vat')}</h6>
              <h6 className="text-dark mb-0">
                {localStorage.getItem('currency')}
                {' '}
                {booking?.calculation?.VAT}
              </h6>
            </div>
            {booking?.calculation?.cashPaid?.length > 0
            && (
              <>
                <Row className="mt-3">
                  <Col md={12}>
                    <h6 className="fw-bold">Cash Paid Add-ons</h6>
                  </Col>
                </Row>
                {renderCashPaidAddons()}
              </>
            )}
          </Collapse>
          {booking.status === 'waiting-host-approval' && renderPaymentStatus()}
        </div>
      </div>
      <PaymentModal isOpen={isPaymentModalOpen} toggleModal={togglePaymentModalHandler} />
    </>
  );
};

export default BookingData;
