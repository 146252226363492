import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getPublicListings } from 'leween-react-sdk/redux/actions/public-listings.actions';
import Filters from './filters';
import Listings from './listings';

const ListingsSearch = () => {
  const params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();

  const [isMapOn, toggleMap] = useState(true);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [meta, setMeta] = useState({
    current_page: 1,
    last_page: 1,
    per_page: 1,
    total: 1,
  });

  const loadListings = (urlParams) => {
    setLoading(true);
    dispatch(getPublicListings(urlParams))
      .then((response) => {
        if (response.meta.current_page === 1) {
          setListings(response.data);
        } else {
          setListings([...listings, ...response.data]);
        }
        setMeta(response.meta);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const updateURLParams = useCallback((updatedParams) => {
    for (const [key, value] of Object.entries(updatedParams)) {
      params.delete(key);
      params.append(key, value);
    }
    params.sort();
    const locationSearch = `?${params.toString()}`;
    window.history.replaceState({}, '', `/listings${locationSearch}`);

    loadListings(params);
  }, [params]);

  const resetParams = useCallback(() => {
    const newParams = new URLSearchParams();
    newParams.sort();
    const locationSearch = `?${newParams.toString()}`;
    window.history.replaceState({}, '', `/listings${locationSearch}`);

    loadListings(newParams);
  }, []);

  useEffect(() => {
    loadListings(params);
  }, []);

  const loadNextPage = useCallback(() => {
    const currentPage = +params.get('page') || 1;
    updateURLParams({ page: currentPage + 1 });
  }, [params]);

  const updateListings = useCallback(() => {
    loadListings(params);
  }, [listings]);

  const toggleListingCard = (id) => setListings(listings.map((listing) => ({ ...listing, isOpen: listing.id === id })));

  return (
    <>
      <Filters
        isMapOn={isMapOn}
        params={params}
        toggleMap={toggleMap}
        updateURLParams={updateURLParams}
        resetParams={resetParams}
      />
      <Listings
        isMapOn={isMapOn}
        listings={listings}
        loading={loading}
        meta={meta}
        updateURLParams={updateURLParams}
        loadNextPage={loadNextPage}
        toggleListingCard={toggleListingCard}
        updateListings={updateListings}
      />
    </>
  );
};

export default ListingsSearch;
