import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Shimmer } from 'react-shimmer';

const ListingInfoShimmerLoader = () => {
  return (
    <section className="p-5">
      <Container>
        <Row>
          <Col>
            <Shimmer className="w-30" height={40} width={100} />
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={2}>
            <Shimmer className="w-100" height={30} width={100} />
          </Col>
          <Col md={2}>
            <Shimmer className="w-100" height={30} width={100} />
          </Col>
          <Col md={2}>
            <Shimmer className="w-100" height={30} width={100} />
          </Col>
          <Col md={2} />
          <Col md={2}>
            <Shimmer className="w-100" height={30} width={100} />
          </Col>
          <Col md={2}>
            <Shimmer className="w-100" height={30} width={100} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Shimmer className="w-100 h-100" width={100} height={30} />
          </Col>
          <Col>
            <Row>
              <Col md={6} className="mb-3">
                <Shimmer className="w-100" height={150} width={100} />
              </Col>
              <Col md={6}>
                <Shimmer className="w-100" height={150} width={100} />
              </Col>
              <Col md={6}>
                <Shimmer className="w-100" height={150} width={100} />
              </Col>
              <Col md={6}>
                <Shimmer className="w-100" height={150} width={100} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="my-5">
          <Col md="auto">
            <Shimmer width={70} height={70} className="rounded-circle" />
          </Col>
          <Col>
            <Shimmer className="w-30 mb-2" height={25} width={100} />
            <Shimmer className="w-50" height={25} width={100} />
          </Col>
          <Col md={12} className="mt-2">
            <Shimmer className="w-90 mb-2" height={25} width={100} />
            <Shimmer className="w-50 mb-2" height={25} width={100} />
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <Shimmer className="w-30 mb-2" height={40} width={100} />
          </Col>
          <Col md={12} className="mt-2">
            <Shimmer className="w-90 mb-2" height={25} width={100} />
            <Shimmer className="w-90 mb-2" height={25} width={100} />
            <Shimmer className="w-50 mb-2" height={25} width={100} />
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <Shimmer className="w-30 mb-2" height={40} width={100} />
          </Col>
          <Col md={12} className="mt-2">
            <Shimmer className="w-70 mb-2" height={350} width={100} />
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <Shimmer className="w-30 mb-2" height={40} width={100} />
          </Col>
          <Col md={12} className="mt-2">
            <Shimmer className="w-90 mb-2" height={25} width={100} />
            <Shimmer className="w-90 mb-2" height={25} width={100} />
            <Shimmer className="w-50 mb-2" height={25} width={100} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ListingInfoShimmerLoader;
