import React from 'react';
import { Row, Col } from 'reactstrap';

const Statistics = ({ data }) => {
  return (
    <>
      <Row>
        <Col md={3}>
          <div className="statistics-box white d-flex flex-column align-items-center justify-content-center">
            <p className="fw-bold mb-1 h2">
              {localStorage.getItem('currency')}
              {' '}
              {data?.balance || 0}
            </p>
            <p className="mb-0 h6">Balance</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <hr className="silver-hr mt-0 mb-4" />
        </Col>
      </Row>
    </>
  );
};

export default Statistics;
