import React, { useState } from 'react';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { login } from 'leween-react-sdk/redux/actions/auth.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUser } from 'leween-react-sdk/redux/actions/user.actions';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import Button from '../../../components/button';
import schema from './schema';
import InputField from '../../../components/input';
import { setServerErrors } from '../../../helpers';
import AccountRetrieve from '../forget-password/account-retrieve';

const Login = ({
  isModalOpen, toggleModal, openSignUpModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isForgetPasswordModalOpen, toggleForgetPasswordModal] = useState(false);

  const toggleForgetPasswordModalHandler = () => {
    toggleForgetPasswordModal(!isForgetPasswordModalOpen);
    toggleModal();
  };

  const {
    reset, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const { userAgent } = window.navigator;
    const newData = { ...data, device_name: userAgent, method: 'email' };
    dispatch(login(newData)).then(() => {
      dispatch(getUser()).then((response) => {
        localStorage.setItem('role', response.data.role);
      });
      toggleModal();
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };
  return (
    <>
      <Modal onShow={reset} className="light-modal" show={isModalOpen} onHide={() => toggleModal()} centered>
        <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
        <Modal.Body className="px-5">
          <Container className="py-4">
            <Row>
              <Col md={12} className="d-flex flex-column align-items-center justify-content-center mb-4 mt-2">
                <h4 className="text-dark fw-bold mb-2">{t('login.title')}</h4>
                <p className="text-dark mb-0">{t('login.subtitle')}</p>
              </Col>
            </Row>
            <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
                <InputField
                  label={t('shared.email')}
                  name="email"
                  errors={errors}
                  type="email"
                  {...register('email')}
                />
                <InputField
                  label={t('shared.password')}
                  name="password"
                  errors={errors}
                  type="password"
                  {...register('password')}
                  wrapperClassName="ltr-input"
                />
                <Button className="yellow-btn w-100" title={t('shared.login')} />
              </Col>
            </Form>
            <Col md={12} className="d-flex align-items-center justify-content-center mb-4">
              <p className="text-dark text-underline cursor-pointer mb-0" onClick={toggleForgetPasswordModalHandler}>{t('login.forgot_password')}</p>
            </Col>
            <Col md={12} className="d-flex align-items-center justify-content-center mb-2">
              <p className="text-dark mb-0">
                {t('login.register')}
                <span className="text-yellow fw-bold ms-1 cursor-pointer" onClick={openSignUpModal}>{t('shared.register')}</span>
              </p>
            </Col>
          </Container>
        </Modal.Body>
      </Modal>
      <AccountRetrieve isModalOpen={isForgetPasswordModalOpen} toggleModal={toggleForgetPasswordModalHandler} />
    </>
  );
};

export default Login;
