import * as yup from 'yup';
import i18n from 'i18next';

function dayValidator() {
  return yup.object({
    times: yup.array().when('allDay', {
      is: false,
      then: yup.array().of(yup.object().shape({
        from: yup.string().required(() => i18n.t('host.validations.required')),
        to: yup.string()
          .required(() => i18n.t('host.validations.required'))
          .test('isGreaterThanFromHour', () => i18n.t('host.validations.endTime'), (to, context) => {
            const { from } = context.parent;
            if (!from || !to) return true;
            return to === '00:00' || to > from;
          }),
      })),
    }),
  });
}

const schema = yup.object().shape({
  sunday: dayValidator(),
  monday: dayValidator(),
  tuesday: dayValidator(),
  wednesday: dayValidator(),
  thursday: dayValidator(),
  friday: dayValidator(),
  saturday: dayValidator(),
});

export default schema;
