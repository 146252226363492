import { React } from 'react';
import {
  Container,
  Row,
  Col,
  List,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  NavLink,
  Route,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Listings from './listings/list';
import Calendar from './calendar/bookings';
import OperatingHours from './calendar/operating-hours';
import Reporting from './reporting';
import BookingsList from './bookings/list';
import avatar from '../../assets/images/user.png';
import mailbox from '../../assets/images/mailbox.svg';
import key from '../../assets/images/key.svg';
import calendar from '../../assets/images/calendar.svg';
import reports from '../../assets/images/reports.svg';
import payouts from '../../assets/images/payout.svg';
import Payouts from './payouts/list';
import CreatePayout from './payouts/create';
import UpdatePayout from './payouts/edit';

const DashboardNavs = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.data);

  return (
    <section className="host-dashboard py-5">
      <Container>
        <Row>
          <Col md={3}>
            <aside className="sidenavs rounded border shadow-sm px-4 py-5">
              <Row>
                <Col md={12}>
                  <Row className="d-flex align-items-center g-0">
                    <Col md={4} xs={4}>
                      {user?.profile_image?.original
                        ? <img src={user.profile_image.original} alt="User" className="avatar-img" />
                        : <img src={avatar} alt="Avatar" className="avatar-img" />}
                    </Col>
                    <Col md={8} xs={8}>
                      <h5 className="text-dark fw-bold mb-0 ms-2">{`${t('dashboard.welcome')} ${user?.first_name || ''}!`}</h5>
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <List type="unstyled" className="navs-list">
                    <li>
                      <NavLink to="/host/dashboard/listings/all" activeClassName="active">
                        <img src={key} alt="key" />
                        <h6 className="mb-0">{t('dashboard.ads.title')}</h6>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/host/dashboard/inbox/upcoming" activeClassName="active">
                        <img src={mailbox} alt="mailbox" />
                        <h6 className="mb-0">{t('dashboard.bookings.title')}</h6>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/host/dashboard/calendar" activeClassName="active">
                        <img src={calendar} alt="calendar" />
                        <h6 className="mb-0">{t('dashboard.calendar.title')}</h6>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/host/dashboard/reporting" activeClassName="active">
                        <img src={reports} alt="reports" />
                        <h6 className="mb-0">{t('dashboard.reporting.title')}</h6>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/host/dashboard/payouts" activeClassName="active">
                        <img src={payouts} alt="payouts" />
                        <h6 className="mb-0 me-3">{t('dashboard.payouts.title')}</h6>
                      </NavLink>
                    </li>
                  </List>
                </Col>
              </Row>
            </aside>
          </Col>
          <Col md={9}>
            <Route path="/host/dashboard/listings/:status" component={Listings} />
            <Route path="/host/dashboard/inbox/:status" component={BookingsList} />
            <Route path="/host/dashboard/calendar" component={Calendar} />
            <Route path="/host/dashboard/operating-hours/:listingId" component={OperatingHours} />
            <Route path="/host/dashboard/reporting" component={Reporting} />
            <Route path="/host/dashboard/payouts" exact component={Payouts} />
            <Route path="/host/dashboard/payouts/create" exact component={CreatePayout} />
            <Route path="/host/dashboard/payouts/edit/:id" exact component={UpdatePayout} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DashboardNavs;
