import React from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { resetPassword } from 'leween-react-sdk/redux/actions/auth.actions';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import schema from './schema';
import InputField from '../../../../components/input';
import Button from '../../../../components/button';

const ResetPassword = ({ isModalOpen, toggleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    reset, register, handleSubmit, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const newData = {
      ...data,
      token: localStorage.getItem('reset_token'),
      phone_number: localStorage.getItem('phone_number'),
    };
    dispatch(resetPassword(newData)).then(() => {
      localStorage.removeItem('reset_token');
      localStorage.removeItem('phone_number');
      toggleModal();
    });
  };
  return (
    <Modal onShow={reset} className="light-modal" show={isModalOpen} onHide={() => toggleModal()} centered>
      <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
      <Modal.Body className="px-5">
        <Container className="py-4">
          <Row>
            <Col md={12} className="d-flex flex-column align-items-center justify-content-center mb-4 mt-2">
              <h4 className="text-dark fw-bold mb-2">{t('reset_password.title')}</h4>
              <p className="text-dark mb-0">
                {t('reset_password.subtitle')}
              </p>
            </Col>
            <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
                <InputField
                  name="password"
                  errors={errors}
                  type="password"
                  label={t('shared.new_password')}
                  {...register('password')}
                />
                <InputField
                  name="password_confirmation"
                  errors={errors}
                  type="password"
                  label={t('shared.confirm_password')}
                  {...register('password_confirmation')}
                />
                <Button className="yellow-btn w-100" title={t('reset_password.button')} />
              </Col>
            </Form>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPassword;
