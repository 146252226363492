import React from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { cancelBooking } from 'leween-react-sdk/redux/actions/booking.actions';
import redXIcon from '../../../../../assets/images/red-x.svg';
import InputField from '../../../../../components/input';
import Button from '../../../../../components/button/index';
import schema from './schema';

const CancelBooking = ({ isOpen, toggle, getBooking }) => {
  const dispatch = useDispatch();
  const { bookingId } = useParams();

  const {
    register, formState: { errors }, handleSubmit, reset, setError,
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    dispatch(cancelBooking(bookingId, data)).then(() => {
      getBooking();
      toggle();
    }).catch((error) => {
      const message = error?.response?.data?.message || '';
      setError('reason', { message, type: 'manual' });
    });
  };

  return (
    <Modal onOpened={reset} isOpen={isOpen} toggle={toggle} centered className="cancel-modal">
      <X className="close-icon cursor-pointer" onClick={toggle} size={18} />
      <ModalBody className="p-md-5">
        <Row>
          <Col md={12}>
            <div className="d-flex justify-content-center">
              <img src={redXIcon} alt="icon" className="icon" />
            </div>
            <h5 className="text-center fw-bold my-4">Are you sure you want to cancel?</h5>
          </Col>
        </Row>
        <Row className="justify-content-between w-75 m-auto">
          <Col xs="auto">
            <h6>Apr 14, 2020</h6>
          </Col>
          <Col xs="auto">
            <h6>
              9:00am
              <span className="px-md-4 px-2">-</span>
              7:00pm
            </h6>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mt-3">
            <Col md={12}>
              <InputField
                name="reason"
                as="textarea"
                placeholder="Please tell us why you want to cancel"
                rows="5"
                errors={errors}
                {...register('reason')}
              />
              <p className="text-space-gray mb-4">
                All Bookings are subject to LeWeen Grace Period policy which provides a full refund for
                Booking cancelled within 24 hours from receipt of a Booking Confirmation but no later than
                48 hours prior to the Event start time.
              </p>
              <h6 className="fw-bold mb-1">Host cancellation policy (Flexible)</h6>
              <p>
                Host cancellation policy (Flexible)
                Guests may cancel their Booking until 7 days before the event
                start time and will receive a full refund (include all Fees) of their
                Booking Price. Guests may cancel their Booking between 7 days
                and 24 hours before the event start time and receive a 50%
                refund (excluding Fees) of their Booking Price. Booking
                cancellations submitted less than 24 hours before the Event start
                time are not refundable.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-between">
              <button type="button" className="light-btn w-50 me-2" onClick={toggle}>Back</button>
              <Button className="red-btn w-50" title="Cancel Booking" />
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CancelBooking;
