import React, { useState } from 'react';
import {
  Row, Container, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import CancellationPolicy from './tabs/cancellation-policy';
import TermsOfService from './tabs/terms-of-service';

const Terms = () => {
  const [activeTab, setActiveTab] = useState('terms-of-service');
  return (
    <section className="my-5">
      <Container>
        <Row>
          <Col md={12}>
            <Nav className="underlined-tabs">
              <NavItem>
                <NavLink
                  active={activeTab === 'terms-of-service'}
                  onClick={() => setActiveTab('terms-of-service')}
                >
                  Terms of Service
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'fees-overview'}
                  onClick={() => setActiveTab('terms-of-service')}
                >
                  Fees Overview
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'community-guidelines'}
                  onClick={() => setActiveTab('terms-of-service')}
                >
                  Community Guidelines
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'cancellation-policy'}
                  onClick={() => setActiveTab('cancellation-policy')}
                >
                  Cancellation Policy
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'host-referral-terms'}
                  onClick={() => setActiveTab('terms-of-service')}
                >
                  Host Referral Terms
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="terms-of-service">
            <TermsOfService />
          </TabPane>
          <TabPane tabId="cancellation-policy">
            <CancellationPolicy />
          </TabPane>
        </TabContent>
      </Container>
    </section>
  );
};

export default Terms;
