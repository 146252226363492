import React, { useState } from 'react';
import { Marker, GoogleMap } from '@react-google-maps/api';
import { Col, Collapse, Row } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { mapContainerStyle } from '../../../helpers';
import defaultMarker from '../../../assets/images/default-marker.png';

const Location = ({ coordinates }) => {
  const [isCollapsed, collapseContent] = useState(true);
  const { t } = useTranslation();

  return (
    <Row>
      <Col md={12}>
        <div className="border-bottom pt-2 pb-4">
          <div className="d-flex align-items-center justify-content-between cursor-pointer" onClick={() => collapseContent(!isCollapsed)}>
            <h5 className="text-dark fw-bold mb-0">{t('listing_details.location')}</h5>
            {isCollapsed ? <ChevronUp /> : <ChevronDown />}
          </div>
          <Collapse className="mt-3" isOpen={isCollapsed}>
            <Row>
              {coordinates
              && (
                <Col md={12}>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={{ lat: parseFloat(coordinates?.split(',')[0]), lng: parseFloat(coordinates?.split(',')[1]) }}
                    zoom={15}
                    options={{
                      gestureHandling: 'greedy',
                      fullscreenControl: false,
                      streetViewControl: false,
                      mapTypeControl: false,
                    }}
                  >
                    <Marker
                      icon={{
                        url: defaultMarker,
                        scaledSize: new window.google.maps.Size(50, 50),
                      }}
                      key={`${coordinates.lat}-${coordinates.lng}`}
                      position={{ lat: parseFloat(coordinates?.split(',')[0]), lng: parseFloat(coordinates?.split(',')[1]) }}
                      draggable={false}
                    />
                  </GoogleMap>
                </Col>
              )}
            </Row>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default Location;
