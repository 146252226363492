import React from 'react';
import { Row, Col } from 'reactstrap';

import emptyImg from '../../assets/images/empty-placeholder.svg';

const EmptyPlaceholder = ({ text }) => {
  return (
    <section className="pt-5">
      <Row>
        <Col md={12} className="d-flex flex-column align-items-center justify-content-center">
          <img src={emptyImg} alt="empty" height={200} />
          <h5 className="text-center mt-4 fw-bold text-space-gray">{text || ''}</h5>
        </Col>
      </Row>
    </section>
  );
};

export default EmptyPlaceholder;
