import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getHostListingDetails, updateListing, updateListingAvailability } from 'leween-react-sdk/redux/actions/host/listings.actions';
import {
  Col, Row, FormGroup, Input, Label,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { X } from 'react-feather';
import { Link, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import schema from './schema';
import TimePicker from '../../../../components/time-picker';

const OperatingHours = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { listingId } = useParams();
  const [days, setDays] = useState({});
  const [listing, setListing] = useState({});
  const [errors, setErrors] = useState({});

  const getListingDetails = () => {
    dispatch(getHostListingDetails(listingId)).then((response) => {
      setListing(response.data);
    });
  };

  useEffect(() => {
    if (!listingId) {
      setListing({});
    } else {
      getListingDetails();
    }
  }, []);

  useEffect(() => {
    if (listing.availability) {
      const parsedAvailability = {};
      const keys = Object.keys(listing.availability);
      for (const key of keys) {
        const parsedData = JSON.parse(listing.availability[key]);
        if (typeof parsedData?.times === 'string') {
          parsedData.times = JSON.parse(parsedData?.times);
        }
        parsedAvailability[key] = parsedData;
      }
      setDays(parsedAvailability);
    }
  }, [listing?.availability]);

  const handleAvailability = (e, day) => {
    const value = e.target.checked;
    setDays({
      ...days,
      [day]: {
        ...days[day],
        status: value,
        allDay: true,
        times: [],
      },
    });
  };

  const handleAllDay = (e, day) => {
    const value = e.target.value === 'allDay';
    setDays({
      ...days,
      [day]: {
        ...days[day],
        allDay: value,
        times: value ? [] : [{ from: '', to: '' }],
      },
    });
  };

  const handleTimes = (value, day, index, type) => {
    const newDays = {
      ...days,
      [day]: {
        ...days[day],
        times: [...days[day].times],
      },
    };
    const slot = { ...days[day].times[index] };
    slot[type] = value;
    newDays[day].times[index] = slot;
    setDays(newDays);
  };

  const addHours = (day) => {
    const { times } = days[day];
    const clonedTimes = [...times];
    clonedTimes.push({ from: '', to: '' });
    const newDays = {
      ...days,
      [day]: {
        ...days[day],
        times: clonedTimes,
      },
    };
    setDays(newDays);
  };

  const removeHours = (day, index) => {
    const { times } = days[day];
    const clonedTimes = [...times];
    const fieldIndex = clonedTimes.indexOf(index);
    clonedTimes.splice(fieldIndex, 1);
    const newDays = {
      ...days,
      [day]: {
        ...days[day],
        times: clonedTimes,
      },
    };
    setDays(newDays);
  };

  const updateAvailability = async () => {
    try {
      setErrors([]);
      await schema.validate(days, { abortEarly: false });
      dispatch(updateListingAvailability(listing.id, { step: 5, availability: days }))
        .then(async (response) => {
          toast.success('Operating hours updated succesfully');
          await dispatch(updateListing(response.data.id, { step: 5 })).then(() => {
            getListingDetails();
          });
        });
    } catch (e) {
      const errors = e?.inner?.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error?.message,
        };
      }, {});
      setErrors(errors);
    }
  };

  return (
    <form>
      <section>
        <Row className="mb-4 d-flex align-items-center">
          <Col md={11}>
            <h4 className="fw-bold text-dark-gray">{t('steps.availability.title')}</h4>
            <p className="text-dark">{t('steps.availability.subtitle')}</p>
          </Col>
        </Row>
        {Object.keys(days)?.map((day) => {
          const data = days[day];
          const statusLabel = data?.status ? t('shared.available') : t('shared.not_available');
          return (
            <Row key={day}>
              <Col md={12}>
                <Row className="d-flex align-items-center">
                  <Col md={2}>
                    <h6 className="fw-bold text-capitalize text-dark mb-0">{day}</h6>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="form-switch" check>
                      <Input
                        type="checkbox"
                        checked={data.status}
                        value={data.status}
                        name={`${day}.status`}
                        onChange={(e) => handleAvailability(e, day)}
                      />
                      <Label className="mb-0" check>
                        {statusLabel}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup check>
                      <Input
                        type="radio"
                        id="allDay"
                        name={`${day}.allDay`}
                        checked={data.allDay}
                        value="allDay"
                        onChange={(e) => handleAllDay(e, day)}
                        disabled={!data.status}
                      />
                      <Label className="mb-0" check>
                        {t('shared.all_day')}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup check>
                      <Input
                        type="radio"
                        id="allDay"
                        name={`${day}.allDay`}
                        checked={!data.allDay}
                        value="setHours"
                        onChange={(e) => handleAllDay(e, day)}
                        disabled={!data.status}
                      />
                      <Label className="mb-0" check>
                        {t('shared.set_hours')}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                {!data.allDay
                && (
                  <>
                    {data.times?.map((time, index) => {
                      return (
                        <>
                          <Row className="mt-3">
                            <Col md={2}>
                              <TimePicker
                                name={`${day}.times[${index}].from`}
                                value={time.from}
                                onChange={(value) => handleTimes(value, day, index, 'from')}
                                errors={errors}
                              />
                            </Col>
                            <Col md="auto" className="d-flex align-items-center">
                              <p className="text-dark mb-0">to</p>
                            </Col>
                            <Col md={2}>
                              <TimePicker
                                name={`${day}.times[${index}].to`}
                                value={time.to}
                                onChange={(value) => handleTimes(value, day, index, 'to')}
                                className="form-control"
                                errors={errors}
                              />
                            </Col>
                            {index !== 0
                          && (
                            <Col md={1} className="d-flex align-items-center">
                              <X size={18} className="cursor-pointer text-blue" onClick={() => removeHours(day, index)} />
                            </Col>
                          )}
                          </Row>
                        </>
                      );
                    })}
                    <Row>
                      <Col md={12}>
                        <h6 className="text-blue text-underline cursor-pointer mt-2 mb-0" onClick={() => addHours(day)}>{t('shared.add_hours')}</h6>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col md={12}><hr className="silver-hr" /></Col>
                </Row>
              </Col>
            </Row>
          );
        })}
        <Row className="mt-4">
          <Col md={12} className="d-flex justify-content-start">
            <Link to="/host/dashboard/calendar" className="btn light-btn px-4 me-2">{t('shared.cancel')}</Link>
            <button type="button" className="btn blue-btn px-4" onClick={updateAvailability}>{t('shared.update')}</button>
          </Col>
        </Row>
      </section>
    </form>

  );
};

export default OperatingHours;
