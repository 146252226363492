import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateListing, updateListingAvailability } from 'leween-react-sdk/redux/actions/host/listings.actions';
import {
  Col, Row, Button as ButtonLink, FormGroup, Input, Label,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import schema from './schema';
import TimePicker from '../../../../../components/time-picker';

const Availability = ({ onUpdateListing, stepper, listing }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [days, setDays] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (listing.availability) {
      const parsedAvailability = {};
      const keys = Object.keys(listing.availability);
      for (const key of keys) {
        const parsedData = listing.availability[key];
        // if (typeof parsedData?.times === 'string') {
        //   parsedData.times = JSON.parse(parsedData?.times);
        // }
        parsedAvailability[key] = parsedData;
      }
      setDays(parsedAvailability);
    }
  }, [listing?.availability]);

  const handleAvailability = (e, day) => {
    const value = e.target.checked;
    setDays({
      ...days,
      [day]: {
        ...days[day],
        status: value,
        allDay: true,
        times: [],
      },
    });
  };

  const handleAllDay = (e, day) => {
    const value = e.target.value === 'allDay';
    setDays({
      ...days,
      [day]: {
        ...days[day],
        allDay: value,
        times: value ? [] : [{ from: '', to: '' }],
      },
    });
  };

  const handleTimes = (value, day, index, type) => {
    const newDays = {
      ...days,
      [day]: {
        ...days[day],
        times: [...days[day].times],
      },
    };
    const slot = { ...days[day].times[index] };
    slot[type] = value;
    newDays[day].times[index] = slot;
    setDays(newDays);
  };

  const updateAvailability = async () => {
    try {
      setErrors([]);
      await schema.validate(days, { abortEarly: false });
      dispatch(updateListingAvailability({ id: listing.id, type: 'listing', availability: days }))
        .then(async () => {
          await dispatch(updateListing(listing.id, { step: 3 })).then(() => {
            onUpdateListing(true);
          });
        });
    } catch (e) {
      const errors = e?.inner?.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error?.message,
        };
      }, {});
      setErrors(errors);
    }
  };
  return (
    <form>
      <section>
        <Row className="mb-4 d-flex align-items-center">
          <Col md={8}>
            <h4 className="fw-bold text-dark">{t('steps.availability.title')}</h4>
            <p className="text-dark-gray">{t('steps.availability.subtitle')}</p>
          </Col>
        </Row>
        {Object.keys(days)?.map((day) => {
          const data = days[day];
          const statusLabel = data?.status ? t('shared.available') : t('shared.not_available');
          return (
            <Row key={day}>
              <Col md={9}>
                <Row className="d-flex align-items-center">
                  <Col md={2}>
                    <h6 className="fw-bold text-capitalize text-dark mb-0">{t(`steps.availability.${day.toLowerCase()}`)}</h6>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="form-switch" check>
                      <Input
                        type="checkbox"
                        checked={data.status}
                        value={data.status}
                        name={`${day}.status`}
                        onChange={(e) => handleAvailability(e, day)}
                      />
                      <Label className="mb-0" check>
                        {statusLabel}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup check>
                      <Input
                        type="radio"
                        id="allDay"
                        name={`${day}.allDay`}
                        checked={data.allDay}
                        value="allDay"
                        onChange={(e) => handleAllDay(e, day)}
                        disabled={!data.status}
                      />
                      <Label className="mb-0" check>
                        {t('shared.all_day')}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup check>
                      <Input
                        type="radio"
                        id="allDay"
                        name={`${day}.allDay`}
                        checked={!data.allDay}
                        value="setHours"
                        onChange={(e) => handleAllDay(e, day)}
                        disabled={!data.status}
                      />
                      <Label className="mb-0" check>
                        {t('shared.set_hours')}
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                {!data.allDay
                && (
                  <>
                    {data.times?.map((time, index) => {
                      return (
                        <>
                          <Row className="mt-3">
                            <Col md={2}>
                              <TimePicker
                                name={`${day}.times[${index}].from`}
                                value={time.from}
                                onChange={(value) => handleTimes(value, day, index, 'from')}
                                errors={errors}
                              />
                            </Col>
                            <Col md="auto" className="d-flex align-items-center">
                              <p className="text-dark mb-0">to</p>
                            </Col>
                            <Col md={2}>
                              <TimePicker
                                name={`${day}.times[${index}].to`}
                                value={time.to}
                                onChange={(value) => handleTimes(value, day, index, 'to')}
                                className="form-control"
                                errors={errors}
                              />
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </>
                )}
                <Row>
                  <Col md={12}><hr className="silver-hr" /></Col>
                </Row>
              </Col>
            </Row>
          );
        })}
      </section>
      <section className="bs-footer">
        <Row>
          <Col md={12} className="d-flex justify-content-end">
            <ButtonLink className="light-btn br-0 px-4 me-2" onClick={() => stepper.previous()}>{t('shared.previous')}</ButtonLink>
            <button type="button" className="btn blue-btn br-0 px-4" onClick={updateAvailability}>{t('shared.next')}</button>
          </Col>
        </Row>
      </section>
    </form>

  );
};

export default Availability;
