import { React, useState } from 'react';
import {
  Container, Row, Col, List, ListInlineItem, Label,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { uploadMedia, contactUs } from 'leween-react-sdk/redux/actions/contact-us.actions';
import Dropzone from 'react-dropzone';
import { Trash2 } from 'react-feather';
import toast from 'react-hot-toast';
import InputField from '../../components/input';
import Button from '../../components/button';
import schema from './schema';
import whatsapp from '../../assets/images/whatsapp.png';
import facebook from '../../assets/images/facebook.png';
import snapchat from '../../assets/images/snapchat.png';
import youtube from '../../assets/images/youtube.png';
import twitter from '../../assets/images/twitter.png';
import instagram from '../../assets/images/instagram.png';
import { requestTypes, setServerErrors } from '../../helpers';

const ContactUs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register, handleSubmit, formState: { errors }, setError, reset,
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });
  const [photos, setPhotos] = useState([]);

  const onSubmit = (data) => {
    dispatch(contactUs({
      ...data,
      request_type: data.request_type.toLowerCase(),
      media_ids: photos.map((photo) => photo.id),
    }))
      .then(() => {
        reset();
        setPhotos([]);
        toast.success('Message sent successfully, thank you!');
      }).catch((error) => {
        const responseErrors = error.response.data.errors;
        setServerErrors(responseErrors, setError);
      });
  };

  const deleteImage = (item) => {
    setPhotos(photos?.filter((photo) => photo.id !== item.id));
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    dispatch(uploadMedia(formData)).then((response) => {
      setPhotos([...photos, { ...file, id: response.id }]);
    });
  };
  return (
    <section className="contact-section">
      <Container>
        <Row className="mb-5">
          <Col md="12" className="text-center">
            <h1 className="text-dark fw-bold">{t('contactUs.title')}</h1>
            <p>{t('contactUs.subtitle')}</p>
          </Col>
        </Row>
        <div className="box-wrapper shadow">
          <Row>
            <Col md={7}>
              <form className="row" onSubmit={handleSubmit(onSubmit)}>
                <Col md={12}>
                  <Label className="fw-bold mb-1">
                    {t('shared.email')}
                    {' '}
                    <strong className="text-red">*</strong>
                  </Label>
                  <InputField
                    errors={errors}
                    {...register('email')}
                    name="email"
                    type="email"
                  />
                  <Label className="fw-bold mb-1">
                    {t('shared.subject')}
                    {' '}
                    <strong className="text-red">*</strong>
                  </Label>
                  <InputField
                    errors={errors}
                    {...register('subject')}
                    name="subject"
                    type="text"
                  />
                  <Label className="fw-bold mb-1">
                    {t('shared.description')}
                    {' '}
                    <strong className="text-red">*</strong>
                  </Label>
                  <InputField
                    errors={errors}
                    {...register('description')}
                    name="description"
                    as="textarea"
                    rows={5}
                    wrapperClassName="mb-1"
                  />
                  <p className="text-space-gray mb-4">{t('contactUs.descriptionNote')}</p>
                  <Label className="fw-bold mb-1">
                    {t('contactUs.requestType')}
                  </Label>
                  <InputField
                    errors={errors}
                    {...register('request_type')}
                    name="request_type"
                    type="select"
                    defaultValue=""
                  >
                    <option value="" disabled>Please select...</option>
                    {requestTypes.map((item) => (<option>{item.name}</option>))}
                  </InputField>
                  <Label className="fw-bold mb-1">
                    {t('shared.attachments')}
                  </Label>
                  <Dropzone onDrop={(acceptedFiles) => uploadFile(acceptedFiles[0])}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: 'dropzone-file-uploader' })}>
                        <input {...getInputProps()} />
                        <div className="blue-container mb-0">
                          <h6 className="text-dark-gray mb-0">
                            <span className="text-blue">Add file</span>
                            {' '}
                            or drop files here
                          </h6>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {photos?.map((file) => (
                    <div className="uploaded-file shadow-sm cursor-pointer mt-3" key={file?.id} onClick={() => deleteImage(file)}>
                      <p className="text-dark-gray text-break mb-0">{file.path}</p>
                      <Trash2 size={20} className="text-red" />
                    </div>
                  ))}
                  <Button className="black-btn w-100 mt-3" title={t('shared.send')} />
                </Col>
              </form>
            </Col>
            <Col md={1} className="d-flex justify-content-center">
              <div className="divider d-md-block d-sm-none" />
            </Col>
            <Col md={4}>
              <Row className="mb-4">
                <Col md={12}>
                  <h6 className="text-dark fw-bold mb-1">{t('shared.address')}</h6>
                  <p className="text-dark-gray font-weight-regular mb-0"> 1935 Walgrove Ave</p>
                  <p className="text-dark-gray font-weight-regular mb-0"> Los Angeles, CA 90066</p>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={12}>
                  <h6 className="text-dark fw-bold mb-1">{t('shared.email')}</h6>
                  <a className="text-blue" href="mailto:contact@LeWeen.com">contact@LeWeen.com</a>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <h6 className="text-dark fw-bold mb-2">{t('contactUs.socialNetworks')}</h6>
                  <List type="inline" className="social-list p-0">
                    <ListInlineItem><img src={whatsapp} alt="Whatsapp" /></ListInlineItem>
                    <ListInlineItem><img src={twitter} alt="Twitter" /></ListInlineItem>
                    <ListInlineItem><img src={facebook} alt="Facebook" /></ListInlineItem>
                    <ListInlineItem><img src={instagram} alt="Instagram" /></ListInlineItem>
                    <ListInlineItem><img src={snapchat} alt="Snapchat" /></ListInlineItem>
                    <ListInlineItem><img src={youtube} alt="Youtube" /></ListInlineItem>
                  </List>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;
