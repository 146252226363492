import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { verifyOtp } from 'leween-react-sdk/redux/actions/auth.actions';
import { X } from 'react-feather';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import OtpInput from '../../../../components/otp-input';
import ResetPassword from '../reset-password';
import schema from './schema';
import Button from '../../../../components/button';

const VerifyOtp = ({ isModalOpen, toggleModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isResetPasswordModalOpen, toggleResetPasswordModal] = useState(false);

  const {
    control, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    if (!localStorage.getItem('phone_number')) return;

    const hashedPhoneNumber = localStorage.getItem('phone_number')?.replace(/.(?=.{4})/g, '*');
    setPhoneNumber(hashedPhoneNumber);
  }, [isModalOpen]);

  const onSubmit = (data) => {
    dispatch(verifyOtp(data)).then((response) => {
      localStorage.setItem('reset_token', response.token);
      toggleResetPasswordModal(true);
      toggleModal();
    }).catch(() => {
      const message = 'Invaid OTP, please try again';
      setError('otp_code', { message, type: 'manual' });
    });
  };
  return (
    <>
      <Modal className="light-modal" show={isModalOpen} centered>
        <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
        <Modal.Body>
          <Container className="p-4">
            <Row>
              <Col md={12} className="d-flex flex-column align-items-center justify-content-center mb-4 mt-2">
                <h3 className="text-dark fw-bold mb-2">{t('verify_otp.title')}</h3>
                <p className="text-dark mb-0">
                  {t('verify_otp.subtitle')}
                  {' '}
                  {phoneNumber}
                </p>
              </Col>
            </Row>
            <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
                <OtpInput
                  name="otp_code"
                  control={control}
                  errors={errors}
                  {...register('otp_code')}
                />
                <Button className="btn yellow-btn w-100" title={t('shared.continue')} />
              </Col>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      <ResetPassword isModalOpen={isResetPasswordModalOpen} toggleModal={toggleResetPasswordModal} />
    </>
  );
};

export default VerifyOtp;
