import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { DateTime } from 'luxon';
import { Shimmer } from 'react-shimmer';
import { useTranslation } from 'react-i18next';
import CancelBooking from './cancel';

const BookingInfo = ({
  booking, isLoading, getBooking,
}) => {
  const { t } = useTranslation();
  const [isCancelBookingOpen, toggleCancelBookingModal] = useState(false);

  const toggleCancelBookingModalHandler = () => toggleCancelBookingModal(!isCancelBookingOpen);

  const renderTimeSlots = () => {
    if (isLoading) {
      return (
        <Row>
          {[1, 2, 3].map((index) => (
            <Col key={index} md={12} className="mb-2">
              <Shimmer className="w-100" height={40} width={100} />
            </Col>
          ))}
        </Row>
      );
    }

    const day = DateTime.fromISO(booking.start_datetime).toFormat('MMM dd, yyyy');
    const startTime = DateTime.fromISO(booking.start_datetime).toFormat('HH:00 a');
    const endTime = DateTime.fromISO(booking.end_datetime).toFormat('HH:00 a');
    return (
      <Row key={day} className="justify-content-between mb-1" dir="ltr">
        <Col xs="auto">
          <h6 className="text-dark mb-0">{day}</h6>
        </Col>
        <Col xs="auto">
          <h6 className="text-dark mb-0">
            {startTime}
            <span className="px-3">-</span>
            {endTime}
          </h6>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <div className="white-container py-3 px-4">
        <Row>
          <Col>
            <h6 className="text-space-gray">{t('shared.date_time')}</h6>
          </Col>
        </Row>
        {renderTimeSlots()}
      </div>
      {
        isLoading
          ? (
            <Row>
              {[1, 2, 3].map((index) => (
                <Col key={index} md={12} className="mb-2">
                  <Shimmer className="w-100" height={40} width={100} />
                </Col>
              ))}
            </Row>
          )
          : (
            <Row>
              <Col md={6}>
                <div className="white-container px-4 py-2 mt-2">
                  <Row className="justify-content-between h-100">
                    <Col xs="auto">
                      <h6 className="text-space-gray mb-0">{t('dashboard.bookings.attendees')}</h6>
                    </Col>
                    <Col xs="auto">
                      <h6 className="text-dark mb-0">{booking?.number_of_guests}</h6>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={6}>
                <div className="white-container px-4 py-2 mt-2">
                  <Row className="justify-content-between h-100">
                    <Col xs="auto">
                      <h6 className="text-space-gray mb-0">{t('dashboard.bookings.hours')}</h6>
                    </Col>
                    <Col xs="auto">
                      <h6 className="text-dark mb-0">{`${booking.duration / 60} ${t('dashboard.bookings.hrs')}`}</h6>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12}>
                <div className="white-container px-4 py-2 mt-2">
                  <Row className="justify-content-between h-100">
                    <Col xs="auto">
                      <h6 className="text-space-gray mb-0">Booking ID</h6>
                    </Col>
                    <Col xs="auto">
                      <h6 className="text-blue mb-0">{booking?.id}</h6>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )
      }
      {(booking?.status === 'booking-approved' || booking?.status === 'pending_host_approval')
      && (
        <Row className="mt-3">
          <Col md={12} className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-link text-dark text-decoration-underline cursor-pointer p-0"
              onClick={toggleCancelBookingModalHandler}
              disabled={booking?.status === 'booking-canceled-by-user'}
            >
              {t('dashboard.bookings.cancel_booking')}
            </button>
          </Col>
        </Row>
      )}
      <CancelBooking isOpen={isCancelBookingOpen} toggle={toggleCancelBookingModalHandler} getBooking={getBooking} />
    </>
  );
};

export default BookingInfo;
