import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Row, Col, Button as ButtonLink, FormGroup,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { updateListing, submitListing } from 'leween-react-sdk/redux/actions/host/listings.actions';
import { createEntity, deleteEntity, getEntities } from 'leween-react-sdk/redux/actions/host/entities.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../../../../../components/button';
import ConfirmationModal from './confirmation-modal';
import { setServerErrors } from '../../../../../helpers';
import InputField from '../../../../../components/input';
import schema from './schema';
import BinaryInput from '../../../../../components/binary-input';

const Booking = ({ stepper, listing, onUpdateListing }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    register: registerEntitiesForm,
    formState: { errors: entitiesErrors },
    handleSubmit: handleEntitiesSubmit,
    reset: entitiesReset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    register: registerListingForm,
    handleSubmit: handleListingSubmit,
    setError,
    reset: listingReset,
  } = useForm({
    mode: 'onChange',
  });

  const [isModalOpen, toggleModal] = useState(false);
  const [entities, setEntities] = useState([]);

  const getListingEntities = () => {
    dispatch(getEntities(listing?.id)).then((response) => setEntities(response.data));
  };

  const addListingEntity = (data) => {
    dispatch(createEntity(listing.id, { ...data, is_time_sync: true })).then(() => {
      entitiesReset();
      getListingEntities();
    });
  };

  const deleteListingEntity = (id) => {
    dispatch(deleteEntity(listing.id, id)).then(() => {
      getListingEntities();
    });
  };

  const onSubmit = (data) => {
    const newData = {
      step: 4,
      cancellation_policy: data?.cancellation_policy || undefined,
      ...data,
    };
    dispatch(updateListing(listing.id, newData))
      .then(() => {
        onUpdateListing(true);
        dispatch(submitListing(listing.id))
          .then(() => {
            toggleModal(!isModalOpen);
          });
      }).catch((error) => {
        const responseErrors = error?.response?.data?.errors || error?.response?.data?.message;
        setServerErrors(responseErrors, setError);
      });
  };

  useEffect(() => {
    listingReset({
      cancellation_policy: listing?.listing_data?.cancellation_policy,
      auto_approval_booking: listing?.auto_approval_booking,
    });
  }, [listing]);

  useEffect(() => {
    if (!listing?.id) return;

    getListingEntities();
  }, [listing?.id]);
  return (
    <>
      <section id="booking">
        <Row className="d-flex align-items-center">
          <Col md={12} className="mb-4">
            <h4 className="fw-bold text-dark">{t('steps.booking.title')}</h4>
            <p className="text-dark-gray">{t('steps.booking.subtitle')}</p>
          </Col>
        </Row>
        <form onSubmit={handleEntitiesSubmit(addListingEntity)}>
          <Row>
            <Col md={12}>
              <div className="bg-light rounded p-4">
                <Row>
                  <Col md={4}>
                    <InputField
                      label={t('steps.booking.entities.entity_title')}
                      name="title"
                      type="text"
                      errors={entitiesErrors}
                      {...registerEntitiesForm('title')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputField
                      label={t('steps.booking.entities.hourly_rate')}
                      name="hourly_rate"
                      type="number"
                      errors={entitiesErrors}
                      {...registerEntitiesForm('hourly_rate')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputField
                      label={t('steps.booking.entities.min_hours')}
                      name="min_hours"
                      type="number"
                      errors={entitiesErrors}
                      {...registerEntitiesForm('min_hours')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputField
                      label={t('steps.booking.entities.more_than')}
                      name="hour_discount.more_than"
                      type="number"
                      errors={entitiesErrors}
                      {...registerEntitiesForm('hour_discount.more_than')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputField
                      label={t('steps.booking.entities.percentage')}
                      name="hour_discount.percentage"
                      type="number"
                      errors={entitiesErrors}
                      {...registerEntitiesForm('hour_discount.percentage')}
                    />
                  </Col>
                  <Col md={4}>
                    <InputField
                      label={t('steps.booking.entities.max_guests')}
                      name="max_guests"
                      type="number"
                      errors={entitiesErrors}
                      {...registerEntitiesForm('max_guests')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex align-items-center justify-content-end">
                    <button type="submit" className="btn blue-btn">{t('shared.add')}</button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </form>
        <Row>
          {entities?.map((entity) => {
            return (
              <Col md={3}>
                <div className="bg-light p-4 rounded mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="text-dark mb-0">
                      {entity?.title}
                    </h5>
                    <h6 className="text-space-gray mb-0">
                      {entity?.max_guests}
                      {' '}
                      أشخاص
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2 mt-3">
                    <h6 className="text-space-gray mb-0">
                      {t('listing_details.booking_request.rate')}
                    </h6>
                    <h6 className="text-space-gray mb-0">
                      {t('listing_details.booking_request.min_duration')}
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                      <h5 className="text-dark mb-0 fw-bold">
                        {entity?.hourly_rate}
                      </h5>
                      <p className="mb-0 ms-2 fw-bold">
                        {`${localStorage.getItem(
                          'currency',
                        )}/${t('shared.hour')}`}
                      </p>
                    </div>
                    <h5 className="text-dark mb-0 fw-bold">
                      {entity?.min_hours}
                      {' '}
                      {t('shared.hour')}
                    </h5>
                  </div>
                  <p className="mb-0">
                    <span className="fw-bold">
                      {entity?.hour_discount?.percentage}
                      %
                    </span>
                    {' '}
                    {t('listing_details.booking_request.discount')}
                    {' '}
                    <span className="fw-bold">
                      {entity?.hour_discount?.more_than}
                      {' '}
                      {t('shared.hour')}
                    </span>
                  </p>
                  <div className="d-flex align-items-center justify-content-end mt-3">
                    <button type="button" className="btn red-btn" onClick={() => deleteListingEntity(entity.id)}>حذف</button>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </section>
      <form onSubmit={handleListingSubmit(onSubmit)}>
        <section className="mt-4">
          <FormGroup>
            <Row>
              <Col md={12}>
                <h4 className="fw-bold text-dark mb-4">قبول الحجز</h4>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={8}>
                <BinaryInput
                  id="auto_approval_booking"
                  label="قبول الحجز تلقائياً عند إتمام الدفع"
                  type="checkbox"
                  name="auto_approval_booking"
                  {...registerListingForm('auto_approval_booking')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-4">
                <h4 className="fw-bold text-dark">{t('steps.cancellations.title')}</h4>
                <p className="text-dark-gray">{t('steps.cancellations.subtitle')}</p>
              </Col>
            </Row>
            {t('steps.cancellations.policies', { returnObjects: true }).map((policy) => (
              <Row key={policy.value} className="mb-4">
                <Col md={8}>
                  <BinaryInput
                    id={policy.value}
                    label={policy.label}
                    type="radio"
                    value={policy.value}
                    name="cancellation_policy"
                    {...registerListingForm('cancellation_policy')}
                  />
                </Col>
                {policy.content.map((desc) => (
                  <p className="text-dark-gray mb-0 mt-2" key={desc}>{desc}</p>
                ))}
              </Row>
            ))}
          </FormGroup>
        </section>
        <section className="bs-footer">
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <ButtonLink className="light-btn br-0 px-4 me-2" onClick={() => stepper.previous()}>{t('shared.previous')}</ButtonLink>
              <Button className="blue-btn br-0 px-4" title={t('shared.save')} />
            </Col>
          </Row>
        </section>
      </form>
      <ConfirmationModal isModalOpen={isModalOpen} toggleModal={toggleModal} />
    </>
  );
};

export default Booking;
