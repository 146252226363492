import React from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { cancelBooking } from 'leween-react-sdk/redux/actions/booking.actions';
import { useForm } from 'react-hook-form';
import InputField from '../../../../../components/input';
import Button from '../../../../../components/button/index';
import BinaryInput from '../../../../../components/binary-input/index';
import schema from './schema';

const CancelBooking = ({ isOpen, toggle, getBooking }) => {
  const dispatch = useDispatch();
  const { bookingId } = useParams();

  const {
    register, formState: { errors }, handleSubmit, reset, setError,
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    dispatch(cancelBooking(bookingId, data)).then(() => {
      getBooking();
      toggle();
    }).catch((error) => {
      const message = error?.response?.data?.message || '';
      setError('reason', { message, type: 'manual' });
    });
  };

  return (
    <Modal onOpened={reset} isOpen={isOpen} toggle={toggle} centered className="cancel-modal">
      <X className="close-icon cursor-pointer" onClick={toggle} size={18} />
      <ModalBody className="p-md-5">
        <Row>
          <Col md={12}>
            <h4 className="text-center fw-bold my-4">Host Cancellation</h4>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs="auto">
            <p>
              Cancellation are very difficult for guests and may result in penalities to your account.
              {' '}
              <a href="/">Learn More</a>
            </p>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <h6 className="fw-bold mt-4 mb-0">Tell us why you are cancelling</h6>
              <p className="text-danger mb-0">{errors?.reason?.message}</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <BinaryInput
                type="radio"
                name="reason"
                value="incorrect listing type"
                label="The guest booked the incorrect listing type"
                {...register('reason')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BinaryInput
                type="radio"
                name="reason"
                value="violates the rules"
                label="The booking violates the rules posted on my listing"
                {...register('reason')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BinaryInput
                type="radio"
                name="reason"
                value="guest is unresponsive"
                label="The guest is unresponsive"
                {...register('reason')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BinaryInput
                type="radio"
                name="reason"
                value="space is no longer available"
                label="My space is no longer available"
                {...register('reason')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BinaryInput
                type="radio"
                name="reason"
                value="Other"
                label="Other"
                {...register('reason')}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="fw-bold mt-4">Tell your guest why you are cancelling</h6>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <InputField
                name="reasonComment"
                as="textarea"
                placeholder="Please tell us why you want to cancel"
                rows="5"
                {...register('reasonComment')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="mb-5">
                By click Cancel Booking you are agreed to
                {' '}
                <a href="/">LeWeen&apos;Services Agreement</a>
                ,which includes the
                {' '}
                <a href="1">Cancellation and Refund Policy.</a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button className="red-btn w-100" title="Cancel Booking" />
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CancelBooking;
