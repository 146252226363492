import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import Select from 'react-select';
import { Location, People } from 'iconsax-react';
import {
  Col, Container, InputGroup, Row,
} from 'react-bootstrap';
import Button from '../../components/button';
import ActivitiesField from '../../components/activities-input';
import selectStyles from '../../globals/styles';
import { peopleRangeOptions } from '../../helpers';

const Hero = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState({});
  const [coords, setCoords] = useState({});
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [peopleRange, setPeopleRange] = useState(undefined);

  const getCurrentLocation = () => {
    const geocoder = new window.google.maps.Geocoder();
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const position = { lat, lng };
        geocoder
          .geocode({ location: position })
          .then(((response) => {
            setCoords({ latitude: lat, longitude: lng });
            setSelectedPlace({ label: response?.results[0]?.address_components[4]?.long_name, value: response?.results[0]?.address_components[4]?.long_name });
          }));
      },
    );
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const updateSearchParams = useCallback((param) => {
    setSearchParams({ ...searchParams, ...param });
  }, [searchParams]);

  useEffect(() => {
    if (!selectedPlace) return;

    geocodeByAddress(selectedPlace.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => setCoords({ latitude: lat, longitude: lng }));
  }, [selectedPlace]);

  const onSearch = () => {
    const params = new URLSearchParams(searchParams);

    if (coords.latitude && coords.longitude) {
      params.append('latitude', coords.latitude);
      params.append('longitude', coords.longitude);
      params.append('distance', 20);
    }

    history.push(`/listings?${params.toString()}`);
  };
  return (
    <section className="hero-section">
      <Container>
        <div className="section-bg">
          <div className="content">
            <Row>
              <Col md={12}>
                <h1 className="text-white text-center fw-bold mb-5">
                  {t('home.hero_title')}
                </h1>
              </Col>
            </Row>
            <div className="white-bg">
              <Row>
                <Col md={3}>
                  <ActivitiesField updateSearchParams={updateSearchParams} />
                </Col>
                <Col md={3}>
                  <InputGroup className="input-group-merge">
                    <InputGroup.Text>
                      <Location size={22} />
                    </InputGroup.Text>
                    <GooglePlacesAutocomplete
                      selectProps={{
                        value: selectedPlace,
                        onChange: setSelectedPlace,
                        styles: selectStyles,
                        components: {
                          DropdownIndicator: () => null, IndicatorSeparator: () => null,
                        },
                        isClearable: true,
                        placeholder: t('home.hero_form.location'),
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <InputGroup className="input-group-merge">
                    <InputGroup.Text>
                      <People size={22} />
                    </InputGroup.Text>
                    <Select
                      className="react-select"
                      options={peopleRangeOptions}
                      styles={selectStyles}
                      placeholder={t('home.hero_form.people_range')}
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      onChange={(option) => {
                        setPeopleRange(option);
                        updateSearchParams({ 'people-range': option.value });
                      }}
                      value={peopleRange}
                    />
                  </InputGroup>
                </Col>
                <Col md={3}>
                  <Button
                    className="yellow-btn w-100"
                    title={t('home.hero_form.button')}
                    onClick={onSearch}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
