import React from 'react';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from '@hookform/error-message';
import { getErrorsByPath } from '../../helpers';

const InputField = React.forwardRef((props, ref) => {
  const errors = props.errors || [];

  return (
    <Form.Group className="mb-3">
      {props.label
      && <Form.Label className="text-dark-gray">{props.label}</Form.Label>}
      <Form.Control
        {...props}
        ref={ref}
        isInvalid={getErrorsByPath(errors, props.name) && true}
        autoComplete="off"
      >
        {props.children}
      </Form.Control>
      <ErrorMessage
        errors={errors}
        name={props.name}
        render={({ message }) => <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>}
      />
    </Form.Group>
  );
});

export default InputField;
