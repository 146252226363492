import React, { useEffect, useState, useCallback } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { LogOut, Settings } from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
import { logout, checkAuth } from 'leween-react-sdk/redux/actions/auth.actions';
import { getUser } from 'leween-react-sdk/redux/actions/user.actions';
import { useTranslation } from 'react-i18next';
import {
  Container, Nav, Navbar, NavDropdown,
} from 'react-bootstrap';
import { NavItem } from 'reactstrap';
import logo from '../../assets/images/logo.svg';
import Login from '../../views/auth/login';
import SignUp from '../../views/auth/sign-up';
import CompleteProfile from '../../views/auth/complete-profile';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [isLoginModalOpen, toggleLoginModal] = useState(false);
  const [isSignUpModalOpen, toggleSignUpModal] = useState(false);

  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user.data);
  const shared = useSelector((state) => state.shared);

  const logoutHandler = () => {
    dispatch(logout()).then(() => {
      localStorage.removeItem('api_token');
      localStorage.removeItem('missing_keys');
      localStorage.removeItem('role');
      history.push('/');
    });
  };

  useEffect(() => {
    dispatch(checkAuth()).then((response) => {
      if (response) {
        dispatch(getUser()).then((response) => {
          localStorage.setItem('role', response.data.role);
        });
      }
    });
  }, []);

  const openSignUpModal = useCallback(() => {
    toggleLoginModal(false);
    toggleSignUpModal(true);
  }, [isLoginModalOpen]);

  const openLoginModal = useCallback(() => {
    toggleSignUpModal(false);
    toggleLoginModal(true);
  }, [isSignUpModalOpen]);
  return (
    <>
      <header>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <img src={logo} alt="LeWeen" />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                {auth?.authenticated
                  && (
                    <>
                      <NavItem>
                        <NavLink className="nav-link text-decoration-none" activeClassName="active" to="/guest/liked-spaces">
                          المفضلة
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink className="nav-link text-decoration-none" activeClassName="active" to="/guest/bookings/upcoming">
                          {t('dashboard.bookings.title')}
                        </NavLink>
                      </NavItem>
                    </>
                  )}
                {auth?.authenticated
                  ? (
                    <NavDropdown title={`${user?.first_name} ${user?.last_name}`} id="basic-nav-dropdown">
                      <NavDropdown.Item>
                        <Link to="/profile/settings" className="w-100">
                          <Settings size={20} />
                          {' '}
                          <span>{t('header.accountSettings')}</span>
                        </Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item onClick={logoutHandler}>
                        <LogOut size={20} />
                        {' '}
                        <span>{t('header.logout')}</span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  )
                  : (
                    <>
                      <Nav.Link className="btn btn-sm yellow-btn me-2" onClick={() => toggleLoginModal(!isLoginModalOpen)}>{t('shared.login')}</Nav.Link>
                      <Nav.Link onClick={() => toggleSignUpModal(!isSignUpModalOpen)}>{t('shared.register')}</Nav.Link>
                    </>
                  )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <Login isModalOpen={isLoginModalOpen} toggleModal={toggleLoginModal} openSignUpModal={openSignUpModal} />
      <SignUp isModalOpen={isSignUpModalOpen} toggleModal={toggleSignUpModal} openLoginModal={openLoginModal} />
      <CompleteProfile isModalOpen={shared?.missingKeys?.length > 0} />
    </>
  );
};

export default Header;
