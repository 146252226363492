import { React, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Alert,
} from 'reactstrap';
import { Link, useParams, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getHostListings, deleteHostListing } from 'leween-react-sdk/redux/actions/host/listings.actions';
import { useTranslation } from 'react-i18next';
import { Shimmer } from 'react-shimmer';
import Pagination from '../../../../components/pagination';
import ConfirmationModal from './confirmation-modal';
import ListingCard from './listing-card';

const HostListings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { status } = useParams();

  const [listings, setListings] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, toggleModal] = useState(false);
  const [selectedListingId, setSelectedListingId] = useState();
  const [isLoading, toggleLoading] = useState(false);

  const getListings = (params) => {
    toggleLoading(true);
    dispatch(getHostListings(params)).then((response) => {
      setListings(response.data);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
      toggleLoading(false);
    }).catch(() => {
      toggleLoading(false);
    });
  };

  useEffect(() => {
    getListings({ page: paginationMeta.current_page, status: status === 'all' ? undefined : [status] });
  }, [status]);

  const handlePagination = (page) => {
    getListings({ page: page.selected + 1, status: status === 'all' ? undefined : [status] });
  };

  const openDeleteModal = (listingId) => {
    toggleModal(true);
    setSelectedListingId(listingId);
  };

  const closeDeleteModal = () => {
    toggleModal(false);
    setSelectedListingId();
  };

  const handleDeleteListing = () => {
    dispatch(deleteHostListing(selectedListingId)).then(() => {
      getListings({ page: paginationMeta.current_page, status: status === 'all' ? undefined : [status] });
      closeDeleteModal();
    });
  };

  const renderListings = () => {
    if (isLoading) {
      return [1, 2, 3, 4, 5].map((index) => <Col key={index} md={12}><Shimmer height={200} width={100} className="w-100 mb-3" /></Col>);
    }

    if (listings?.length === 0) {
      return (
        <Col md={12}>
          <Alert
            color="secondary"
            className="text-center"
          >
            {t('dashboard.ads.empty_placeholder')}
          </Alert>
        </Col>
      );
    }

    return listings?.map((listing) => (
      <Col md={12} key={listing.id}>
        <ListingCard listing={listing} openDeleteModal={openDeleteModal} />
      </Col>
    ));
  };
  return (
    <>
      <section className="host-dashboard pb-5">
        <div className="tabs-container">
          <Row>
            <Col md={9}>
              <Nav tabs className="dashboard-tabs">
                <NavItem>
                  <NavLink className="nav-link" activeClassName="active" to="/host/dashboard/listings/all">
                    {t('dashboard.ads.all')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" activeClassName="active" to="/host/dashboard/listings/published">
                    {t('dashboard.ads.active')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" activeClassName="active" to="/host/dashboard/listings/waiting-admin-approval">
                    {t('dashboard.ads.in_review')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" activeClassName="active" to="/host/dashboard/listings/draft">
                    {t('dashboard.ads.unpublished')}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md={3} className="d-flex justify-content-end">
              <Link to="/host/listings/create" className="btn blue-btn w-100 br-0">{t('dashboard.ads.add_new')}</Link>
            </Col>
          </Row>
        </div>
        <TabContent activeTab={status} className="pt-4">
          <TabPane tabId="all">
            <Row>
              {renderListings('all')}
            </Row>
          </TabPane>
          <TabPane tabId="published">
            <Row>
              {renderListings('published')}
            </Row>
          </TabPane>
          <TabPane tabId="waiting-admin-approval">
            <Row>
              {renderListings('waiting-admin-approval')}
            </Row>
          </TabPane>
          <TabPane tabId="draft">
            <Row>
              {renderListings('draft')}
            </Row>
          </TabPane>
          {!!listings?.length && (
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <Pagination totalPages={totalPages} handlePagination={handlePagination} />
              </Col>
            </Row>
          )}
        </TabContent>
      </section>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        closeModal={closeDeleteModal}
        deleteListing={handleDeleteListing}
      />
    </>
  );
};

export default HostListings;
