import React, { useEffect, useState } from 'react';
import {
  FormGroup, Label, ListInlineItem, List, Row, Col, Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCards } from 'leween-react-sdk/redux/actions/cards.actions';
import InputField from '../../../../components/input';
import mada from '../../../../assets/images/mada.png';
import visa from '../../../../assets/images/visa.png';
import CreatePaymentMethod from '../../../user/settings/payments/create';

const Payment = ({ bookingId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cards, setCards] = useState([]);
  const [isModalOpen, toggleModal] = useState(false);
  const { register, formState: { errors } } = useFormContext();

  useEffect(() => {
    dispatch(getCards()).then((response) => setCards(response.data));
  }, []);

  const policy = t('steps.cancellations.policies', { returnObjects: true })?.find((option) => option.value === 'very flexible');
  return (
    <>
      <div className="white-container third-step">
        <FormGroup className="pb-3">
          <Label className="fw-bold" for="coupon">
            {t('booking_request.coupon')}
          </Label>
          <InputField
            name="promocode"
            errors={errors}
            {...register('promocode')}
            type="text"
          />
        </FormGroup>
        <FormGroup>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Label className="mb-0 fw-bold" for="payment_method">
              {t('booking_request.pay_with')}
            </Label>
            <List className="mb-0 payment-methods">
              <ListInlineItem><img src={mada} alt="Mada" /></ListInlineItem>
              <ListInlineItem><img src={visa} alt="Visa" /></ListInlineItem>
            </List>
          </div>
          <Row>
            <Col md={9}>
              <Input
                name="card_id"
                errors={errors}
                type="select"
                {...register('card_id')}
                defaultValue=""
              >
                <option value="" disabled>Select card</option>
                {cards?.map((card) => {
                  return <option value={card.id}>{card?.masked_number}</option>;
                })}
              </Input>
            </Col>
            <Col md={3}>
              <InputField
                name="cvv"
                errors={errors}
                type="number"
                {...register('cvv')}
                placeholder="CVV"
              />
            </Col>
          </Row>
        </FormGroup>
        <p className="text-dark text-line"><span className="bg-white">OR</span></p>
        <button type="button" className="btn blue-btn w-100 my-3" onClick={() => toggleModal(!isModalOpen)}>{t('booking_request.add_payment_method')}</button>
        <h6 className="text-dark fw-bold mb-3">{t('listing_details.cancelation_policy')}</h6>
        <h6 className="text-dark fw-bold mb-3">{policy?.label}</h6>
        {policy?.content?.map((content) => (
          <p className="text-dark" key={content}>
            {content}
          </p>
        ))}
      </div>
      <CreatePaymentMethod isOpen={isModalOpen} toggleModal={toggleModal} callbackUrl={`${window.origin}/booking/request/${bookingId}`} />
    </>
  );
};

export default Payment;
