import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup.object().shape({
  password: yup.string().required(() => i18n.t('auth.validations.required'))
    .min(8, () => i18n.t('auth.validations.passwordLength'))
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      () => i18n.t('auth.validations.passwordSchema'),
    ),

  password_confirmation: yup.string().required(() => i18n.t('auth.validations.required'))
    .oneOf([yup.ref('password')], () => i18n.t('auth.validations.passwordMatch')),
});

export default schema;
