import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import BootstrapTable from 'react-bootstrap-table-next';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useDispatch } from 'react-redux';
import { getPaymentDetails, getPayments } from 'leween-react-sdk/redux/actions/guest/payments.api';
import { DateTime } from 'luxon';
import Statistics from './statistics';

const Payment = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [statistics, setStatistics] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [paginationMeta, setPaginationMeta] = useState({});
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    dispatch(getPayments({ page: 1 })).then((response) => {
      setTransactions(response.data);
      setStatistics(response.meta);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  }, []);

  const loadMore = () => {
    dispatch(getPayments({ page: paginationMeta.current_page + 1 })).then((response) => {
      setTransactions([...transactions, ...response.data]);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  };

  function renderDate(date) {
    return (
      <p className="text-space-gray small mb-0">
        {DateTime.fromISO(date).toFormat('yyyy-MM-dd')}
        <br />
        {DateTime.fromISO(date).toFormat('HH:mm:ss')}
      </p>
    );
  }

  function renderType(type) {
    return (
      <p className="text-dark text-capitalize mb-0">{type}</p>
    );
  }

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      formatter: (date) => renderDate(date),
    },
    {
      dataField: 'reference_id',
      text: 'Booking ID',
    },
    {
      dataField: 'reference_details',
      text: 'Booking Details',
      hidden: isMobile,
    },
    {
      dataField: 'type',
      text: 'Type',
      hidden: isMobile,
      formatter: (type) => renderType(type),
    },
    {
      dataField: 'payment_reference',
      text: 'Payment Reference',
      hidden: isMobile,
    },
    {
      dataField: 'amount',
      text: `Amount (${localStorage.getItem('currency')})`,
      hidden: isMobile,
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div className="expanded-row">
        {columns
          .filter((column) => column.hidden)
          .map((column) => {
            return (
              <p key={column?.dataField} className="text-dark mb-1">
                <span className="fw-bold">{column?.text}</span>
                :
                {' '}
                {row[column?.dataField]}
              </p>
            );
          })}
        <Row>
          <Col md={12}>
            <Row>
              <Col md={10} className="d-flex justify-content-between">
                <p className="text-mid-black fw-bold small mb-1">Items</p>
                <p className="text-mid-black fw-bold small mb-1">Price</p>
              </Col>
              <Col md={10} className="d-flex justify-content-between">
                <p className="text-mid-black mb-0">
                  {localStorage.getItem('currency')}
                  {' '}
                  {transactionDetails?.hourly_rate}
                  {' '}
                  x
                  {' '}
                  {transactionDetails?.total_booked_hours}
                  {' '}
                  Hours
                </p>
                <p className="text-mid-black mb-0">{transactionDetails?.hourly_rate * transactionDetails?.total_booked_hours}</p>
              </Col>
              <Col md={10} className="d-flex justify-content-between">
                <p className="text-mid-black mb-0">Cleaning Fee</p>
                <p className="text-mid-black mb-0">{transactionDetails?.cleaning_fees}</p>
              </Col>
              {transactionDetails?.add_ons?.map((addon) => {
                return (
                  <Col md={10} className="d-flex justify-content-between">
                    <p className="text-mid-black mb-0">{addon?.label}</p>
                    <p className="text-mid-black mb-0">{addon?.amount}</p>
                  </Col>
                );
              })}
              <Col md={10} className="d-flex justify-content-between">
                <p className="text-mid-black mb-0">Processing Fee</p>
                <p className="text-mid-black mb-0">{transactionDetails?.processing_fees}</p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <button type="button" className="btn btn-link fw-bold text-blue p-0">Download invoice.pdf</button>
          </Col>
        </Row>
      </div>
    ),
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <ChevronUp className="text-blue cursor-pointer" size={19} />;
      }
      return <ChevronDown className="text-blue cursor-pointer" size={19} />;
    },
    showExpandColumn: true,
    expandColumnPosition: 'right',
    onlyOneExpanding: true,
  };

  const rowEvents = {
    onClick: (e, row) => {
      dispatch(getPaymentDetails(row?.payment_reference)).then((response) => {
        setTransactionDetails(response.data);
      });
    },
  };
  return (
    <section className="reporting">
      <Statistics data={statistics} />
      <Row>
        <Col md={12}>
          <BootstrapTable
            keyField="date"
            data={transactions}
            columns={columns}
            expandRow={expandRow}
            rowEvents={rowEvents}
            striped
            headerClasses="table-header fw-bold"
            rowClasses="table-body"
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={2} xs={6} className="d-flex align-items-center">
          <h6 className="mb-0 text-space-gray">{`Showing ${transactions?.length} of ${paginationMeta?.total}`}</h6>
        </Col>
        <Col md={10} xs={6} className="d-flex justify-content-center">
          <button type="button" className="btn btn-light py-2 px-3" disabled={paginationMeta?.current_page >= totalPages} onClick={loadMore}>
            Show more
          </button>
        </Col>
      </Row>
    </section>
  );
};

export default Payment;
