/* eslint-disable */
import Geocode from "react-geocode";
import i18n from 'i18next';

/* Gets the nested array of errors by the name path */
export const getErrorsByPath = (errors, name) => {
  const parts = name.split('.');
  let result = errors;
  for (const part of parts) {
    if (!result) return;
    result = result[part];
  }
  return result;
};

/* Maps server errors and converts them to match yup objects shape */
export const setServerErrors = (responseErrors, setError) => {
  for (const key of Object.keys(responseErrors)) {
    const message = responseErrors[key][0];
    setError(key, { message, type: 'manual' });
  }
}

export const mapAddressToCoords = async (address) => {
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  try {
    const response = await Geocode.fromAddress(address);
    const { location } = response.results[0].geometry;
    return { lat: location.lat, lng: location.lng };
  } catch (error) {
    console.error(error);
  }
}

export const formatCoordinates = (coor) => {
  const latlngArr = coor?.split(', ').map((item) => Number(item));
  return { lat: latlngArr?.[0] ?? 0, lng: latlngArr?.[1] ?? 0 };
};

/* Google map styles and options */
export const mapContainerStyle = { width: '100%', height: '45vh' };

export const mapOptions = {
  strokeColor: '#2a32ff',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#2a32ff',
  fillOpacity: 0.2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 300,
  zIndex: 1,
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};

export const peopleRangeOptions = [
  {
    label: '2 to 10',
    value: '2-10',
  },
  {
    label: '11 to 25',
    value: '11-25',
  },
  {
    label: '26 to 50',
    value: '26-50',
  },
  {
    label: '51 to 100',
    value: '51-100',
  },
  {
    label: 'Over 100',
    value: '101-999999999',
  },
];

export const roles = {
  host: 'host',
  user: 'user',
}

export const getActiveLocale = () => i18n.language === 'en' ? 'en' : 'ar';

export const requestTypes = [
  { id: 1, name: 'Find premium space' },
  { id: 2, name: 'Feedback' },
  { id: 3, name: 'Investment' },
  { id: 4, name: 'Other' },
];