import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import StorageService from 'leween-react-sdk/storage-service';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrencies } from 'leween-react-sdk/redux/actions/currencies.actions';
import {
  Global, MoneySend,
} from 'iconsax-react';
import { Instagram, Twitter } from 'react-feather';
import { Col, Container, Row } from 'react-bootstrap';
import { becomeHost, getUser } from 'leween-react-sdk/redux/actions/user.actions';
import localStorageProvider from '../../localStorageProvider';
import CurrenciesModal from './currencies';
import logo from '../../assets/images/logo.svg';

const Footer = () => {
  const dispatch = useDispatch();
  const [isModalOpen, toggleModal] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const { i18n, t } = useTranslation();
  const history = useHistory();

  const user = useSelector((state) => state.user.data);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getCurrencies()).then((response) => {
      const defaultCurrency = response.data?.find((currency) => currency.default);
      setDefaultCurrency(defaultCurrency?.code);
      setCurrencies(response.data);
    });
  }, []);

  useEffect(() => {
    StorageService.get('currency').then((currency) => {
      setSelectedCurrency(currency || defaultCurrency);
      if (!currency) StorageService.set('currency', currency || defaultCurrency);
    });
  }, [defaultCurrency]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorageProvider.set('locale', lng);
  };

  const closeCurrencyModal = () => {
    toggleModal(false);

    StorageService.get('currency').then((currency) => {
      setSelectedCurrency(currency);
    });
  };

  const createAd = () => {
    if (user?.role === 'host') {
      history.push('/host/listings/create');
    } else {
      dispatch(becomeHost()).then(() => {
        dispatch(getUser()).then((response) => {
          localStorage.setItem('role', response.data.role);
          history.push('/host/listings/create');
        });
      });
    }
  };
  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <Col md={3} xs={4}>
              <h5 className="text-blue mb-3">{t('footer.brand')}</h5>
              <ul className="list-unstyled m-0 p-0">
                <li className="mb-2">
                  <Link to="/about" className="text-dark text-decoration-none">
                    {t('footer.about_us')}
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/terms" className="text-dark text-decoration-none">
                    {t('footer.terms')}
                  </Link>
                </li>
                {(user?.role === 'host' && auth?.authenticated)
                  && (
                    <li className="mb-2">
                      <Link className="text-dark text-decoration-none" to="/host/dashboard/listings/all">{t('header.ads')}</Link>
                    </li>
                  ) }
                {auth?.authenticated
                && (
                  <li className="text-dark cursor-pointer" onClick={createAd}>
                    {t('header.create_ad')}
                  </li>
                )}
              </ul>
            </Col>
            <Col md={3} xs={4}>
              <h5 className="text-blue mb-3">{t('footer.follow_us')}</h5>
              <ul className="list-unstyled mb-2 p-0">
                <li>
                  <Link to="/contact-us" className="text-dark text-decoration-none">
                    {t('footer.contact_us')}
                  </Link>
                </li>
              </ul>
              <ul className="list-inline">
                <li className="list-inline-item cursor-pointer" onClick={() => window.open('https://www.instagram.com/leweenapp/', '_blank')}>
                  <Instagram size={23} className="text-dark" />
                </li>
                <li className="list-inline-item cursor-pointer" onClick={() => window.open('https://twitter.com/LeWeenApp', '_blank')}>
                  <Twitter size={23} className="text-dark" />
                </li>
              </ul>
            </Col>
            <Col md={3} xs={4}>
              <h5 className="text-blue mb-3">{t('footer.settings')}</h5>
              <ul className="list-unstyled">
                {i18n.language === 'ar'
                  ? (
                    <li className="mb-2 text-dark cursor-pointer" onClick={() => changeLanguage('en')}>
                      <Global />
                      {' '}
                      English
                    </li>
                  ) : (
                    <li className="mb-2 text-dark cursor-pointer" onClick={() => changeLanguage('ar')}>
                      <Global />
                      {' '}
                      العربية
                    </li>
                  )}
                <li className="text-dark cursor-pointer" onClick={() => toggleModal(!isModalOpen)}>
                  <MoneySend />
                  {' '}
                  {selectedCurrency}
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <Link to="/">
                <img src={logo} alt="LeWeen" height={35} className="mb-3 mt-md-0 mt-3" />
              </Link>
              <p className="text-dark mb-0">
                لِوين المنصة السعودية الأولى اللي تستخدم الذكاء الاصطناعي لترشيح الأماكن والمواقع حسب مكانك وتفضيلاتك
              </p>
            </Col>
            <Col md={12}>
              <hr />
              <p className="mb-0 text-center text-dark">
                {t('footer.copyrights')}
                {' '}
                {t('footer.brand')}
                &copy;
                {(new Date().getFullYear())}
                {' '}
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
      <CurrenciesModal isModalOpen={isModalOpen} toggleModal={closeCurrencyModal} currencies={currencies} />
    </>
  );
};

export default Footer;
