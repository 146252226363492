import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import {
  Container, Row, Col, Spinner, FormGroup, Input, Label,
} from 'reactstrap';
import { GoogleMap, Marker } from '@react-google-maps/api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import InfoWindow from './info-window';
import ListingCard from '../../../../components/cards/ListingCard';
import { formatCoordinates } from '../../../../helpers';
import activeMarker from '../../../../assets/images/active-marker.png';
import defaultMarker from '../../../../assets/images/default-marker.png';
import emptyPlaceholder from '../../../../assets/images/empty-placeholder.svg';

const mapContainerStyle = { width: '100%', height: '100%' };

const Listings = ({
  isMapOn,
  listings,
  meta,
  loadNextPage,
  toggleListingCard,
  updateURLParams,
  updateListings,
  loading,
}) => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const [coordinates, setCoordinates] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const [address, setAddress] = useState('');
  const [searchOnDrag, toggleSearchOnDrag] = useState(false);

  const Map = useRef();

  const getCurrentLocation = () => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const position = { lat, lng };
        setCoordinates(position);
      },
    );
  };

  const onMapLoad = useCallback((map) => {
    Map.current = map;

    // Init google maps Geocoder
    const geocoder = new window.google.maps.Geocoder();
    setGeocoder(geocoder);

    const coordinates = { lat: +params.get('latitude'), lng: +params.get('longitude') };
    if (!coordinates.lat && !coordinates.lng) getCurrentLocation();
    Map.current?.setCenter(coordinates);
  }, [params.get('latitude'), params.get('longitude')]);

  useEffect(() => {
    if (!coordinates && listings.length > 0) {
      const latitude = params.get('latitude');
      const longitude = params.get('longitude');
      if (latitude && longitude) {
        const coords = `${latitude}, ${longitude}`;
        setCoordinates(formatCoordinates(coords));
      } else {
        getCurrentLocation();
      }
    }
  }, [listings]);

  const toggleInfoWindow = (id, formattedCoords) => {
    geocoder
      .geocode({ location: formattedCoords })
      .then((response) => setAddress(response.results[0].formatted_address))
      .catch(() => setAddress(''));
    toggleListingCard(id);
  };

  const closeInfoWindow = () => {
    toggleInfoWindow('Close');
  };

  const onMapViewChanged = useCallback(() => {
    if (!Map.current) return;
    // This will be called whenever we zoom, or pan in the map
    const center = Map.current.getCenter();
    const northEastBound = Map.current.getBounds().getNorthEast();
    let distance = window.google.maps.geometry.spherical.computeDistanceBetween(center, northEastBound) / 1000;
    distance = Math.max(20, distance);
    distance = Math.min(500, distance);
    setCoordinates({
      lat: center.lat(),
      lng: center.lng(),
    });

    if (searchOnDrag) {
      updateURLParams({ latitude: center.lat(), longitude: center.lng(), distance });
    }
  }, [searchOnDrag]);

  const renderListings = () => {
    if (loading) {
      return (
        <div className="scrollable">
          <Row className="h-100">
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <Col md={6} key={index}>
                <Skeleton width="100%" height={225} />
              </Col>
            ))}
          </Row>
        </div>
      );
    }
    if (listings?.length === 0) {
      return (
        <div className="scrollable">
          <Row className="h-100">
            <Col md={12} className="d-flex flex-column align-items-center justify-content-center">
              <img src={emptyPlaceholder} alt="empty placeholder" height={200} />
              <h5 className="text-space-gray mt-3">{t('listings.empty_placeholder')}</h5>
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div className="scrollable" id="scrollableDiv">
        <InfiniteScroll
          className="row"
          dataLength={listings?.length}
          next={loadNextPage}
          hasMore={meta?.current_page !== meta?.last_page}
          loader={(
            <Row>
              <Col md={12} className="d-flex justify-content-center">
                <Spinner md="lg" />
              </Col>
            </Row>
          )}
          scrollThreshold="200px"
          scrollableTarget="scrollableDiv"
        >
          {listings?.map((listing) => {
            return (
              <Col md={isMapOn ? 6 : 3} key={listing.id} className="mb-3">
                <ListingCard item={listing} updateList={updateListings} />
              </Col>
            );
          })}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <section className="bg-light">
      <Container fluid>
        <Row>
          <Col md={isMapOn ? 6 : 12}>
            {renderListings()}
          </Col>
          <Col md={6} className={`map-section pe-0 ${!isMapOn ? 'd-none' : ''}`}>
            <div className="search-checkbox">
              <FormGroup className="mb-0" check>
                <Input type="checkbox" checked={searchOnDrag} onChange={() => toggleSearchOnDrag(!searchOnDrag)} />
                {' '}
                <Label className="mb-0" check>
                  {t('listings.search_drag')}
                </Label>
              </FormGroup>
            </div>
            <GoogleMap
              loader
              center={coordinates}
              draggable
              mapContainerClassName="black-tooltip"
              mapContainerStyle={mapContainerStyle}
              onLoad={onMapLoad}
              zoom={12}
              options={{
                gestureHandling: 'greedy',
                zoomControl: false,
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
              }}
              onDragEnd={onMapViewChanged}
              onZoomChanged={onMapViewChanged}
            >
              {listings.map((listing) => {
                if (listing.coordinates) {
                  const formattedCoords = formatCoordinates(
                    listing.coordinates,
                  );
                  return (
                    <>
                      <Marker
                        icon={{
                          url: listing.isOpen ? activeMarker : defaultMarker,
                          scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        key={listing.id + listing.coordinates}
                        position={formattedCoords}
                        onClick={() => toggleInfoWindow(listing.id, formattedCoords)}
                        zIndex={listing.isOpen ? 50 : 10}
                      />
                      {listing.isOpen && (
                        <InfoWindow listing={listing} address={address} closeInfoWindow={closeInfoWindow} />
                      )}
                    </>
                  );
                }
                return null;
              })}
            </GoogleMap>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Listings;
