import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Statistics = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col md={4}>
          <div className="statistics-box white d-flex flex-column align-items-center justify-content-center">
            <p className="fw-bold mb-1 h2">
              {' '}
              {localStorage.getItem('currency')}
              {' '}
              {data?.gross_income || 0}

            </p>
            <p className="mb-0 h6">{t('dashboard.reporting.stats.total_payments')}</p>
          </div>
        </Col>
        <Col md={4}>
          <div className="statistics-box white d-flex flex-column align-items-center justify-content-center">
            <p className="fw-bold mb-1 h2">
              {localStorage.getItem('currency')}
              {' '}
              {data?.total_paycheck || 0}
            </p>
            <p className="mb-0 h6">{t('dashboard.reporting.stats.total_payouts')}</p>
          </div>
        </Col>
        <Col md={4}>
          <div className="statistics-box white d-flex flex-column align-items-center justify-content-center">
            <p className="fw-bold mb-1 h2">
              {localStorage.getItem('currency')}
              {' '}
              {data?.balance || 0}
            </p>
            <p className="mb-0 h6">{t('dashboard.reporting.stats.balance')}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Statistics;
