import i18n from 'i18next';

const selectStyles = {
  container: (styles) => ({
    ...styles,
    flex: 1,
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    borderTopLeftRadius: i18n.language === 'ar' ? '3px' : '0',
    borderBottomLeftRadius: i18n.language === 'ar' ? '3px' : '0',
    borderTopRightRadius: i18n.language === 'ar' ? '0' : '3px',
    borderBottomRightRadius: i18n.language === 'ar' ? '0' : '3px',
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    flex: 1,
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#FFFFFF' : '#00296b',
    backgroundColor: state.isSelected ? '#00296b' : '#FFFFFF',
    borderBottom: '1px solid #e2e2e2',
    padding: '6px 10px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#00296b',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(0, 41, 107, 0.6)',
  }),
  IndicatorsContainer: (base) => ({
    ...base,
    padding: '0',
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    zIndex: 11,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: '165px',
    padding: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    height: 42,
    padding: 0,
  }),
  input: (base) => ({
    ...base,
    height: 42,
    margin: 0,
    padding: 0,
  }),
};

export default selectStyles;
