import React, { useState } from 'react';
import {
  Modal, Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';

const ListingImages = ({
  isModalOpen, toggleModal, activeIndex, setActiveIndex, images,
}) => {
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = images?.map((image) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={image.id}
      >
        <img src={image.url} alt={image.id} />
      </CarouselItem>
    );
  });

  return (
    <Modal
      isOpen={isModalOpen}
      size="xl"
      toggle={() => toggleModal()}
      className="images-modal"
    >
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
      <div className="modal-caption">
        <p className="text-white fw-bold lead mb-0">{`${activeIndex + 1} / ${images?.length}`}</p>
      </div>
    </Modal>
  );
};

export default ListingImages;
