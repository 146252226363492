import React, { useCallback, useState } from 'react';
import {
  Card, CardImg, CardBody, CardTitle, CarouselItem, CarouselControl, Carousel,
} from 'reactstrap';
import StarRatings from 'react-star-ratings';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToWishlist, removeFromWishlist } from 'leween-react-sdk/redux/actions/guest/wishlist.actions';
import { Heart } from 'iconsax-react';
import imageAlt from '../../../assets/images/listing_image.png';
import localStorageProvider from '../../../localStorageProvider';
import Login from '../../../views/auth/login';
import SignUp from '../../../views/auth/sign-up';

const ListingCard = ({
  item,
  updateList,
  images,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoginModalOpen, toggleLoginModal] = useState(false);
  const [isSignUpModalOpen, toggleSignUpModal] = useState(false);

  const openSignUpModal = useCallback(() => {
    toggleLoginModal(false);
    toggleSignUpModal(true);
  }, [isLoginModalOpen]);

  const openLoginModal = useCallback(() => {
    toggleSignUpModal(false);
    toggleLoginModal(true);
  }, [isSignUpModalOpen]);

  const addToWishlistHandler = (e) => {
    e.stopPropagation();
    localStorageProvider.get('api_token').then((token) => {
      if (!token) {
        toggleLoginModal(true);
      } else {
        dispatch(addToWishlist(item.id)).then(() => {
          if (updateList) {
            updateList();
          }
        });
      }
    });
  };

  const removeFromWishlistHandler = (e) => {
    e.stopPropagation();
    dispatch(removeFromWishlist(item.id)).then(() => {
      if (updateList) {
        updateList();
      }
    });
  };

  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === item?.images.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? item?.images.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = item?.images?.map((image) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={image.id}
      >
        <img src={image.url} alt={image.id} />
      </CarouselItem>
    );
  });

  const renderListingImages = () => {
    if (!item.primary_image?.url || images?.length === 0) return <CardImg top src={item.primary_image?.url || imageAlt} alt={item?.listing_data?.title} />;

    return (
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    );
  };
  return (
    <>
      <Card className="listing-card border-0 cursor-pointer shadow-sm">
        <div className="img-wrapper">
          {renderListingImages()}
          {item?.in_wishlist
            ? (
              <div type="button" className="icon-wrapper" onClick={removeFromWishlistHandler}>
                <Heart color="#E82852" variant="Bold" />
              </div>
            )
            : (
              <div type="button" className="icon-wrapper" onClick={addToWishlistHandler}>
                <Heart color="#FFFFFF" />
              </div>
            )}
        </div>
        <CardBody className="p-3" onClick={() => history.push(`/listings/${item.id}`)}>
          <CardTitle tag="h5" className="text-dark fw-bold title">{item?.listing_data?.title || 'Untitled'}</CardTitle>
          <div className="d-flex align-items-center">
            <p className="text-dark mb-0 me-1">
              {item?.listing_data?.average_rating?.toFixed(1) || 0}
            </p>
            <StarRatings
              rating={item?.listing_data?.average_rating || 0}
              starRatedColor="#fdc500"
              numberOfStars={5}
              starDimension="16px"
              starSpacing="1px"
              isSelectable={false}
            />
            <p className="text-dark mb-0 ms-1">
              (
              {item?.listing_data?.ratings_count || 0}
              )
            </p>
          </div>
        </CardBody>
      </Card>
      <Login
        isModalOpen={isLoginModalOpen}
        toggleModal={toggleLoginModal}
        openSignUpModal={openSignUpModal}
      />
      <SignUp
        isModalOpen={isSignUpModalOpen}
        toggleModal={toggleSignUpModal}
        openLoginModal={openLoginModal}
      />
    </>
  );
};

export default ListingCard;
