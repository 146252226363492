import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { addToWishlist, removeFromWishlist } from 'leween-react-sdk/redux/actions/guest/wishlist.actions';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { Heart } from 'iconsax-react';
import { Share2 } from 'react-feather';
import { formatCoordinates } from '../../../helpers';
import ShareModal from './share-modal';
import pinIcon from '../../../assets/images/pin.svg';
import localStorageProvider from '../../../localStorageProvider';
import Login from '../../auth/login/index';
import SignUp from '../../auth/sign-up';

const ListingInfo = ({ data, listingId, updateListingDetails }) => {
  const dispatch = useDispatch();
  const [placeDetails, setPlaceDetails] = useState('');
  const [isShareModalOpen, toggleShareModalOpen] = useState(false);
  const [isLoginModalOpen, toggleLoginModal] = useState(false);
  const [isSignUpModalOpen, toggleSignUpModal] = useState(false);
  const { t } = useTranslation();

  const openSignUpModal = useCallback(() => {
    toggleLoginModal(false);
    toggleSignUpModal(true);
  }, [isLoginModalOpen]);

  const openLoginModal = useCallback(() => {
    toggleSignUpModal(false);
    toggleLoginModal(true);
  }, [isSignUpModalOpen]);

  const getPlaceDetails = () => {
    const coordinates = formatCoordinates(data?.coordinates);
    geocodeByLatLng(coordinates)
      .then((results) => setPlaceDetails(results[4]?.formatted_address));
  };

  useEffect(() => {
    getPlaceDetails();
  }, [data?.coordinates]);

  const addToWishlistHandler = () => {
    localStorageProvider.get('api_token').then((token) => {
      if (!token) {
        toggleLoginModal(true);
      } else {
        dispatch(addToWishlist(listingId)).then(() => {
          updateListingDetails();
        });
      }
    });
  };

  const removeFromWishlistHandler = () => {
    dispatch(removeFromWishlist(listingId)).then(() => {
      updateListingDetails();
    });
  };

  const toggleShareModalHandler = () => toggleShareModalOpen(!isShareModalOpen);
  return (
    <>
      <section className="pt-3">
        <Container>
          <Row>
            <Col md={8}>
              <h4 className="text-dark fw-bold mb-2">
                {data?.listing_data?.title || 'Untitled'}
              </h4>
              <div className="info-container append-dot d-flex align-items-center mb-md-0 mb-2">
                <img src={pinIcon} alt="location" />
                <p className="mb-0">{placeDetails}</p>
              </div>
            </Col>
            <Col md={4} className="d-flex align-items-center justify-content-md-end">
              <div
                className="info-container d-flex align-items-center cursor-pointer"
                onClick={toggleShareModalHandler}
              >
                <Share2 size={18} className="me-1" />
                <p className="mb-0">{t('shared.share')}</p>
              </div>
              {data?.in_wishlist
                ? (
                  <div className="info-container d-flex align-items-center ms-3 cursor-pointer" onClick={removeFromWishlistHandler}>
                    <Heart size={20} variant="Bold" color="#E82852" className="me-1" />
                    <p className="mb-0">إزالة</p>
                  </div>
                )
                : (
                  <div className="info-container d-flex align-items-center ms-3 cursor-pointer" onClick={addToWishlistHandler}>
                    <Heart size={20} className="me-1" />
                    <p className="mb-0">{t('shared.save')}</p>
                  </div>
                )}
            </Col>
          </Row>
        </Container>
      </section>
      <ShareModal
        isModalOpen={isShareModalOpen}
        toggleModal={toggleShareModalHandler}
      />
      <Login
        isModalOpen={isLoginModalOpen}
        toggleModal={toggleLoginModal}
        openSignUpModal={openSignUpModal}
      />
      <SignUp
        isModalOpen={isSignUpModalOpen}
        toggleModal={toggleSignUpModal}
        openLoginModal={openLoginModal}
      />
    </>
  );
};

export default ListingInfo;
