import React, { useState } from 'react';
import { Link2, Twitter, X } from 'react-feather';
import {
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap';
import ShareLink from 'react-twitter-share-link';

const ShareModal = ({ isModalOpen, toggleModal }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(document.location.href);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };
  return (
    <Modal isOpen={isModalOpen} toggle={toggleModal} centered size="sm" className="share-modal">
      <X className="close-icon cursor-pointer" onClick={toggleModal} size={18} />
      <ModalBody>
        <Container className="py-4">
          <Row>
            <Col md={12}>
              <h4 className="text-center text-dark mb-4">Share this Listing</h4>
              <button className="btn crimson-btn w-100 d-flex align-items-center justify-content-center mb-2" type="button" onClick={copyToClipboard}>
                <Link2 size={20} className="me-2" />
                {isCopied ? 'Copied!' : 'Copy Link'}
              </button>
              <ShareLink link={window.location.href}>
                {(link) => (
                  <a className="btn tweet-btn w-100 d-flex align-items-center justify-content-center" href={link} target="_blank" rel="noreferrer">
                    <Twitter size={20} className="me-2" />
                    Tweet
                  </a>
                )}
              </ShareLink>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ShareModal;
