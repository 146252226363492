import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getActivityDetails } from 'leween-react-sdk/redux/actions/activities.actions';
import { useTranslation } from 'react-i18next';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete';
import { Location, People, Refresh } from 'iconsax-react';
import {
  Col, Container, Form, InputGroup, Row,
} from 'react-bootstrap';
import Select from 'react-select';
import ActivitiesField from '../../../../components/activities-input';
import selectStyles from '../../../../globals/styles';
import { peopleRangeOptions } from '../../../../helpers';

const Filters = ({
  isMapOn,
  params,
  toggleMap,
  updateURLParams,
  resetParams,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedActivity, setActivity] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [peopleRange, setPeopleRange] = useState(undefined);

  useEffect(() => {
    if (!params.get('activities[]')) {
      setActivity(null);
      return;
    }

    dispatch(getActivityDetails(params.get('activities[]'))).then((response) => {
      setActivity({ label: response.data.name, value: response.data.id });
    });
  }, [params.get('activities[]')]);

  const updateSearchParams = useCallback((param) => {
    updateURLParams(param);
  }, [updateURLParams]);

  useEffect(() => {
    if (!params.get('latitude') && !params.get('longitude') && !params.get('distance')) {
      setSelectedPlace('');
      return;
    }

    geocodeByLatLng({ lat: +params.get('latitude'), lng: +params.get('longitude') })
      .then((results) => setSelectedPlace({ label: results[4]?.formatted_address, value: results[4]?.formatted_address }));
  }, [params.get('latitude'), params.get('longitude')]);

  const onLocationFieldChange = (place) => {
    const distance = params.get('distance') || 20;
    geocodeByAddress(place.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setSelectedPlace(place);
        updateURLParams({ latitude: lat, longitude: lng, distance });
      });
  };

  useEffect(() => {
    if (!params.get('people-range')) {
      setPeopleRange(undefined);
      return;
    }
    setPeopleRange(params.get('people-range'));
  }, [params.get('people-range')]);

  const onPeopleRangeChange = useCallback((option) => {
    setPeopleRange(option);
    updateURLParams({ 'people-range': option.value });
  }, [setPeopleRange]);
  return (
    <section className="filters bg-light d-flex align-items-center">
      <Container fluid>
        <Row>
          <Col md={3}>
            <ActivitiesField updateSearchParams={updateSearchParams} selectedValue={selectedActivity} />
          </Col>
          <Col md={3}>
            <InputGroup className="input-group-merge">
              <InputGroup.Text>
                <Location size={22} />
              </InputGroup.Text>
              <GooglePlacesAutocomplete
                selectProps={{
                  value: selectedPlace,
                  onChange: onLocationFieldChange,
                  styles: selectStyles,
                  components: {
                    DropdownIndicator: () => null, IndicatorSeparator: () => null,
                  },
                  isClearable: true,
                  placeholder: t('home.hero_form.location'),
                }}
              />
            </InputGroup>
          </Col>
          <Col md={3}>
            <InputGroup className="input-group-merge">
              <InputGroup.Text>
                <People size={22} />
              </InputGroup.Text>
              <Select
                className="react-select"
                options={peopleRangeOptions}
                styles={selectStyles}
                placeholder={t('home.hero_form.people_range')}
                components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                value={peopleRange}
                onChange={onPeopleRangeChange}
              />
            </InputGroup>
          </Col>
          <Col md="auto" className="d-flex align-items-center">
            <button type="button" className="btn yellow-btn d-flex align-items-center w-100 justify-content-center" onClick={resetParams}>
              <Refresh size={15} className="me-2" />
              {t('shared.reset')}
            </button>
          </Col>
          <Col md="auto" className="d-flex align-items-center justify-content-md-end justify-content-start mt-md-0 mt-3">
            <Form.Check
              defaultChecked={isMapOn}
              id="isMapOn"
              onChange={(e) => toggleMap(e.target.checked)}
              type="switch"
            />
            <Form.Label htmlFor="isMapOn" className="mb-0">{isMapOn ? t('listings.hide_map') : t('listings.show_map')}</Form.Label>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Filters;
