import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import FlagCheckboxField from '../../../../components/checkbox-field';
import infoCircle from '../../../../assets/images/info-circle.svg';

const AddOns = ({ addons }) => {
  const { t } = useTranslation();
  const { control, formState: { errors } } = useFormContext();
  return (
    <>
      <p className="fw-bold">{t('steps.activities.add_ons')}</p>
      {addons?.length > 0
        ? (
          <>
            {addons?.map((addon) => (
              <div className="white-container second-step mb-4" key={addon.id}>
                <FlagCheckboxField
                  errors={errors}
                  label={addon.type}
                  flag={addon.id}
                  name="addons"
                  control={control}
                  bsSize="md"
                  labelSize="lg"
                  className="checkbox-lg d-flex align-items-center"
                />
                <p className="mb-0 fw-bold text-dark lg">{addon.description}</p>
                <div className="badge dark-badge">
                  {localStorage.getItem('currency')}
                  {' '}
                  {addon.price}
                </div>
              </div>
            ))}
            <div className="note-container d-flex align-items-center mb-3">
              <img src={infoCircle} className="me-2" alt="alert" />
              <p className="text-white mb-0">Paid cash on site</p>
            </div>
          </>
        ) : (
          <Alert
            color="secondary"
            className="text-center"
          >
            No addons available
          </Alert>
        )}
    </>
  );
};

export default AddOns;
