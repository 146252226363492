import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getUser, updateUser, uploadUserImage } from 'leween-react-sdk/redux/actions/user.actions';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { Add, User } from 'iconsax-react';
import InputField from '../../../components/input';
import Button from '../../../components/button';
import schema from './schema';
import { setServerErrors } from '../../../helpers';
import OTP from '../otp';
import PhoneField from '../../../components/phone-input';

const CompleteProfile = ({ isModalOpen }) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const [imageSrc, setImage] = useState(undefined);
  const [isOtpModalOpen, toggleOtpModal] = useState(false);
  const [otpId, setOtpId] = useState(null);
  const { t } = useTranslation();

  const {
    register, reset, handleSubmit, control, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const parsedPhoneNumber = parsePhoneNumber(`+${data.phone_number}`);
    const newData = { ...data, phone_number: parsedPhoneNumber?.nationalNumber, phone_country: parsedPhoneNumber?.country };
    dispatch(updateUser(newData)).then((response) => {
      setOtpId(response.meta.otp_id);
      if (response?.data?.meta_data?.need_verification?.includes('phone_number')) {
        toggleOtpModal(!isOtpModalOpen);
      }
      dispatch(getUser()).then((response) => {
        localStorage.setItem('role', response.data.role);
      });
      reset('', { keepValues: false });
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };

  const openUploader = () => {
    inputRef.current.click();
  };

  const uploadImage = (e) => {
    const imageBlob = URL.createObjectURL(e.target.files[0]);
    setImage(imageBlob);

    const formData = new FormData();
    formData.append('profile_image', e.target.files[0]);
    dispatch(uploadUserImage(formData));
  };
  return (
    <>
      <Modal onShow={reset} className="light-modal" show={isModalOpen} centered backdrop="static">
        <Modal.Body>
          <Container className="py-4">
            <Row className="mb-4 mt-2">
              <Col md="12" className="d-flex flex-column align-items-center justify-content-center mb-4 mt-2">
                <h4 className="text-dark fw-bold mb-2">{t('complete_profile.title')}</h4>
                <p className="text-dark mb-0">{t('complete_profile.subtitle')}</p>
              </Col>
              <Col md={3} className="d-flex align-items-center">
                <div className="avatar cursor-pointer d-flex align-items-center justify-content-center" onClick={openUploader}>
                  {imageSrc
                    ? <img src={imageSrc} alt="User" />
                    : (
                      <div className="avatar-content">
                        <User className="text-white" size={38} />
                      </div>
                    )}
                  <div className="upload-btn d-flex align-items-center justify-content-center">
                    <Add />
                  </div>
                  <input type="file" hidden ref={inputRef} onChange={(e) => uploadImage(e)} />
                </div>
              </Col>
              <Col md={9} className="d-flex align-items-center">
                <p className="text-muted mb-0">
                  {t('complete_profile.profile_photo')}
                </p>
              </Col>
            </Row>
            <Form className="row mb-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md={6}>
                <InputField
                  label={t('shared.first_name')}
                  name="first_name"
                  errors={errors}
                  type="text"
                  {...register('first_name')}
                />
              </Col>
              <Col md={6}>
                <InputField
                  label={t('shared.last_name')}
                  name="last_name"
                  errors={errors}
                  type="text"
                  {...register('last_name')}
                />
              </Col>
              <Col md={12}>
                <PhoneField
                  name="phone_number"
                  control={control}
                  errors={errors}
                  {...register('phone_number')}
                  label={t('shared.phone')}
                />
              </Col>
              <Col md="12">
                <Button className="yellow-btn w-100" title={t('shared.continue')} />
              </Col>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
      <OTP isModalOpen={isOtpModalOpen} toggleModal={toggleOtpModal} otpId={otpId} />
    </>
  );
};

export default CompleteProfile;
