import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getBooking } from 'leween-react-sdk/redux/actions/booking.actions';
import { useParams } from 'react-router-dom';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { Shimmer } from 'react-shimmer';
import { DateTime } from 'luxon';
import Booking from './booking';
import BookingInfo from './info';
import orangeInfo from '../../../../assets/images/orange-info.svg';
import greenInfo from '../../../../assets/images/green-info.svg';
import redInfo from '../../../../assets/images/red-info.png';
import CreateReview from '../reviews/create-review';
import SubmitReviewMessage from '../reviews/submit-review';
import checkmark from '../../../../assets/images/checkmark.svg';

const BookingDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { bookingId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [booking, setBooking] = useState({});
  const [listingLocation, setListingLocation] = useState('');
  const [isCreateReviewModalOpen, toggleReviewModal] = useState(false);
  const [isSubmitReviewModalOpen, toggleSubmitReviewModal] = useState(false);

  const getBookingDetails = () => {
    setIsLoading(true);
    dispatch(getBooking(bookingId)).then((response) => {
      const [lat, lng] = response.data.listing?.coordinates.split(', ');
      geocodeByLatLng({ lat: +lat, lng: +lng }).then((results) => setListingLocation(results[4].formatted_address));
      setBooking(response.data);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getBookingDetails();
  }, []);

  const renderBookingStatus = (status) => {
    if (isLoading) {
      return (
        <Row>
          <Col md={12} className="mb-3">
            <Shimmer className="w-100" height={80} width={100} />
          </Col>
        </Row>
      );
    }

    switch (status) {
      case 'waiting-host-approval':
        return (
          <Row className="mb-3">
            <Col md={12}>
              <div className="status-alert orange-bg d-flex align-items-center">
                <img src={orangeInfo} alt="info" />
                <h6 className="mb-0 ms-3 fw-bold">Waiting for host response…</h6>
              </div>
            </Col>
          </Row>
        );
      case 'booking-approved':
        return (
          <>
            <Row>
              <Col>
                <div className="status-alert platinum-bg mb-3">
                  <div className="d-flex align-items-center">
                    <img src={greenInfo} alt="info" />
                    <h6 className="fw-bold mb-0 ms-2">
                      Booking Confirmed
                    </h6>
                  </div>
                  <p className="small text-space-gray mb-0 ms-4">Please follow instructions to your space!</p>
                </div>
              </Col>
              <Col md="auto">
                <h6 className="mb-0 text-space-gray">{DateTime.now().toFormat('h:mm a')}</h6>
              </Col>
            </Row>
          </>
        );
      case 'booking-rejected':
        return (
          <Row className="mb-3">
            <Col md={12}>
              <div className="status-alert red-bg d-flex align-items-center">
                <img src={redInfo} alt="info" />
                <h6 className="mb-0 ms-3 fw-bold">Declined by host.</h6>
              </div>
            </Col>
          </Row>
        );
      case 'pending-payment':
        return (
          <Row className="mb-3">
            <Col md={12}>
              <div className="status-alert orange-bg d-flex align-items-center">
                <img src={orangeInfo} alt="info" />
                <h6 className="mb-0 ms-3 fw-bold">Waiting for payment.</h6>
              </div>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <section className="mt-3">
        <Container>
          <Row>
            <Col md={12}>
              <h3 className="text-dark mb-4">{t('dashboard.bookings.details')}</h3>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
        <Container>
          {renderBookingStatus(booking?.status)}
          {booking?.status === 'booking-completed' && booking.review?.is_completed === 0
        && (
          <Row className="mb-2">
            <Col>
              <div className="status-alert platinum-bg">
                <div className="d-flex align-items-center">
                  <img src={checkmark} alt="info" />
                  <h6 className="fw-bold mb-0 ms-2">
                    Completed
                  </h6>
                </div>
                <p className="small text-space-gray  mb-0 ms-4">
                  Help other guests by writing a genuine and honest review.
                  {' '}
                  <span className="text-blue cursor-pointer" onClick={() => toggleReviewModal(!isCreateReviewModalOpen)}>Review your stay</span>
                </p>
              </div>
            </Col>
          </Row>
        )}
          <Row>
            <Col md={6} className="order-md-1 order-2">
              <Booking booking={booking} listingLocation={listingLocation} isLoading={isLoading} />
            </Col>
            <Col md={6} className="order-md-2 order-1">
              <BookingInfo booking={booking} isLoading={isLoading} getBooking={getBookingDetails} />
            </Col>
          </Row>
        </Container>
      </section>
      <CreateReview
        isOpen={isCreateReviewModalOpen}
        toggle={toggleReviewModal}
        toggleSubmitModal={toggleSubmitReviewModal}
        reviewId={booking.review?.id}
        getBooking={getBookingDetails}
      />
      <SubmitReviewMessage
        isOpen={isSubmitReviewModalOpen}
        toggle={toggleSubmitReviewModal}
      />
    </>
  );
};

export default BookingDetails;
