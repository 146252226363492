import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import HttpHelpers from 'leween-react-sdk/services/helpers';
import store from 'leween-react-sdk/redux/storeConfig';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'react-bootstrap';
import ScrollToTop from './routes/scroll-to-top';
import PublicRoute from './routes/public-route';
import Home from './views/home';
import ActivityDetails from './views/activities/details';
import About from './views/about-us';
import Dashboard from './views/host/navs';
import ListingsSearch from './views/listings/search';
import ContactUs from './views/contact-us';
import ListingConfiguration from './views/host/listings/steps';
import NotFound from './views/404';
import ListingDetails from './views/listings/details';
import BookingRequest from './views/booking/request';
import ConfirmBooking from './views/booking/confirm';
import ProfileSettings from './views/user/settings';
import GuestBookings from './views/guest/bookings/list';
import BookingDetails from './views/guest/bookings/details';
import HostBookingDetails from './views/host/bookings/details';
import ConfirmEmail from './views/user/confirm-email';
import Terms from './views/terms';
import PrivateRoute from './routes/private-route';
import Unauthorized from './views/unauthorized';
import { roles } from './helpers';
import localStorageProvider from './localStorageProvider';
import LikedSpaces from './views/guest/liked-spaces';

HttpHelpers.setBaseUrl(process.env.REACT_APP_API_URL);

function App() {
  const { i18n } = useTranslation();
  document.querySelector('html').dir = i18n.dir();

  useEffect(() => {
    localStorageProvider.get('locale').then((lng) => {
      let locale;
      if (!lng) {
        locale = 'ar';
      } else {
        locale = lng;
      }

      if (!lng) localStorageProvider.set('locale', locale);
      i18n.changeLanguage(locale);
      document.dir = i18n.dir();
      document.documentElement.lang = i18n.language;

      document.querySelector('#bootstrap-ltr').disabled = locale === 'ar';
      document.querySelector('#bootstrap-rtl').disabled = locale === 'en';
    });
  }, [i18n, i18n.language]);
  return (
    <ThemeProvider dir={i18n.dir()}>
      <BrowserRouter>
        <ScrollToTop>
          <Provider store={store}>
            <Toaster />
            <Switch>
              <PublicRoute path="/" exact component={Home} />
              <PrivateRoute path="/host/listings/create" component={ListingConfiguration} header roles={[roles.host]} />
              <PrivateRoute path="/host/listings/:listingId" component={ListingConfiguration} header roles={[roles.host]} />
              <PublicRoute path="/activities/:id" component={ActivityDetails} />
              <PublicRoute path="/about" component={About} />
              <PublicRoute path="/contact-us" exact component={ContactUs} />
              <PrivateRoute path="/host/dashboard" component={Dashboard} header roles={[roles.host]} />
              <PrivateRoute path="/host/inbox/:bookingId" component={HostBookingDetails} header roles={[roles.host]} />
              <PublicRoute path="/listings" exact component={ListingsSearch} header />
              <PublicRoute path="/listings/:id" component={ListingDetails} />
              <PrivateRoute exact path="/guest/bookings/:status(upcoming|past|payment|liked-spaces)" component={GuestBookings} header roles={[roles.host, roles.user]} />
              <PrivateRoute path="/guest/bookings/:bookingId" component={BookingDetails} header roles={[roles.host, roles.user]} />
              <PrivateRoute path="/guest/liked-spaces" component={LikedSpaces} header roles={[roles.host, roles.user]} />
              <PrivateRoute path="/booking/request/:bookingId" component={BookingRequest} header roles={[roles.host, roles.user]} />
              <PrivateRoute path="/booking/confirm" component={ConfirmBooking} header roles={[roles.host, roles.user]} />
              <PrivateRoute path="/profile" component={ProfileSettings} header roles={[roles.host, roles.user]} />
              <PublicRoute exact path="/terms" component={Terms} />
              <Route path="/unauthorized" component={Unauthorized} />
              <Route exact path="/confirm-email" component={ConfirmEmail} />
              <Route component={NotFound} />
            </Switch>
          </Provider>
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
