import * as yup from 'yup';
import i18n from 'i18next';

const schema = yup.object().shape({
  title: yup.string().required('This field is required').typeError('This field is required'),
  hour_discount: yup.object()
    .when(['min_hours'], (minHours) => {
      return yup.object().shape({
        more_than: yup.string().required(() => i18n.t('host.validations.required')).test(' > 1', () => i18n.t('host.validations.min'), (val) => Number(val) > minHours),
        percentage: yup.string().required(() => i18n.t('host.validations.required')).test(' > 1', () => i18n.t('host.validations.greater'), (val) => Number(val) > 0),
      });
    }),
  hourly_rate: yup.string().required(() => i18n.t('host.validations.required')).test(' > 1', () => i18n.t('host.validations.greater'), (val) => Number(val) > 0),
  max_guests: yup.string().required(() => i18n.t('host.validations.required')).test(' > 1', () => i18n.t('host.validations.greater'), (val) => Number(val) > 0),
  min_hours: yup.string().required(() => i18n.t('host.validations.required'))
    .test('> 1', () => i18n.t('host.validations.greater'), (val) => Number(val) > 0)
    .test('<= 12', () => i18n.t('host.validations.less'), (val) => Number(val) <= 12),
});

export default schema;
