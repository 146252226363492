import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const TermsOfService = () => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col md={12}>
        <h3 className="text-dark fw-bold mt-5">LeWeen Services Agreement</h3>
        <p className="text-space-gray fst-italic mt-3 mb-5">Last Uppdated: February 25, 2022</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.introduction.title')}</h4>
        <p className="text-dark">
          <span className="fw-bold">{t('terms.introduction.sectionOneBold')}</span>
          {' '}
          {t('terms.introduction.sectionOneNormal')}
        </p>
        <p className="mb-5">{t('terms.introduction.sectionTwo')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.1Services.title')}</h4>
        <p className="text-dark">{t('terms.1Services.sectionOne')}</p>
        <p className="text-dark mb-5">{t('terms.1Services.sectionTwo')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.2NatureOfTermsOfUse.title')}</h4>
        <p className="text-dark">{t('terms.2NatureOfTermsOfUse.sectionOne')}</p>
        <p className="text-dark">{t('terms.2NatureOfTermsOfUse.sectionTwo')}</p>
        <p className="text-dark mb-5">{t('terms.2NatureOfTermsOfUse.sectionThree')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.3IndependentLegalRelationships.title')}</h4>
        <p className="text-dark">{t('terms.3IndependentLegalRelationships.sectionOne')}</p>
        <p className="text-dark">{t('terms.3IndependentLegalRelationships.sectionTwo')}</p>
        <p className="text-dark mb-5">{t('terms.3IndependentLegalRelationships.sectionThree')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.4UsersAccountsRestrictions.title')}</h4>
        <h5 className="text-dark fw-bold">{t('terms.4UsersAccountsRestrictions.4-1UserAccounts.title')}</h5>
        <p className="text-dark">{t('terms.4UsersAccountsRestrictions.4-1UserAccounts.sectionOne')}</p>
        <p className="text-dark">{t('terms.4UsersAccountsRestrictions.4-1UserAccounts.sectionTwo')}</p>
        <p className="text-dark mb-4">{t('terms.4UsersAccountsRestrictions.4-1UserAccounts.sectionThree')}</p>
        <h5 className="text-dark fw-bold">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.title')}</h5>
        <p className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionOne')}</p>
        <p className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.content')}</p>
        <ul className="mb-5">
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointOne')}</li>
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointTwo')}</li>
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointThree')}</li>
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointFour')}</li>
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointFive')}</li>
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointSix')}</li>
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointSeven')}</li>
          <li className="text-dark">{t('terms.4UsersAccountsRestrictions.4-2RestrictionsUse.sectionTwo.pointEight')}</li>
        </ul>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.5SpaceBooking.title')}</h4>
        <p className="text-dark">{t('terms.5SpaceBooking.sectionOne')}</p>
        <p className="text-dark">{t('terms.5SpaceBooking.sectionTwo.content')}</p>
        <ul className="mb-5">
          <li className="text-dark">{t('terms.5SpaceBooking.sectionTwo.pointOne')}</li>
          <li className="text-dark">{t('terms.5SpaceBooking.sectionTwo.pointTwo')}</li>
        </ul>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.6PricesPaymentTaxesRefundPolicy.title')}</h4>
        <p className="text-dark">{t('terms.6PricesPaymentTaxesRefundPolicy.sectionOne')}</p>
        <p className="text-dark">{t('terms.6PricesPaymentTaxesRefundPolicy.sectionTwo')}</p>
        <p className="text-dark">{t('terms.6PricesPaymentTaxesRefundPolicy.sectionThree.content')}</p>
        <ul className="mb-5">
          <li className="text-dark">{t('terms.6PricesPaymentTaxesRefundPolicy.sectionThree.pointOne')}</li>
          <li className="text-dark">{t('terms.6PricesPaymentTaxesRefundPolicy.sectionThree.pointTwo')}</li>
          <li className="text-dark">{t('terms.6PricesPaymentTaxesRefundPolicy.sectionThree.pointThree')}</li>
          <li className="text-dark">{t('terms.6PricesPaymentTaxesRefundPolicy.sectionThree.pointFour')}</li>
        </ul>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.7IntellectualPropertyRights.title')}</h4>
        <p className="text-dark">{t('terms.7IntellectualPropertyRights.sectionOne')}</p>
        <p className="text-dark">{t('terms.7IntellectualPropertyRights.sectionTwo.content')}</p>
        <ul className="mb-5">
          <li className="text-dark">{t('terms.7IntellectualPropertyRights.sectionTwo.pointOne')}</li>
          <li className="text-dark">{t('terms.7IntellectualPropertyRights.sectionTwo.pointTwo')}</li>
        </ul>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.8PrivacyUseData.title')}</h4>
        <p className="text-dark">{t('terms.8PrivacyUseData.sectionOne')}</p>
        <p className="text-dark">{t('terms.8PrivacyUseData.sectionTwo')}</p>
        <p className="text-dark mb-5">{t('terms.8PrivacyUseData.sectionThree')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.9LimitationLiability.title')}</h4>
        <p className="text-dark">{t('terms.9LimitationLiability.sectionOne')}</p>
        <p className="text-dark mb-5">{t('terms.9LimitationLiability.sectionTwo')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.10Non-Compete.title')}</h4>
        <p className="text-dark mb-5">{t('terms.10Non-Compete.content')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.11No-Circumvention.title')}</h4>
        <p className="text-dark">{t('terms.11No-Circumvention.sectionOne')}</p>
        <p className="text-dark mb-5">{t('terms.11No-Circumvention.sectionTwo')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.12GoverningLawJurisdiction.title')}</h4>
        <p className="text-dark">{t('terms.12GoverningLawJurisdiction.sectionOne')}</p>
        <p className="text-dark mb-5">{t('terms.12GoverningLawJurisdiction.sectionTwo')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.13Severability.title')}</h4>
        <p className="text-dark mb-5">{t('terms.13Severability.content')}</p>
      </Col>
      <Col md={12}>
        <h4 className="text-dark fw-bold">{t('terms.14PrevailingLanguage.title')}</h4>
        <p className="text-dark mb-5">{t('terms.14PrevailingLanguage.content')}</p>
      </Col>
    </Row>
  );
};

export default TermsOfService;
