import { React, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import Button from '../../../../components/button';
import imageAlt from '../../../../assets/images/listing_image.png';

const ListingCard = ({ listing, openDeleteModal }) => {
  const { t } = useTranslation();

  let statusText;
  let statusClassName;

  if (listing.status === 'waiting-admin-approval') {
    statusText = t('dashboard.ads.in_review');
    statusClassName = 'in-review';
  } else if (listing.status === 'published') {
    statusText = t('dashboard.ads.active');
    statusClassName = 'published';
  } else if (listing.status === 'draft') {
    statusText = t('dashboard.ads.unpublished');
    statusClassName = 'draft';
  }

  const renderListingAction = () => {
    let action;

    if (listing.status === 'draft') {
      action = (
        <Col md={4}>
          <Link to={`/host/listings/${listing.id}`}>
            <Button className="blue-btn br-0 w-100" title={t('shared.continue')} />
          </Link>
        </Col>
      );
    } else {
      action = (
        <Col md={4}>
          <Link to={`/host/listings/${listing.id}`}>
            <Button className="light-btn br-0 w-100" title={t('dashboard.ads.edit_ad')} />
          </Link>
        </Col>
      );
    }

    if (listing.status === 'waiting-admin-approval') action = null;

    return action;
  };

  const progress = useMemo(() => {
    if (!listing.step) return 0;

    return (listing.step / 4) * 100;
  }, [listing?.step]);

  return (
    <div className="listing-card">
      <Row>
        <Col md={3}>
          <div className="img-container">
            <img src={listing.primary_image?.url || imageAlt} alt={listing.listing_data.title} />
          </div>
        </Col>
        <Col md={9}>
          <div className="listing-details">
            <Row>
              <Col md={8} xs={6} className="d-flex align-items-center">
                <h5 className="fw-bold text-dark mb-0">{listing.listing_data.title || 'Untitled'}</h5>
              </Col>
              <Col md={4} xs={6}>
                <div className={`status ${statusClassName}`}>
                  <p className="mb-0">{statusText}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="mb-0">{listing.listing_data?.description}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p className="text-space-gray small mb-1">{`${progress === 100 ? '' : `Listing is ${progress}% done. `}${t('dashboard.ads.updated_at')} ${listing.updated_at}`}</p>
                {progress !== 100 && <ProgressBar now={progress} />}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={8}>
                <Row>
                  {renderListingAction()}
                  <Col md={8}>
                    {listing?.status !== 'draft'
                      && (
                        <Link to={`/listings/${listing.id}`} className="btn light-btn br-0">
                          {t('dashboard.ads.public_view')}
                        </Link>
                      )}
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="d-flex justify-content-end">
                <Button className="light-btn br-0" onClick={() => openDeleteModal(listing.id)} title={t('dashboard.ads.delete_ad')} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ListingCard;
