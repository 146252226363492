import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Location } from 'iconsax-react';
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {
  Col, Container, InputGroup, Row,
} from 'react-bootstrap';
import Button from '../../../components/button';
import ActivitiesField from '../../../components/activities-input';
import selectStyles from '../../../globals/styles';

const Search = ({ activity, updateActivityDetails }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [searchParams, setSearchParams] = useState({});
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [coords, setCoords] = useState({});

  const getCurrentLocation = () => {
    const geocoder = new window.google.maps.Geocoder();
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const position = { lat, lng };
        geocoder
          .geocode({ location: position })
          .then(((response) => {
            setCoords({ latitude: lat, longitude: lng });
            setSelectedPlace({ label: response.results[4].formatted_address, value: response.results[4].formatted_address });
          }));
      },
    );
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  useEffect(() => {
    setSearchParams({
      ...searchParams, latitude: coords?.latitude, longitude: coords?.longitude, distance: 20, 'activities[]': activity?.id,
    });
  }, [activity, coords]);

  useEffect(() => {
    if (!selectedPlace) return;

    geocodeByAddress(selectedPlace.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => setCoords({ latitude: lat, longitude: lng }));
  }, [selectedPlace]);

  const updateSearchParams = useCallback((param) => {
    setSearchParams({ ...searchParams, ...param });
    updateActivityDetails(param?.activity_id);
  }, [searchParams]);

  const onSearch = () => {
    const params = new URLSearchParams(searchParams);
    history.push(`/listings?${params.toString()}`);
  };

  return (
    <section className="search-section">
      <Container fluid>
        <div className="section-bg" style={{ backgroundImage: `url(${activity?.image?.original})` }}>
          <div className="search-box">
            <Row className="mb-3">
              <Col md={12}>
                <ActivitiesField updateSearchParams={updateSearchParams} value={{ label: activity?.name, value: activity.id }} />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <InputGroup className="input-group-merge mb-3">
                  <InputGroup.Text>
                    <Location size={22} />
                  </InputGroup.Text>
                  <GooglePlacesAutocomplete
                    selectProps={{
                      value: selectedPlace,
                      onChange: setSelectedPlace,
                      styles: selectStyles,
                      components: {
                        DropdownIndicator: () => null, IndicatorSeparator: () => null,
                      },
                      isClearable: true,
                      placeholder: t('home.hero_form.location'),
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Button
                  className="yellow-btn w-100"
                  title={t('home.hero_form.button')}
                  onClick={onSearch}
                  disabled={!selectedPlace}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Search;
