import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getGuestBookings } from 'leween-react-sdk/redux/actions/guest/bookings.actions';
import { useTranslation } from 'react-i18next';
import { Shimmer } from 'react-shimmer';
import { useParams, NavLink } from 'react-router-dom';
import BookingCard from './booking-card';
import EmptyPlaceholder from '../../../../components/empty-placeholder';
import Payment from '../payment';

const GuestBookings = () => {
  const dispatch = useDispatch();
  const { status } = useParams();
  const [isLoading, toggleLoading] = useState(false);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [bookings, setBookings] = useState([]);

  const { t } = useTranslation();

  const getGuestBookingsList = (params, loadMore) => {
    toggleLoading(true);
    dispatch(getGuestBookings(params)).then((response) => {
      if (loadMore) {
        const newBookings = [...bookings, ...response.data];
        setBookings(newBookings);
      } else {
        setBookings(response.data);
      }
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
      toggleLoading(false);
    });
  };

  useEffect(() => {
    if (status === 'payment') return;

    getGuestBookingsList({ page: paginationMeta.current_page, filter: status });
  }, [status]);

  const loadMore = () => {
    getGuestBookingsList({ page: paginationMeta.current_page + 1, filter: status }, true);
  };

  const renderBookings = () => {
    if (isLoading) {
      return [1, 2, 3, 4, 5].map((index) => <Col key={index} md={12}><Shimmer height={200} width={100} className="w-100 mb-3" /></Col>);
    }
    if (bookings.length === 0) {
      return <EmptyPlaceholder text={t('dashboard.bookings.empty_placeholder')} />;
    }
    return bookings?.map((booking) => (
      <Col md={12} key={booking.id}>
        <BookingCard data={booking} />
      </Col>
    ));
  };
  return (
    <>
      <section className="guest-booking mt-5">
        <Container>
          <section>
            <Row>
              <Col md={12}>
                <Nav tabs className="dashboard-tabs">
                  <NavItem>
                    <NavLink className="nav-link" activeClassName="active" to="/guest/bookings/upcoming">
                      {t('dashboard.bookings.upcoming')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" activeClassName="active" to="/guest/bookings/past">
                      {t('dashboard.bookings.past')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" activeClassName="active" to="/guest/bookings/payment">
                      {t('dashboard.bookings.payments')}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </section>
          <section>
            <TabContent activeTab={status} className="pt-5">
              <TabPane tabId="upcoming">
                <Row>
                  {renderBookings()}
                </Row>
              </TabPane>
              <TabPane tabId="past">
                <Row>
                  {renderBookings()}
                </Row>
              </TabPane>
              <TabPane tabId="payment">
                <Payment />
              </TabPane>
            </TabContent>
          </section>
          {(status === 'upcoming' || status === 'past') && bookings?.length > 0 && (
            <section className="py-4">
              <Row>
                <Col md={12} className="d-flex justify-content-center">
                  <button type="button" className="btn light-btn px-3" onClick={loadMore} disabled={paginationMeta?.current_page >= totalPages}>{t('shared.show_more')}</button>
                </Col>
              </Row>
            </section>
          )}
        </Container>
      </section>
    </>
  );
};

export default GuestBookings;
