import React from 'react';
import {
  Row,
  Col,
  Label,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { updateUser } from 'leween-react-sdk/redux/actions/user.actions';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import InputField from '../../../../components/input';
import Button from '../../../../components/button';
import schema from './schema';

const Security = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit, register, formState: { errors }, reset,
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    toast.promise(
      dispatch(updateUser(data)).then(() => {
        reset();
      }),
      {
        loading: 'Changing password...',
        success: <b>Password changed successfully</b>,
        error: <b>Error occurred while changing password</b>,
      },
    );
  };

  return (
    <>
      <Row className="mb-4">
        <Col md={11}>
          <h4 className="fw-bold">{t('profile.change_password')}</h4>
        </Col>
      </Row>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="flex-column">
          <Col md="6">
            <Label className="fw-bold mb-1">
              {t('shared.new_password')}
              {' '}
              <strong className="text-blue">*</strong>
            </Label>
            <InputField
              errors={errors}
              name="password"
              type="password"
              {...register('password')}
            />
          </Col>
          <Col md="6">
            <Label className="fw-bold mb-1">
              {t('shared.confirm_password')}
              {' '}
              <strong className="text-blue">*</strong>
            </Label>
            <InputField
              errors={errors}
              name="password_confirmation"
              type="password"
              {...register('password_confirmation')}
              wrapperClassName="mb-1"
            />
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={12}>
            <Button className="blue-btn px-3" title={t('shared.update')} />
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Security;
