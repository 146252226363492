import React from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { rejectHostBooking } from 'leween-react-sdk/redux/actions/host/bookings.actions';
import InputField from '../../../../../components/input/index';
import Button from '../../../../../components/button/index';
import schema from './schema';

const DeclineBooking = ({
  isOpen, toggleModal, bookingId, getBooking,
}) => {
  const dispatch = useDispatch();

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    dispatch(rejectHostBooking(bookingId, data)).then(() => {
      toggleModal();
      getBooking();
    });
  };

  return (
    <Modal isOpen={isOpen} centered>
      <X className="modal-close-icon cursor-pointer" onClick={() => toggleModal()} size={18} />
      <ModalBody className="p-5">
        <Row>
          <Col>
            <h5 className="fw-bold text-center">Are you sure you want to decline?</h5>
            <p className="text-center">Explain to the guest why you can&apos;t host</p>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <InputField
              as="textarea"
              name="comment"
              errors={errors}
              {...register('comment')}
              placeholder="I apologize I can&apos;t."
              rows="5"
            />
          </Row>
          <Row>
            <Col>
              <Button className="red-btn w-100" title="Space Not Available" />
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default DeclineBooking;
