import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getActivities } from 'leween-react-sdk/redux/actions/activities.actions';
import Hero from './hero';
import Activities from './activities';

const Home = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getActivities()).then((response) => {
      setLoading(false);
      setActivities(response.data);
    }).catch(() => setLoading(false));
  }, []);
  return (
    <>
      <Hero />
      <Activities
        data={activities}
        loading={loading}
      />
    </>
  );
};

export default Home;
