import React from 'react';
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reviewBooking } from 'leween-react-sdk/redux/actions/guest/bookings.actions';
import { useDispatch } from 'react-redux';
import InputField from '../../../../components/input';
import Button from '../../../../components/button/index';
import RatingInput from '../../../../components/rating-input';
import schema from './schema';

const CreateReview = ({
  isOpen, toggle, toggleSubmitModal, reviewId, getBooking,
}) => {
  const dispatch = useDispatch();
  const {
    handleSubmit, register, control, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    dispatch(reviewBooking(reviewId, data)).then(() => {
      getBooking();
      toggleSubmitModal();
      toggle();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} centered className="reviews-modal">
      <X className="close-icon cursor-pointer" onClick={() => toggle()} size={18} />
      <ModalBody className="p-md-5">
        <Row>
          <Col md={12}>
            <h4 className="text-center fw-bold mb-3">Rate your experience</h4>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="justify-content-md-between justify-content-center m-3">
            <Col md={6} className="mb-4">
              <h6 className="fw-bold mb-2">Accuracy</h6>
              <RatingInput name="accuracy" errors={errors} {...register('accuracy')} control={control} />
            </Col>
            <Col md={6} className="mb-4">
              <h6 className="fw-bold mb-2">Communication</h6>
              <RatingInput name="communication" errors={errors} {...register('communication')} control={control} />
            </Col>
            <Col md={6} className="mb-4">
              <h6 className="fw-bold mb-2">Cleanlines</h6>
              <RatingInput name="cleanliness" errors={errors} {...register('cleanliness')} control={control} />
            </Col>
            <Col md={6} className="mb-4">
              <h6 className="fw-bold mb-2">Location</h6>
              <RatingInput name="location" errors={errors} {...register('location')} control={control} />
            </Col>
            <Col md={6} className="mb-4">
              <h6 className="fw-bold mb-2">Check-in</h6>
              <RatingInput name="check_in" errors={errors} {...register('check_in')} control={control} />
            </Col>
            <Col md={6} className="mb-4">
              <h6 className="fw-bold mb-2">Value</h6>
              <RatingInput name="value" errors={errors} {...register('value')} control={control} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label for="public_review" className="font-weight-500">Leave a public review</Label>
              <InputField
                errors={errors}
                as="textarea"
                name="public_review"
                rows="5"
                placeholder="What you think of the experience"
                {...register('public_review')}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-between">
              <Button className="blue-btn w-100" title="Send Review" />
            </Col>
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateReview;
