import React, {
  useState, useRef, useEffect, useCallback,
} from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getHostListingDetails } from 'leween-react-sdk/redux/actions/host/listings.actions';
import ListingStepper from '../../../../components/stepper';
import Location from './location';
import Setup from './setup';
import Availability from './availability';
import Booking from './booking';

const ListingConfiguration = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [stepper, setStepper] = useState(null);
  const ref = useRef(null);

  const params = useParams();
  const location = useLocation();
  const { listingId } = params;
  const [listing, setListing] = useState({});

  useEffect(() => {
    if (stepper) {
      if (!listingId) {
        setListing({});
      } else {
        dispatch(getHostListingDetails(listingId)).then((response) => {
          setListing(response.data);
          if (location.state?.step) {
            stepper.to(location.state?.step);
          } else if (response.data.step === 8) {
            stepper.to(1);
          } else {
            stepper.to(response.data.step + 1);
          }
        });
      }
    }
  }, [stepper, listingId]);

  const onUpdateListing = useCallback((navigateNext, newListingId) => {
    dispatch(getHostListingDetails(newListingId || listingId)).then((response) => {
      setListing(response.data);
      if (navigateNext) {
        stepper.next();
      }
    });
  }, [dispatch, listingId, setListing, stepper]);

  const steps = [
    {
      id: 'location',
      title: `1. ${t('steps.location.title')}`,
      content: <Location stepper={stepper} onUpdateListing={onUpdateListing} listingId={listing?.id} listingCoordinates={listing?.coordinates} />,
    },
    {
      id: 'setup',
      title: `2. ${t('steps.setup.title')}`,
      content: <Setup stepper={stepper} listing={listing} onUpdateListing={onUpdateListing} />,
    },
    {
      id: 'availability',
      title: `3. ${t('steps.availability.title')}`,
      content: <Availability stepper={stepper} listing={listing} onUpdateListing={onUpdateListing} />,
    },
    {
      id: 'booking',
      title: '4. الحجوزات',
      content: <Booking stepper={stepper} listing={listing} onUpdateListing={onUpdateListing} />,
    },
  ];
  return (
    <section className="stepper-container">
      <Container fluid>
        <ListingStepper
          ref={ref}
          steps={steps}
          options={{ linear: false }}
          instance={(el) => setStepper(el)}
          completedStep={listing.step}
        />
      </Container>
    </section>
  );
};

export default ListingConfiguration;
