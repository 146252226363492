import React from 'react';
import StorageService from 'leween-react-sdk/storage-service';
import LocaleService from 'leween-react-sdk/locale-service';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LocalStorageProvider from './localStorageProvider';
import LocaleResolver from './localeResolver';
import './i18n';

import './assets/scss/main.scss';

StorageService.setStorageProvider(LocalStorageProvider);
LocaleService.setLocaleResolver(LocaleResolver);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
