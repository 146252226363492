import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Col, Container, Row } from 'react-bootstrap';

const Activities = ({ data, loading }) => {
  const { t } = useTranslation();

  return (
    <section className="activities-section">
      <Container>
        <Row>
          <Col md={12} className="d-flex align-items-center">
            <h3 className="text-dark fw-bold mb-0">
              {t('home.activities.title')}
            </h3>
          </Col>
          {loading ? (
            <>
              {[1, 2, 3, 4].map((index) => (
                <Col md={3} key={index}>
                  <Skeleton height={250} width="100%" className="mt-4" />
                </Col>
              ))}
            </>
          ) : (
            <>
              {data?.map((activity) => (
                <Col md={3} key={activity.id}>
                  <Link to={`/activities/${activity.id}`}>
                    <div className="activity-card mt-4">
                      <img src={activity.image?.original} alt={activity?.name} />
                      <div className="overlay">
                        <h1 className="text-offwhite mb-0 text-center">{activity?.name}</h1>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default Activities;
