import React, { useState } from 'react';
import {
  Col, Row, List, Collapse,
} from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';
import parkingIcon from '../../../assets/images/parking.svg';

const Parking = ({ data }) => {
  const [isCollapsed, collapseContent] = useState(true);
  const { t } = useTranslation();
  return (
    <Row>
      <Col md={12}>
        <div className="border-bottom py-4">
          <div className="d-flex align-items-center justify-content-between cursor-pointer" onClick={() => collapseContent(!isCollapsed)}>
            <div className="d-flex align-items-center">
              <img src={parkingIcon} alt="parking" height="35" />
              <h5 className="text-dark fw-bold ms-2 mb-0">{t('listing_details.parking')}</h5>
            </div>
            {isCollapsed ? <ChevronUp /> : <ChevronDown />}
          </div>
          <Collapse className="mt-3" isOpen={isCollapsed}>
            <Row>
              <Col md={12}>
                <List>
                  {data?.options?.length ? data?.options?.map((option) => (<li className="text-blue" key={option}>{option}</li>))
                    : <h5 className="text-blue text-center">{t('listing_details.no_parking')}</h5>}
                </List>
                <p className="text-blue mb-0">{data?.description}</p>
              </Col>
            </Row>
          </Collapse>
        </div>
      </Col>
    </Row>
  );
};

export default Parking;
