import { React } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
  const { t } = useTranslation();

  return (
    <section className="h-100 d-flex align-items-center">
      <Container className="h-100 d-flex flex-column justify-content-center align-items-center">
        <Row>
          <Col md={12} className="text-center fw-bold">
            <h1 className="text-blue display-1 mb-2">{t('errors.unauthorized.title')}</h1>
            <h2 className="fw-bold mb-0 text-dark">{t('errors.unauthorized.subtitle')}</h2>
            <div className="d-flex justify-content-center">
              <Link to="/" className="btn yellow-btn mt-4 px-4">
                {t('errors.unauthorized.button')}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Unauthorized;
