import React from 'react';
import { Spinner } from 'reactstrap';

const Button = ({
  onClick, className, title, disabled, loading,
}) => {
  return (
    <>
      <button type="submit" onClick={onClick} className={`btn ${className}`} disabled={disabled || loading}>
        {
          loading
            ? <Spinner size="sm" />
            : title
        }
      </button>
    </>
  );
};

export default Button;
