import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { X } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Label, Form, Modal, ModalBody, Row, Col, List, ListInlineItem,
} from 'reactstrap';
import { tokenizeCard } from 'leween-react-sdk/redux/actions/cards.actions';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import mada from '../../../../../assets/images/mada.png';
import visa from '../../../../../assets/images/visa.png';
import InputField from '../../../../../components/input';
import schema from '../schema';
import styles from '../styles';

const CreatePaymentMethod = ({ isOpen, toggleModal, callbackUrl }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    wrapperProps,
  } = usePaymentInputs();
  const dispatch = useDispatch();
  const { handleSubmit, register, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    dispatch(tokenizeCard({ callback_url: callbackUrl, is_primary: true })).then((response) => {
      const html = `
        <form id="add_card" style="display: none;" method="POST" action="https://paytest.directpay.sa/SmartRoutePaymentWeb/SRPayMsgHandler">
          <input type="hidden" name="MessageID" value="${response.message_id}">
          <input type="hidden" name="TransactionID" value="${response.transaction_id}">
          <input type="hidden" name="MerchantID" value="${response.merchant_id}">
          <input type="hidden" name="Amount" value="${response.amount}">
          <input type="hidden" name="CurrencyISOCode" value="${response.currency_code}">
          <input type="hidden" name="PaymentMethod" value="${response.payment_method}">
          <input type="hidden" name="GenerateToken" value="${response.generate_token}">
          <input type="hidden" name="Language" value="${response.language}">
          <input type="hidden" name="Version" value="${response.version}">
          <input type="hidden" name="ResponseBackURL" value="${response.response_back_url}">
          <input type="hidden" name="CardNumber" value=${cardNumber?.replace(/\s/g, '')}>
          <input type="hidden" name="ExpiryDateYear" value=${expiryDate?.split('/')[1]}>
          <input type="hidden" name="ExpiryDateMonth" value=${expiryDate?.split('/')[0]}>
          <input type="hidden" name="SecurityCode" value=${securityCode}>
          <input type="hidden" name="CardHolderName" value="${data.full_name}">
          <input type="hidden" name="SecureHash" value="${response.secure_hash}">
        </form>
      `;
      document.querySelector('body').innerHTML += html;
      document.getElementById('add_card').submit();
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={() => toggleModal()} className="payment-modal" size="lg" centered>
      <X className="close-icon text-gray cursor-pointer" onClick={() => toggleModal()} size={18} />
      <ModalBody className="p-5">
        <h4 className="text-dark fw-bold text-center fw-bold mb-0">Add payment method</h4>
        <h6 className="text-dark small text-center mb-4">*Your payment is stored securely.</h6>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={6}>
              <Label className="mb-1">Full Name</Label>
              <InputField
                name="full_name"
                errors={errors}
                type="text"
                placeholder="Full Name"
                {...register('full_name')}
              />
            </Col>
            <Col md={6}>
              <div className="d-flex justify-content-between align-items-center mb-1">
                <Label className="mb-0">Card</Label>
                <List className="mb-0 payment-methods">
                  <ListInlineItem><img src={visa} alt="Visa" /></ListInlineItem>
                  <ListInlineItem><img src={mada} alt="Mada" /></ListInlineItem>
                </List>
              </div>
              <PaymentInputsWrapper {...wrapperProps} styles={styles}>
                <input {...getCardNumberProps({ onChange: (e) => setCardNumber(e.target.value) })} value={cardNumber} />
                <input {...getExpiryDateProps({ onChange: (e) => setExpiryDate(e.target.value) })} value={expiryDate} />
                <input {...getCVCProps({ onChange: (e) => setSecurityCode(e.target.value) })} value={securityCode} />
              </PaymentInputsWrapper>
            </Col>
            <Col md={6}>
              <Label className="mb-1">Address</Label>
              <InputField
                name="billing_address"
                errors={errors}
                type="text"
                placeholder="Billing Address"
                {...register('billing_address')}
              />
            </Col>
            <Col md={6}>
              <Label className="mb-1">City</Label>
              <InputField
                name="city"
                errors={errors}
                type="text"
                placeholder="e.g. Riyadh"
                {...register('city')}
              />
            </Col>
            <Col md={6}>
              <Label className="mb-1">Country</Label>
              <InputField
                name="country"
                errors={errors}
                type="text"
                placeholder="Saudi Arabia"
                {...register('country')}
              />
            </Col>
            <Col md={6}>
              <Label className="mb-1">Zip Code</Label>
              <InputField
                name="zip_code"
                errors={errors}
                type="text"
                placeholder="Ex. 14221"
                {...register('zip_code')}
              />
            </Col>
            <Col md={12} className="d-flex justify-content-center">
              <button type="submit" className="btn blue-btn w-50 mt-2">Add Card</button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreatePaymentMethod;
