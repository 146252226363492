import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getActivityDetails } from 'leween-react-sdk/redux/actions/activities.actions';
import { getPublicListings } from 'leween-react-sdk/redux/actions/public-listings.actions';
import { useTranslation } from 'react-i18next';
import Search from './search';
import ListingCard from '../../../components/cards/ListingCard';

const ActivityDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const [activity, setActivity] = useState({});
  const [listings, setListings] = useState([]);

  const getSimilarListings = (activities) => {
    dispatch(getPublicListings({ page: 1, activities, limit: 3 })).then((response) => {
      setListings(response.data);
    });
  };

  const getDetails = (activityId) => {
    dispatch(getActivityDetails(activityId))
      .then((response) => {
        setActivity(response?.data);
        const activities = [response.data.id];
        getSimilarListings(activities);
      });
  };

  useEffect(() => {
    getDetails(id);
  }, [id]);

  const updateActivityDetails = useCallback((activityId) => {
    getDetails(activityId);
  }, [id]);

  const updateListings = useCallback(() => {
    const activities = [activity.id];
    getSimilarListings(activities);
  }, [listings]);
  return (
    <>
      <Search activity={activity} updateActivityDetails={updateActivityDetails} />
      {listings?.length > 0
        && (
          <section className="py-5">
            <Container>
              <Row className="mb-3">
                <Col md="12">
                  <h3 className="fw-bold text-center">
                    {`${t('shared.recently_added')} ${activity?.name}`}
                  </h3>
                </Col>
              </Row>
              <Row>
                {listings.map((item) => (
                  <Col md={4} key={item?.id}>
                    <ListingCard item={item} updateList={updateListings} />
                  </Col>
                ))}
              </Row>
            </Container>
          </section>
        )}
    </>
  );
};

export default ActivityDetails;
