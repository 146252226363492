import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import {
  Row, Col, Container, ListInlineItem, Label,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { getBookingsCalendar, getHostListings, getListingBookings } from 'leween-react-sdk/redux/actions/host/listings.actions';
import { getListingDetails } from 'leween-react-sdk/redux/actions/public-listings.actions';
import { Link } from 'react-router-dom';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';
import BookingCard from './booking-card';
import BlockTime from './block-time';
import asyncSelectStyles from '../../../../globals/async-select';
import EmptyPlaceholder from '../../../../components/empty-placeholder';
import 'react-datepicker/dist/react-datepicker.css';

const Calendar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [bookings, setBookings] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [isBlockTimeModalIsOpen, toggleBlockTimeModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState({});
  const [availability, setAvailability] = useState({});
  const [selectedDay, setSelectedDay] = useState({});
  const [calendar, setCalendar] = useState({});

  const loadOptions = async (search, loadedOptions, { page }) => {
    const response = await dispatch(getHostListings({ page, status: ['published'] }));
    const options = response.data?.map((listing) => ({
      label: listing?.listing_data?.title || 'Untitled',
      value: listing?.id,
    }));
    const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
    if (!selectedListing.value) {
      setSelectedListing({ label: response.data[0]?.listing_data?.title || 'Untitled', value: response.data[0]?.id });
    }
    return {
      options,
      hasMore: page !== totalPages,
      additional: {
        page: page + 1,
      },
    };
  };

  useEffect(() => {
    if (!selectedListing.value) return;

    const startDate = DateTime.fromJSDate(selectedDate).toFormat('yyyy-MM-dd');
    dispatch(getListingBookings(selectedListing?.value, { 'start-date': startDate, page: 1, limit: 3 })).then((response) => {
      setBookings(response.data);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });

    dispatch(getListingDetails(selectedListing?.value)).then((response) => {
      const data = response.data.availability;
      const newAvailability = {};
      const keys = Object.keys(data);
      for (const key of keys) {
        const parsedData = JSON.parse(data[key]);
        parsedData.allDay = parsedData.allDay.toString();
        newAvailability[key] = parsedData;
      }
      setAvailability(newAvailability);
      setSelectedDay({ label: 'Sunday', data: newAvailability.sunday });
    });
  }, [selectedListing]);

  const loadMore = () => {
    const startDate = DateTime.fromJSDate(selectedDate).toFormat('yyyy-MM-dd');
    dispatch(getListingBookings(selectedListing?.value, { 'start-date': startDate, page: paginationMeta.current_page + 1, limit: 3 })).then((response) => {
      setBookings([...bookings, ...response.data]);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  };

  useEffect(() => {
    if (!selectedListing?.value) return;

    const year = DateTime.fromJSDate(selectedDate).toFormat('yyyy');
    const month = DateTime.fromJSDate(selectedDate).toFormat('M');

    dispatch(getBookingsCalendar(selectedListing?.value, { year, month })).then((response) => {
      setCalendar(response.data);
    });
  }, [selectedListing]);

  const selectDate = useCallback((date) => {
    const startDate = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    dispatch(getListingBookings(selectedListing?.value, { 'start-date': startDate, page: 1, limit: 3 })).then((response) => {
      setSelectedDate(date);
      setBookings(response.data);
      setPaginationMeta(response.meta);
      const totalPages = Math.ceil(response.meta.total / response.meta.per_page);
      setTotalPages(totalPages);
    });
  }, [selectedDate, setSelectedDate, selectedListing]);

  const onMonthChange = useCallback((date) => {
    const year = DateTime.fromJSDate(date).toFormat('yyyy');
    const month = DateTime.fromJSDate(date).toFormat('M');
    dispatch(getBookingsCalendar(selectedListing?.value, { year, month })).then((response) => {
      setCalendar(response.data);
    });
  }, [selectedListing]);

  const renderDayClassName = useCallback((date) => {
    const day = DateTime.fromJSDate(date).toFormat('d');
    switch (calendar[day]) {
      case 'waiting-host-approval':
        return 'pending';
      case 'booking-approved':
        return 'approved';
      default:
        return '';
    }
  }, [calendar]);
  return (
    <>
      <section className="calendar-wrapper">
        <Container>
          <Row>
            <Col md={4}>
              <Row>
                <Col md={12}>
                  <div className="dropdown-container mb-4">
                    <Label className="text-dark mb-0">{t('dashboard.calendar.select_ad')}</Label>
                    <AsyncPaginate
                      cacheOptions
                      components={{ IndicatorSeparator: () => null }}
                      defaultOptions
                      loadOptions={loadOptions}
                      onChange={(option) => setSelectedListing(option)}
                      value={selectedListing}
                      styles={asyncSelectStyles}
                      additional={{
                        page: 1,
                      }}
                    />
                  </div>
                  <DatePicker
                    adjustDateOnChange
                    selected={selectedDate}
                    onChange={selectDate}
                    onMonthChange={onMonthChange}
                    dayClassName={renderDayClassName}
                    inline
                  />
                </Col>
                <Col md={6} className="d-flex align-items-center">
                  <div className="blue-circle" />
                  <h6 className="small text-dark mb-0 ms-2">{t('dashboard.bookings.new_request')}</h6>
                </Col>
                <Col md={6} className="d-flex align-items-center">
                  <div className="dark-triangle" />
                  <h6 className="small text-dark mb-0 ms-2">{t('dashboard.bookings.confirmed')}</h6>
                </Col>
                <Col md={12} className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6 className="text-dark fw-bold mb-0">{t('dashboard.calendar.operating_hours')}</h6>
                    <Link to={`/host/dashboard/operating-hours/${selectedListing?.value}`} className="text-blue text-underline mb-0 cursor-pointer">{t('shared.edit')}</Link>
                  </div>
                  <ul className="p-0 days-list mt-3">
                    {t('shared.days', { returnObjects: true })?.map((day) => {
                      return (
                        <>
                          <ListInlineItem
                            className={`${selectedDay?.label === day.label ? 'active' : ''}`}
                            key={day.id}
                            onClick={() => setSelectedDay({ label: day.label, data: availability[day.id] })}
                          >
                            {day.label.charAt(0)}
                          </ListInlineItem>
                        </>
                      );
                    })}
                  </ul>
                  <div className="d-flex justify-content-between">
                    <h6 className="text-dark fw-bold mb-0">{selectedDay?.label}</h6>
                    <div>
                      {selectedDay?.data?.status
                        ? (
                          <>
                            {selectedDay?.data?.allDay === 'true'
                              ? <h6 className="text-dark fw-bold mb-0">{t('shared.all_day')}</h6>
                              : (
                                <>
                                  {selectedDay?.data?.times?.map((time) => (
                                    <h6 className="text-dark fw-bold mb-0" key={selectedDay?.label}>
                                      {time.from}
                                      {' '}
                                      -
                                      {' '}
                                      {time.to}
                                    </h6>
                                  ))}
                                </>
                              )}
                          </>
                        )
                        : <h6 className="text-dark fw-bold mb-0">{t('shared.not_available')}</h6>}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={7}>
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="text-dark-gray fw-bold mb-0">{DateTime.fromJSDate(selectedDate).toLocaleString({ weekday: 'long', day: '2-digit', month: 'short' })}</h3>
                    <button type="button" className="btn blue-btn fw-bold px-3" onClick={() => toggleBlockTimeModal(!isBlockTimeModalIsOpen)}>{t('dashboard.bookings.block_time')}</button>
                  </div>
                  <hr className="silver-hr" />
                </Col>
              </Row>
              {bookings?.length > 0
                ? (
                  <Row>
                    {bookings?.map((booking) => (
                      <Col md={12} key={booking.id}>
                        <BookingCard data={booking} />
                      </Col>
                    ))}
                  </Row>
                )
                : (
                  <EmptyPlaceholder text={t('dashboard.bookings.empty_placeholder')} />
                )}
              {bookings?.length > 0
                && (
                  <Row>
                    <Col md={12}>
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        <p className="text-space-gray mb-0">{`Showing ${bookings?.length} of ${paginationMeta?.total}`}</p>
                        <button type="button" className="btn light-btn px-3" disabled={paginationMeta?.current_page >= totalPages} onClick={loadMore}>{t('shared.show_more')}</button>
                      </div>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
        </Container>
      </section>
      <BlockTime isOpen={isBlockTimeModalIsOpen} toggle={toggleBlockTimeModal} listingId={selectedListing?.value} />
    </>
  );
};

export default Calendar;
