import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import OTPInput from 'react-otp-input';

const OtpField = React.forwardRef(({ optional, control, ...props }, ref) => {
  React.useImperativeHandle(ref, () => ({}));

  return (
    <Form.Group className="mb-3">
      {props.label
      && <Form.Label className="text-dark">{props.label}</Form.Label>}
      <Controller
        control={control}
        name={props.name}
        render={({ field: { onChange, value } }) => (
          <OTPInput
            value={value}
            onChange={onChange}
            numInputs={4}
            renderInput={(props) => <Form.Control {...props} />}
            renderSeparator={<span className="mx-2" />}
            inputStyle={{ width: '4em', height: '4em' }}
            containerStyle={{ justifyContent: 'center', marginTop: '0.6em', direction: 'ltr' }}
          />
        )}
      />
      <ErrorMessage
        errors={props.errors}
        name={props.name}
        render={({ message }) => <Form.Control.Feedback className="d-block" type="invalid">{message}</Form.Control.Feedback>}
      />
    </Form.Group>
  );
});

export default OtpField;
