import React from 'react';
import { X } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { signUp } from 'leween-react-sdk/redux/actions/auth.actions';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import Button from '../../../components/button';
import schema from './schema';
import { setServerErrors } from '../../../helpers';
import InputField from '../../../components/input';

const Register = ({
  isModalOpen, toggleModal, openLoginModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    reset, register, handleSubmit, setError, formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const onSubmit = (data) => {
    const { userAgent } = window.navigator;
    const newData = { ...data, device_name: userAgent, method: 'email' };
    dispatch(signUp(newData)).then(() => {
      toggleModal();
    }).catch((error) => {
      const responseErrors = error.response.data.errors;
      setServerErrors(responseErrors, setError);
    });
  };

  return (
    <Modal onShow={reset} className="light-modal" show={isModalOpen} onHide={() => toggleModal()} centered>
      <X className="close-icon cursor-pointer" onClick={() => toggleModal()} />
      <Modal.Body className="px-5">
        <Container className="py-4">
          <Row>
            <Col md="12" className="d-flex flex-column align-items-center justify-content-center mb-4 mt-2">
              <h4 className="text-dark fw-bold mb-2">{t('register.title')}</h4>
              <p className="text-dark mb-0">{t('register.subtitle')}</p>
            </Col>
            <Form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md={12}>
                <InputField
                  label={t('shared.email')}
                  name="email"
                  errors={errors}
                  type="email"
                  {...register('email')}
                />
                <InputField
                  label={t('shared.password')}
                  name="password"
                  errors={errors}
                  type="password"
                  {...register('password')}
                  wrapperClassName="ltr-input"
                />
                <Button className="yellow-btn w-100" title={t('shared.continue')} />
              </Col>
            </Form>
            <Col md={12}>
              <p className="text-dark text-center mb-0">
                {t('register.login')}
                <span className="text-yellow fw-bold ms-1 cursor-pointer" onClick={openLoginModal}>{t('shared.login')}</span>
              </p>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Register;
