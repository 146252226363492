import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import InputField from '../../../../components/input';
import { getActiveLocale } from '../../../../helpers';

const ActivityDetails = ({ bookingDetails }) => {
  const { t } = useTranslation();
  const {
    register, formState: { errors },
  } = useFormContext();
  return (
    <>
      <div className="white-container first-step">
        <FormGroup className="mb-4">
          <Label className="fw-bold">
            {t('shared.date_time')}
          </Label>
          {bookingDetails?.start_datetime && bookingDetails?.end_datetime
          && (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <h6 className="text-dark mb-0">{DateTime.fromISO(bookingDetails?.start_datetime).setLocale(getActiveLocale()).toLocaleString(DateTime.DATE_MED)}</h6>
                </div>
                <h6 className="text-dark mb-0">
                  {DateTime.fromISO(bookingDetails?.start_datetime).setLocale(getActiveLocale()).toFormat('HH:00 a')}
                  {' '}
                  -
                  {' '}
                  {DateTime.fromISO(bookingDetails?.end_datetime).setLocale(getActiveLocale()).toFormat('HH:00 a')}
                </h6>
              </div>
            </>
          )}
        </FormGroup>
        <FormGroup className="mb-4">
          <Label className="fw-bold" for="number_of_guests">
            {`${t('steps.activities.max_guests')}`}
          </Label>
          <InputField
            id="number_of_guests"
            name="number_of_guests"
            type="number"
            errors={errors}
            {...register('number_of_guests')}
          />
        </FormGroup>
        <FormGroup>
          <Label className="fw-bold" for="description">
            {`${t('shared.description')}`}
          </Label>
          <InputField
            id="description"
            name="description"
            as="textarea"
            rows={4}
            errors={errors}
            {...register('description')}
          />
        </FormGroup>
      </div>
    </>
  );
};

export default ActivityDetails;
