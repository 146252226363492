import { React } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalPages, handlePagination }) => {
  return (
    <ReactPaginate
      pageCount={totalPages}
      nextLabel=""
      breakLabel="..."
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      onPageChange={(page) => handlePagination(page)}
      activeClassName="active"
      pageClassName="page-item"
      previousLabel=""
      nextLinkClassName="page-link"
      nextClassName="page-item next-item"
      previousClassName="page-item prev-item"
      previousLinkClassName="page-link"
      pageLinkClassName="page-link"
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination react-paginate"
    />
  );
};

export default Pagination;
