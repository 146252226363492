import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

const About = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col md={12}>
        <div className="d-flex align-items-center mb-3">
          <h5 className="text-dark fw-bold mb-0">{t('listing_details.about')}</h5>
        </div>
        <Row>
          <Col md={12}>
            <p className="text-blue">
              {data}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default About;
