import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';

const PublicRoute = ({
  component: Component, header, footer,
  ...rest
}) => {
  if (header) {
    return (
      <>
        <Header />
        <Route {...rest} component={(props) => { return <Component {...props} />; }} />
      </>
    );
  } if (footer) {
    return (
      <>
        <Route {...rest} component={(props) => { return <Component {...props} />; }} />
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <Route {...rest} component={(props) => { return <Component {...props} />; }} />
      <Footer />
    </>
  );
};

export default PublicRoute;
