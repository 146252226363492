import * as yup from 'yup';

const schema = yup.object().shape({
  password: yup.string().required('Password field is required')
    .min(8, 'Password must contain 8 letters/numbers minimum')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Password should have at least one Capital letter, one number, one special character and doesn’t allow spaces.',
    ),

  password_confirmation: yup.string().required('Confirm password field is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export default schema;
